import { useEffect, useState } from "react";
import { Box, Text, Button, Flex, Grid, GridItem, Input, VStack, Textarea } from "@chakra-ui/react";
import { OrganizationPrivilegeSettings, UserQuery } from "../../types";
import store from "../../store";
import { useSelector } from "react-redux";
import { setGlobalUserInfo, updateOrganizationPrivilege } from "../../actions/authActions";
import { useOrganizationAPI } from "../../endpoints/organization";
import { useUserAPI } from "../../endpoints/user";

export default function SettingsTabPanel() {
  const [readOnlyInfo, setReadOnlyInfo] = useState<UserQuery>({} as UserQuery);
  const [saveNeeded, setSaveNeeded] = useState(false);
  const [settingsScratch, setSettingsScratch] = useState<UserQuery>({} as UserQuery);
  const organizationId = useSelector((state: any) => state.auth.organizationId);
  const { updateOrganizationPrivilageSettings } = useOrganizationAPI();
  const { getUser } = useUserAPI();

  const getUserInfo = async () => {
    const qr: any = await getUser();
    setReadOnlyInfo(qr as UserQuery);
    setSettingsScratch(qr as UserQuery);
    store.dispatch(setGlobalUserInfo(qr! as UserQuery));
  };

  useEffect(() => {
    getUserInfo();
  }, [organizationId]);

  function updatePrivilegeBanner(event: any) {
    if (
      !settingsScratch ||
      !settingsScratch.currentOrgMembership ||
      !settingsScratch.currentOrgMembership.organization ||
      !settingsScratch.currentOrgMembership.organization.privilegeSettings
    )
      return;
    const newScratch = {
      ...settingsScratch,
      currentOrgMembership: {
        ...settingsScratch.currentOrgMembership,
        organization: {
          ...settingsScratch.currentOrgMembership.organization,
          privilegeSettings: {
            ...settingsScratch.currentOrgMembership.organization.privilegeSettings,
            privilegeBanner: event.target.value,
          },
        },
      },
    };
    setSettingsScratch(newScratch);
    setSaveNeeded(true);
  }

  function updatePrivilegeAgreement(event: any) {
    if (
      !settingsScratch ||
      !settingsScratch.currentOrgMembership ||
      !settingsScratch.currentOrgMembership.organization ||
      !settingsScratch.currentOrgMembership.organization.privilegeSettings
    )
      return;
    const newScratch = {
      ...settingsScratch,
      currentOrgMembership: {
        ...settingsScratch.currentOrgMembership,
        organization: {
          ...settingsScratch.currentOrgMembership.organization,
          privilegeSettings: {
            ...settingsScratch.currentOrgMembership.organization.privilegeSettings,
            privilegeAgreement: event.target.value,
          },
        },
      },
    };
    setSettingsScratch(newScratch);
    setSaveNeeded(true);
  }

  function updatePrivilegeTerms(event: any) {
    if (
      !settingsScratch ||
      !settingsScratch.currentOrgMembership ||
      !settingsScratch.currentOrgMembership.organization ||
      !settingsScratch.currentOrgMembership.organization.privilegeSettings
    )
      return;
    const newScratch = {
      ...settingsScratch,
      currentOrgMembership: {
        ...settingsScratch.currentOrgMembership,
        organization: {
          ...settingsScratch.currentOrgMembership.organization,
          privilegeSettings: {
            ...settingsScratch.currentOrgMembership.organization.privilegeSettings,
            privilegeTerms: event.target.value,
          },
        },
      },
    };
    setSettingsScratch(newScratch);
    setSaveNeeded(true);
  }

  function resetChanges() {
    const newScratch = {
      ...readOnlyInfo,
    };
    setSettingsScratch(newScratch);
    setSaveNeeded(false);
  }

  const saveChanges = async () => {
    const updatedSettings: OrganizationPrivilegeSettings = {
      privilegeBanner: settingsScratch!.currentOrgMembership!.organization!.privilegeSettings.privilegeBanner,
      privilegeAgreement: settingsScratch!.currentOrgMembership!.organization!.privilegeSettings.privilegeAgreement,
      privilegeTerms: settingsScratch!.currentOrgMembership!.organization!.privilegeSettings.privilegeTerms,
    };
    await updateOrganizationPrivilageSettings(updatedSettings);

    store.dispatch(updateOrganizationPrivilege(updatedSettings));
    await getUser();
    setSaveNeeded(false);
  };

  return (
    <>
      <Flex alignContent="">
        <Box w="800px">
          <Grid templateRows="repeat(8, 1fr)" templateColumns="repeat(4, 1fr)" gap="3">
            <GridItem colSpan={1} rowSpan={1} alignSelf="center">
              <Text align="right">Privilege Banner</Text>
            </GridItem>
            <GridItem colSpan={2} rowSpan={1}>
              <Input
                placeholder="Privileged and Confidential"
                size="md"
                value={settingsScratch?.currentOrgMembership?.organization?.privilegeSettings.privilegeBanner}
                onChange={updatePrivilegeBanner}
              ></Input>
            </GridItem>
            <GridItem colSpan={1}></GridItem>
            <GridItem colSpan={1} rowSpan={1} alignSelf="center">
              <Text align="right">Privilege Agreement</Text>
            </GridItem>
            <GridItem colSpan={3} rowSpan={2}>
              <Textarea
                placeholder=""
                size="md"
                value={settingsScratch?.currentOrgMembership?.organization?.privilegeSettings.privilegeAgreement}
                onChange={updatePrivilegeAgreement}
              ></Textarea>
            </GridItem>
            <GridItem colSpan={1}></GridItem>

            <GridItem colSpan={1} rowSpan={1} alignSelf="center">
              <Text align="right">Privilege Terms</Text>
            </GridItem>
            <GridItem colSpan={3} rowSpan={3}>
              <Textarea
                placeholder=""
                size="md"
                value={settingsScratch?.currentOrgMembership?.organization?.privilegeSettings.privilegeTerms}
                onChange={updatePrivilegeTerms}
              ></Textarea>
            </GridItem>
          </Grid>
        </Box>
        <VStack ml="20px">
          {saveNeeded ? (
            <>
              <Button onClick={saveChanges}>Save</Button>
              <Button variant="secondary" onClick={resetChanges}>
                Cancel
              </Button>
            </>
          ) : (
            <></>
          )}
        </VStack>
      </Flex>
    </>
  );
}
