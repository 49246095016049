import { withProps } from "@udecode/cn";
import { createAlignPlugin } from "@udecode/plate-alignment";
import { createAutoformatPlugin } from "@udecode/plate-autoformat";
import {
  createBoldPlugin,
  createCodePlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createUnderlinePlugin,
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from "@udecode/plate-basic-marks";
import { createBlockquotePlugin, ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { createExitBreakPlugin, createSoftBreakPlugin } from "@udecode/plate-break";
import { createCodeBlockPlugin, ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE, ELEMENT_CODE_SYNTAX } from "@udecode/plate-code-block";
import { createComboboxPlugin } from "@udecode/plate-combobox";
import { createPlugins, isSelectionAtBlockStart, PlateElement, PlateLeaf, RenderAfterEditable, someNode } from "@udecode/plate-common";
import { createFontBackgroundColorPlugin, createFontColorPlugin, createFontSizePlugin } from "@udecode/plate-font";
import { createHeadingPlugin, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4, ELEMENT_H5, ELEMENT_H6, KEYS_HEADING } from "@udecode/plate-heading";
import { createIndentPlugin } from "@udecode/plate-indent";
import { createIndentListPlugin, KEY_LIST_STYLE_TYPE } from "@udecode/plate-indent-list";
import { createJuicePlugin } from "@udecode/plate-juice";
import { createKbdPlugin, MARK_KBD } from "@udecode/plate-kbd";
import { createLinkPlugin, ELEMENT_LINK } from "@udecode/plate-link";
import { createTodoListPlugin, ELEMENT_LI, ELEMENT_OL, ELEMENT_TODO_LI, ELEMENT_UL } from "@udecode/plate-list";
import { createNodeIdPlugin } from "@udecode/plate-node-id";
import { createParagraphPlugin, ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { createResetNodePlugin } from "@udecode/plate-reset-node";
import { createBlockSelectionPlugin } from "@udecode/plate-selection";
import { createDeserializeDocxPlugin } from "@udecode/plate-serializer-docx";
import { createDeserializeMdPlugin } from "@udecode/plate-serializer-md";
import { createTabbablePlugin } from "@udecode/plate-tabbable";
import { createDeletePlugin } from "@udecode/plate-select";
import { createTablePlugin, ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TH, ELEMENT_TR } from "@udecode/plate-table";
import { createTrailingBlockPlugin } from "@udecode/plate-trailing-block";
import { ELEMENT_MENTION, ELEMENT_MENTION_INPUT } from "@udecode/plate-mention";

import { MentionElement } from "../plate-ui/mention-element";
import { MentionInputElement } from "../plate-ui/mention-input-element";
import { autoformatPlugin } from "../plate-ui/auto-format-plugin";
import { dragOverCursorPlugin } from "../plate-ui/dragOverCursorPlugin";
import { BlockquoteElement } from "../plate-ui/blockquote-element";
import { CodeBlockElement } from "../plate-ui/code-block-element";
import { CodeLeaf } from "../plate-ui/code-leaf";
import { CodeLineElement } from "../plate-ui/code-line-element";
import { CodeSyntaxLeaf } from "../plate-ui/code-syntax-leaf";
import { HeadingElement } from "../plate-ui/heading-element";
import { KbdLeaf } from "../plate-ui/kbd-leaf";
import { LinkElement } from "../plate-ui/link-element";
import { LinkFloatingToolbar } from "../plate-ui/link-floating-toolbar";
import { ListElement } from "../plate-ui/list-element";
import { ParagraphElement } from "../plate-ui/paragraph-element";
import { TableCellElement, TableCellHeaderElement } from "../plate-ui/table-cell-element";
import { TableElement } from "../plate-ui/table-element";
import { TableRowElement } from "../plate-ui/table-row-element";
import { TodoListElement } from "../plate-ui/todo-list-element";
import { TabbableElement } from "../plate-ui/tabbableElements";
import { withPlaceholders } from "../plate-ui/placeholder";
import { createMentionPlugin } from "../plate-ui/mention/create-mention-plugin";

export const plugins = (includeMentions: boolean) =>
  createPlugins(
    [
      // Nodes
      createParagraphPlugin(),
      createHeadingPlugin(),
      createBlockquotePlugin(),
      createCodeBlockPlugin(),
      createLinkPlugin({
        renderAfterEditable: LinkFloatingToolbar as RenderAfterEditable,
      }),
      createTablePlugin(),
      createMentionPlugin({ key: "@", component: MentionElement, enabled: includeMentions }),
      createTodoListPlugin(),

      // Marks
      createBoldPlugin(),
      createItalicPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createCodePlugin(),
      createKbdPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createFontSizePlugin(),

      // Block Style
      createAlignPlugin({
        inject: {
          props: {
            validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3],
          },
        },
      }),
      createIndentPlugin({
        inject: {
          props: {
            validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_BLOCKQUOTE, ELEMENT_CODE_BLOCK],
          },
        },
      }),
      createIndentListPlugin({
        inject: {
          props: {
            validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_BLOCKQUOTE, ELEMENT_CODE_BLOCK],
          },
        },
      }),

      // Functionality
      createAutoformatPlugin(autoformatPlugin),
      createBlockquotePlugin(),
      createBlockSelectionPlugin({
        options: {
          sizes: {
            top: 0,
            bottom: 0,
          },
        },
      }),
      createComboboxPlugin(),
      createExitBreakPlugin({
        options: {
          rules: [
            {
              hotkey: "mod+enter",
            },
            {
              hotkey: "mod+shift+enter",
              before: true,
            },
            {
              hotkey: "enter",
              query: {
                start: true,
                end: true,
                allow: KEYS_HEADING,
              },
              relative: true,
              level: 1,
            },
          ],
        },
      }),
      createNodeIdPlugin(),
      createResetNodePlugin({
        options: {
          rules: [],
        },
      }),
      createDeletePlugin(),
      createSoftBreakPlugin({
        options: {
          rules: [
            { hotkey: "shift+enter" },
            {
              hotkey: "enter",
              query: {
                allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD],
              },
            },
          ],
        },
      }),
      createTabbablePlugin({
        options: {
          query: (editor) => {
            if (isSelectionAtBlockStart(editor)) return false;

            return !someNode(editor, {
              match: (n) => {
                return !!(n.type && ([ELEMENT_TABLE, ELEMENT_LI, ELEMENT_CODE_BLOCK].includes(n.type as string) || n[KEY_LIST_STYLE_TYPE]));
              },
            });
          },
        },
        plugins: [
          {
            key: "tabbable_element",
            isElement: true,
            isVoid: true,
            component: TabbableElement,
          },
        ],
      }),
      createTrailingBlockPlugin({
        options: { type: ELEMENT_PARAGRAPH },
      }),
      dragOverCursorPlugin,

      // Deserialization
      createDeserializeDocxPlugin(),
      createDeserializeMdPlugin(),
      createJuicePlugin(),
    ],
    {
      components: withPlaceholders({
        [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
        [ELEMENT_CODE_BLOCK]: CodeBlockElement,
        [ELEMENT_CODE_LINE]: CodeLineElement,
        [ELEMENT_CODE_SYNTAX]: CodeSyntaxLeaf,
        [ELEMENT_H1]: withProps(HeadingElement, { variant: "h1" }),
        [ELEMENT_H2]: withProps(HeadingElement, { variant: "h2" }),
        [ELEMENT_H3]: withProps(HeadingElement, { variant: "h3" }),
        [ELEMENT_H4]: withProps(HeadingElement, { variant: "h4" }),
        [ELEMENT_H5]: withProps(HeadingElement, { variant: "h5" }),
        [ELEMENT_H6]: withProps(HeadingElement, { variant: "h6" }),
        [ELEMENT_LI]: withProps(PlateElement, { as: "li" }),
        [ELEMENT_LINK]: LinkElement,
        [ELEMENT_UL]: withProps(ListElement, { variant: "ul" }),
        [ELEMENT_OL]: withProps(ListElement, { variant: "ol" }),
        [ELEMENT_MENTION]: MentionElement,
        [ELEMENT_MENTION_INPUT]: MentionInputElement,
        [ELEMENT_PARAGRAPH]: ParagraphElement,
        [ELEMENT_TABLE]: TableElement,
        [ELEMENT_TD]: TableCellElement,
        [ELEMENT_TH]: TableCellHeaderElement,
        [ELEMENT_TODO_LI]: TodoListElement,
        [ELEMENT_TR]: TableRowElement,
        [MARK_BOLD]: withProps(PlateLeaf, { as: "strong" }),
        [MARK_CODE]: CodeLeaf,
        [MARK_ITALIC]: withProps(PlateLeaf, { as: "em" }),
        [MARK_KBD]: KbdLeaf,
        [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: "s" }),
        [MARK_UNDERLINE]: withProps(PlateLeaf, { as: "u" }),
      }),
    }
  );
