import { createContext, useContext, useState } from "react";
import { HStack } from "@chakra-ui/react";

interface ITopNavPageContext {
  pageInfo: any;
  setPageInfo: (x: any) => void;
  pageActions: any;
  setPageActions: (x: any) => void;
}

const TopNavContext = createContext<ITopNavPageContext>({
  pageInfo: "",
  setPageInfo: () => {},
  pageActions: "",
  setPageActions: () => {},
});

export const TopNavProvider = ({ children }: { children: any }) => {
  const [pageInfo, setPageInfo] = useState(<HStack></HStack>);
  const [pageActions, setPageActions] = useState(<HStack></HStack>);

  return (
    <TopNavContext.Provider
      value={{ pageInfo, setPageInfo, pageActions, setPageActions }}
    >
      {children}
    </TopNavContext.Provider>
  );
};

export const useTopNavContext = () => useContext(TopNavContext);
