import {
  Box,
  Button,
  Center,
  Checkbox,
  Circle,
  Text,
  Flex,
  HStack,
  VStack,
  Modal,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Spacer,
  Icon,
  Tooltip,
  Heading,
  Textarea,
  useClipboard,
  useToast,
  Progress,
} from "@chakra-ui/react";
import _ from "lodash";
import { useState, useEffect } from "react";
import {
  UserQuery,
  EnrichedForm,
  FormUser,
  EnrichedFormContentless,
  EnrichedSubmission,
  EnrichedSubmissionContentless,
  OtherUserInfo,
  SubmissionUser,
  FormAvailability,
  MembershipType,
} from "../../types";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { LuminosModalHeader } from "./LuminosModalHeader";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { UserAccessCard } from "./UserAccessCard";
import { DestructiveErrorModal } from "./DestructiveErrorModal";
import RichText from "../../pages/Elements/ElementComponents/RichText";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useOrganizationAPI } from "../../endpoints/organization";
import { useAPIRequest } from "../../hooks/useAPI";

export function urlPathPrefix(type: string) {
  if (type === "form") {
    return "forms";
  } else if (type === "submission") {
    return "submissions";
  }
  return "";
}

interface DocUser {
  userInfo: OtherUserInfo;
  userDocInfo: FormUser | SubmissionUser;
}

interface SharingModalInterface {
  document?: EnrichedForm | EnrichedFormContentless | EnrichedSubmission | EnrichedSubmissionContentless;
  type: "form" | "submission";
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

function mergeUsers({ usersDocInfo, usersInfo }: { usersDocInfo: FormUser[] | SubmissionUser[]; usersInfo: OtherUserInfo[] }) {
  const usersInfoMap = new Map<string, OtherUserInfo>();
  const docUserMap: Map<string, DocUser> = new Map<string, DocUser>();

  usersInfo.map((userInfo) => {
    usersInfoMap.set(userInfo.userId, userInfo);
  });

  usersDocInfo.map((userDocInfo) => {
    const userInfo = usersInfoMap.get(userDocInfo.userId);
    if (userInfo) {
      docUserMap.set(userDocInfo.userId, {
        userInfo: userInfo,
        userDocInfo: userDocInfo,
      });
    }
  });
  return docUserMap;
}

export const SharingModal = ({ document, type, isOpen, onOpen, onClose }: SharingModalInterface) => {
  const globalUserInfo: UserQuery = useSelector((state: any) => state.auth.globalUserInfo);
  const [loadingDocumentInfo, setLoadingDocumentInfo] = useState(true);
  const [doc, setDoc] = useState<EnrichedForm | EnrichedFormContentless | EnrichedSubmission | EnrichedSubmissionContentless>();
  const [docUsers, setDocUsers] = useState<Map<string, DocUser>>();
  const [saving, setSaving] = useState(false);
  const [notifyRecipient, setNotifyRecipient] = useState(true);
  const { isOpen: errorModalIsOpen, onOpen: openErrorModal, onClose: closeErrorModal } = useDisclosure();
  const { onCopy: onUrlCopy, value: url, setValue: setUrl } = useClipboard("");
  const toast = useToast();
  const mentionableMembers = useSelector((state: RootState) => state.org.mentionableMembers);
  const generalAccessRestrictedText = "Only those granted access above";
  const generalAccessMembersText = `All ${globalUserInfo?.currentOrgMembership?.name} members`;
  const generalAccessMembersAndGuestsText = `All ${globalUserInfo?.currentOrgMembership?.name} members and guests`;

  const [generalAccessText, setGeneralAccessText] = useState(generalAccessRestrictedText);
  const [generalAccess, setGeneralAccess] = useState<"restricted" | "members" | "membersAndGuests">("restricted");
  const [discoverable, setDiscoverable] = useState(false);
  const [notifyOnShare, setNotifyOnShare] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");

  const [emailAddresses, setEmailAddresses] = useState<string[] | null>([]);
  const [displayAddUserDialog, setDisplayAddUserDialog] = useState(false);
  const [validInput, setValidInput] = useState(false);
  const { inviteNewUser, refreshOrganizationMembers } = useOrganizationAPI();
  const sendAPIRequest = useAPIRequest();

  function onDocUpdate(document: EnrichedForm | EnrichedFormContentless | EnrichedSubmission | EnrichedSubmissionContentless) {
    const du = mergeUsers({ usersDocInfo: document.users, usersInfo: document.readOnly.usersInfo });
    setDoc(document);
    setDocUsers(du);
    if (type === "form") {
      const tmp: EnrichedFormContentless = document as EnrichedFormContentless;
      setUrl(`${window.location.protocol}//${window.location.host}/form-builder/${tmp._id}`);
      setDiscoverable(tmp.availability.discoverable);
      if (tmp.availability.audience?.includes("guest") && tmp.availability.audience?.includes("member")) {
        setGeneralAccess("membersAndGuests");
        setGeneralAccessText(generalAccessMembersAndGuestsText);
      } else if (tmp.availability.audience?.includes("member")) {
        setGeneralAccess("members");
        setGeneralAccessText(generalAccessMembersText);
      } else {
        setGeneralAccess("restricted");
        setGeneralAccessText(generalAccessRestrictedText);
      }
      if ((document as EnrichedFormContentless).readOnly.actions.becomeAdjudicator) {
        setAddUsersRole("owner");
      } else if ((document as EnrichedFormContentless).readOnly.actions.edit) {
        setAddUsersRole("editor");
      }
    } else if (type == "submission") {
      const tmp: EnrichedSubmissionContentless = document as EnrichedSubmissionContentless;
      setUrl(`${window.location.protocol}//${window.location.host}/submission-editor/${tmp._id}`);
      if ((document as EnrichedSubmissionContentless).readOnly.actions.assignAdjudicators) {
        setAddUsersRole("adjudicator");
      }
    }
    setLoadingDocumentInfo(false);
  }

  useEffect(() => {
    if (document?.readOnly) {
      onDocUpdate(document);
    }
  }, [document]);

  function copyLinkToClipboard() {
    onUrlCopy();
    toast({
      title: "Copied",
      description: "The form link was copied to your clipboard.",
      status: "success",
      duration: 3000,
      isClosable: true,
      containerStyle: {
        textColor: "white",
      },
    });
  }

  async function shareAndSend() {
    setSaving(true);

    try {
      const knownEmails = mentionableMembers.map((member: any) => member.data.email);
      const knownNames = mentionableMembers.map((member: any) => member.text);
      const knownUsers = knownEmails.concat(knownNames);
      const knownUserIds = mentionableMembers
        .filter((member: any) => emailAddresses?.includes(member.data.email) || emailAddresses?.includes(member.text))
        .map((member: any) => member.key);
      const newEmails = emailAddresses?.filter((email: string) => !knownUsers.includes(email.trim()));

      let userIds = [...knownUserIds];
      userIds = userIds.filter((id: string) => id !== globalUserInfo.user._id);

      if (newEmails) {
        const newUserIds = await Promise.all(
          newEmails.map(async (address: string) => {
            const res: any = await inviteNewUser({
              email: address.trim(),
              orgMembership: {
                membershipType: "guest",
                permissions: {
                  inviteUser: true,
                },
              },
            });
            if (res.userId) {
              refreshOrganizationMembers();
              return res.userId;
            }
            return null;
          })
        );

        userIds.push(...newUserIds);

        toast({
          title: "Invitations Sent",
          description: `${newEmails.join(", ")} invited to join ${globalUserInfo.currentOrgMembership.organization!.name}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
          containerStyle: {
            textColor: "white",
          },
        });
      }

      if (userIds.length > 0) {
        const docUsers: any = { users: [] };
        userIds.map((userId) => {
          docUsers.users.push({
            userId: userId,
            role: addUsersRole,
          });
        });
        await sendAPIRequest(`${urlPathPrefix(type)}/${document!._id}/users`, "POST", docUsers);
      }
      const newDoc = await sendAPIRequest(`${urlPathPrefix(type)}/${document!._id}`);
      onDocUpdate(newDoc as any);
      cleanupAndClose();
    } catch (error) {
      console.log(error);
      openErrorModal();
    }
  }

  function cleanupAndClose() {
    setEmailAddresses([]);
    setSaving(false);
    setDisplayAddUserDialog(false);
    setNotifyOnShare(true);
    setNotificationMessage("");
    onClose();
  }

  function closeErrorModalWrapper() {
    // reset everything derived from the input document
    onDocUpdate(document!);
    cleanupAndClose();
    closeErrorModal();
  }

  async function saveAvailability(availability: FormAvailability) {
    setSaving(true);
    try {
      const res: any = await sendAPIRequest(`${urlPathPrefix(type)}/${doc!._id}/availability`, "PUT", availability);
      setSaving(false);
      if (!res.message) {
        openErrorModal();
      }
    } catch (error) {
      console.log(error);
      openErrorModal();
    }
  }

  function setGeneralAccessRestricted() {
    if (generalAccess !== "restricted") {
      setGeneralAccessText(generalAccessRestrictedText);
      setGeneralAccess("restricted");
      saveAvailability({
        discoverable: false,
        audience: [],
      });
    }
  }

  function setGeneralAccessMembers() {
    if (generalAccess !== "members") {
      setGeneralAccessText(generalAccessMembersText);
      setGeneralAccess("members");
      saveAvailability({
        discoverable: discoverable,
        audience: ["member"],
      });
    }
  }

  function setGeneralAccessMembersAndGuests() {
    if (generalAccess !== "membersAndGuests") {
      setGeneralAccessText(generalAccessMembersAndGuestsText);
      setGeneralAccess("membersAndGuests");
      saveAvailability({
        discoverable: discoverable,
        audience: ["member", "guest"],
      });
    }
  }

  function getMembershipType() {
    if (generalAccess === "membersAndGuests") {
      return ["member", "guest"] as MembershipType[];
    } else if (generalAccess === "members") {
      return ["member"] as MembershipType[];
    } else {
      return [] as MembershipType[];
    }
  }

  function setDiscoverableWrapper() {
    if (!discoverable) {
      setDiscoverable(true);
      saveAvailability({
        discoverable: true,
        audience: getMembershipType(),
      });
    }
  }

  function setNotDiscoverable() {
    if (discoverable) {
      setDiscoverable(false);
      saveAvailability({
        discoverable: false,
        audience: getMembershipType(),
      });
    }
  }

  useEffect(() => {
    if (emailAddresses !== null && emailAddresses.length > 0) {
      setValidInput(true);
      setDisplayAddUserDialog(true);
    } else {
      setValidInput(false);
      setDisplayAddUserDialog(false);
    }
  }, [emailAddresses]);

  const handleOnChange = (event: any) => {
    if (event[0].children) {
      const mentionedUsers = event[0].children.filter((textNode: any) => textNode.type == "@");
      if (mentionedUsers) {
        setEmailAddresses(mentionedUsers.map((textNode: any) => textNode.value));
      }
    }
  };

  const [addUsersRole, setAddUsersRole] = useState("submitter");

  function addUsersRoleOwner() {
    setAddUsersRole("owner");
  }

  function addUsersRoleEditor() {
    setAddUsersRole("editor");
  }

  function addUsersRoleSubmitter() {
    setAddUsersRole("submitter");
  }

  function addUsersRoleAdjudicator() {
    setAddUsersRole("adjudicator");
  }

  function setNotifyOnShareWrapper(e: any) {
    setNotifyOnShare(e.target.checked);
  }

  function setNotificationMessageWrapper(e: any) {
    setNotificationMessage(e.target.value);
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={cleanupAndClose}>
        <ModalOverlay />
        <ModalContent minWidth="600px">
          <LuminosModalHeader title={`${type === "form" ? "Form" : "Submission"} Sharing`} closeButton={false} />
          {saving ? <Progress w="100%" size="xs" isIndeterminate /> : <Box h="4px" bg="white"></Box>}
          <ModalBody bg="white">
            <HStack w="100%">
              {displayAddUserDialog ? (
                <Box>
                  <KeyboardBackspaceIcon
                    onClick={() => {
                      setDisplayAddUserDialog(false);
                      setEmailAddresses([]);
                    }}
                  />
                </Box>
              ) : (
                <></>
              )}
              <Heading size="md" w="100%">
                {doc ? <>{`Share "${doc.meta.title}"`}</> : <></>}
              </Heading>
            </HStack>
            {loadingDocumentInfo ? <Text>loading...</Text> : <></>}
            {!loadingDocumentInfo ? (
              <>
                <Flex mt="12px" mb="8px">
                  <Box w="100%">
                    <div style={{ width: "100%" }}>
                      <RichText
                        showToolbar={false}
                        placeholder="type @ to add users"
                        size="fit"
                        value={emailAddresses ? [{ text: "" }, { type: "@", value: emailAddresses[0], children: [{ text: "" }, { text: "" }] }] : {}}
                        onChange={(e: any) => handleOnChange(e)}
                        includeMentions={true}
                        reset={emailAddresses === null}
                      />
                    </div>
                  </Box>
                  <Spacer />
                  <Box>
                    {displayAddUserDialog ? (
                      <Menu>
                        <MenuButton h="38px" border="1px" ml="12px" mr="12px" mt="4px" pl="8px" borderColor="gray.500" whiteSpace="nowrap">
                          <HStack spacing="2px">
                            <Text>{addUsersRole}</Text>
                            <Icon as={ArrowDropDownOutlinedIcon} />
                          </HStack>
                        </MenuButton>

                        <MenuList>
                          <>
                            {doc && type === "form" && (doc as EnrichedFormContentless).readOnly.actions.becomeAdjudicator ? (
                              <MenuItem onClick={addUsersRoleOwner}>
                                <Flex w="100%">
                                  {addUsersRole === "owner" ? <Icon as={CheckOutlinedIcon} /> : <></>}
                                  <Spacer />
                                  <Text>owner</Text>
                                </Flex>
                              </MenuItem>
                            ) : (
                              <></>
                            )}
                          </>
                          <>
                            {doc && type === "form" && (doc as EnrichedFormContentless).readOnly.actions.edit ? (
                              <MenuItem onClick={addUsersRoleEditor}>
                                <Flex w="100%">
                                  {addUsersRole === "editor" ? <Icon as={CheckOutlinedIcon} /> : <></>}
                                  <Spacer />
                                  <Text>editor</Text>
                                </Flex>
                              </MenuItem>
                            ) : (
                              <></>
                            )}
                          </>
                          <>
                            {doc && type === "submission" && (doc as EnrichedSubmissionContentless).readOnly.actions.assignAdjudicators ? (
                              <MenuItem onClick={addUsersRoleAdjudicator}>
                                <Flex w="100%">
                                  {addUsersRole === "adjudicator" ? <Icon as={CheckOutlinedIcon} /> : <></>}
                                  <Spacer />
                                  <Text>reviewer</Text>
                                </Flex>
                              </MenuItem>
                            ) : (
                              <></>
                            )}
                          </>
                          <MenuItem onClick={addUsersRoleSubmitter}>
                            <Flex w="100%">
                              {addUsersRole === "submitter" ? <Icon as={CheckOutlinedIcon} /> : <></>}
                              <Spacer />
                              <Text>submitter</Text>
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Flex>
                {displayAddUserDialog ? (
                  <></>
                ) : (
                  <>
                    <Heading size="sm" w="100%" mt="12px">
                      People With Access
                    </Heading>
                    <>
                      {doc && docUsers ? (
                        <>
                          {Array.from(docUsers.keys()).map((data: string) => {
                            return (
                              <UserAccessCard
                                key={data}
                                userId={data}
                                docUsers={docUsers}
                                docId={doc._id!}
                                docType={type}
                                doc={doc}
                                setDocUsers={setDocUsers}
                                openErrorModal={openErrorModal}
                                setSaving={setSaving}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                    {type === "form" ? (
                      <>
                        <Heading size="sm" w="100%" mt="24px">
                          General Submission Access
                        </Heading>
                        <VStack w="100%" spacing="2px">
                          <Flex w="100%">
                            <Menu>
                              <MenuButton border="1px" pr="2px" pl="2px" pt="0px" pb="0px" mr="2px" borderColor="gray.300" borderRadius="3px">
                                <Center>
                                  <Text fontSize="sm">{generalAccessText}</Text>
                                </Center>
                              </MenuButton>
                              <MenuList>
                                <MenuItem onClick={setGeneralAccessRestricted}>{generalAccessRestrictedText}</MenuItem>
                                <MenuItem onClick={setGeneralAccessMembers}>{generalAccessMembersText}</MenuItem>
                                <MenuItem onClick={setGeneralAccessMembersAndGuests}>{generalAccessMembersAndGuestsText}</MenuItem>
                              </MenuList>
                            </Menu>
                            <Center>
                              <Text fontSize="sm">may create submissions of this form.</Text>
                            </Center>
                          </Flex>

                          {generalAccess === "restricted" ? (
                            <></>
                          ) : (
                            <>
                              <Flex w="100%">
                                <Text fontSize="sm">This form</Text>

                                <Menu>
                                  <MenuButton border="1px" pr="2px" pl="2px" pt="0px" pb="0px" ml="2px" mr="2px" borderColor="gray.300" borderRadius="3px">
                                    <Center>
                                      <Text fontSize="sm">{discoverable ? "is" : "is not"}</Text>
                                    </Center>
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem onClick={setDiscoverableWrapper}>is</MenuItem>
                                    <MenuItem onClick={setNotDiscoverable}>is not</MenuItem>
                                  </MenuList>
                                </Menu>

                                <Text fontSize="sm">discoverable on the Forms Page for those with only General Submission</Text>
                              </Flex>
                              {!discoverable ? (
                                <Flex w="100%">
                                  <Text fontSize="sm">Access. They must have the link to create a Submission from the Form.</Text>
                                </Flex>
                              ) : (
                                <>
                                  <Flex w="100%">
                                    <Text fontSize="sm">Access. They may create a Submission from this Form from the Forms Page or</Text>
                                  </Flex>
                                  <Flex w="100%">
                                    <Text fontSize="sm"> using the link.</Text>
                                  </Flex>
                                </>
                              )}
                            </>
                          )}
                        </VStack>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {!loadingDocumentInfo && displayAddUserDialog ? (
              <VStack w="100%">
                <Checkbox w="96%" isChecked={notifyOnShare} onChange={setNotifyOnShareWrapper}>
                  Notify people
                </Checkbox>
                {notifyOnShare ? (
                  <Textarea size="lg" placeholder="Message for new users" value={notificationMessage} onChange={setNotificationMessageWrapper} />
                ) : (
                  <></>
                )}
              </VStack>
            ) : (
              <></>
            )}
          </ModalBody>

          <ModalFooter bg="white">
            <Flex w="100%">
              <Center>
                <Tooltip label="Copy Link to Form" fontSize="md">
                  <Circle size="32px" border="1px" borderColor="brightblue.500" onClick={copyLinkToClipboard} boxShadow="lg">
                    <Icon as={LinkOutlinedIcon} w="22px" h="22px" />
                  </Circle>
                </Tooltip>
              </Center>

              <Spacer />
              {displayAddUserDialog ? (
                <>
                  {saving ? (
                    <Button isDisabled={true}>Saving...</Button>
                  ) : (
                    <>
                      <Button variant="secondary" mr={3} onClick={cleanupAndClose}>
                        Cancel
                      </Button>
                      <Button isDisabled={!validInput} onClick={shareAndSend}>
                        {notifyRecipient ? "Send" : "Share"}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {saving ? (
                    <Button isDisabled={true} onClick={cleanupAndClose}>
                      Saving...
                    </Button>
                  ) : (
                    <Button isDisabled={false} onClick={cleanupAndClose}>
                      Done
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DestructiveErrorModal
        errorModalIsOpen={errorModalIsOpen}
        title={`${type === "form" ? "Form" : "Submission"} Sharing Error`}
        closeErrorModalWrapper={closeErrorModalWrapper}
        closeParent={cleanupAndClose}
      />
    </>
  );
};
