import { Tab, TabList, TabPanel, Tabs, Text, Box, VStack, HStack, Icon, Divider, MenuItem, Menu, MenuList, MenuButton, TabPanels } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import Draggable from "react-draggable";
import { AudienceSelection } from "../../../pages/Elements/ElementComponents/AudienceSelection";
import { ROLE_1 } from "../../../roles";
import { Form, FormElement } from "../../../types";
import CloseIcon from "@mui/icons-material/Close";
import { VariablesPanel } from "./VariablesPanel";

const elementTypeMap: { [index: string]: string } = {
  attestation: "Attestation",
  section: "Section",
  textResponse: "Text Response",
  multipleChoice: "Multiple Choice",
  singleSelect: "Single Select",
  fileUpload: "File Upload",
  biasAnalysis: "Bias Analysis",
};

export interface Question {
  id: string;
  title: string;
  titleNumber: string;
  indentationLevel: number;
  type: string;
}

const AssignmentRow = ({ question, questions }: { question: Question; questions: Question[] }) => {
  const [audienceInternal, setAudienceInternal] = useState(ROLE_1);
  const [selectedRule, setSelectedRule] = useState("always*");
  const currentQuestionIndex = questions?.findIndex((q) => q.id === question.id);
  const previousQuestions = questions?.slice(0, currentQuestionIndex);

  const updateAudience = (newAudience: any) => {
    setAudienceInternal(newAudience);
  };

  return (
    <div style={{ paddingTop: 3, display: "grid", gridTemplateColumns: "40% 8% 40%", justifyContent: "space-between" }}>
      <Text style={{ marginLeft: question.indentationLevel * 8 }}>
        {question.titleNumber} {question.title}
      </Text>
      <VStack>
        {question.type === "section" ? (
          <Text>N/A</Text>
        ) : (
          <AudienceSelection staticContent={true} audience={audienceInternal} changeAudience={(x) => updateAudience(x)} />
        )}
      </VStack>
      <Menu>
        <MenuButton px={2} bg={"gray.100"}>
          <HStack justifyContent={"space-between"}>
            <Text>{selectedRule}</Text>
            <ChevronDownIcon />
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => setSelectedRule("always*")}>always*</MenuItem>
          {previousQuestions?.map((q) => (
            <MenuItem onClick={() => setSelectedRule(`If ${q.titleNumber} is completed.`)} key={q.id}>
              If {q.titleNumber} is completed.
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};

export const RulesModal = ({ open, onClose, save, form }: { open: boolean; onClose: any; save: () => void; form: Form }) => {
  const [questions, setQuestions] = useState<Question[]>();

  const getQuestionTitles = (elements: FormElement[], indentationLevel: number): Question[] => {
    //   recurse through the form elements and return an array of questions
    return elements?.reduce((acc, element) => {
      acc.push({
        id: element.id!,
        title: element.content.title !== "" ? element.content.title : "Untitled " + elementTypeMap[element.type],
        titleNumber: element.titleNumber!,
        indentationLevel: indentationLevel,
        type: element.type,
      });
      if (element.children && element.children.length > 0) {
        acc.push(...getQuestionTitles(element.children, indentationLevel + 1));
      }
      return acc;
    }, [] as Question[]);
  };

  React.useEffect(() => {
    const questions = getQuestionTitles(form.elements, 0);
    setQuestions(questions);
  }, [form.elements]);

  const Header = () => {
    return (
      <VStack w="full" spacing="0px">
        <HStack p={1} justify="space-between" bg="gray.800" color="white.500" w="full">
          <strong className="cursor" style={{ cursor: "move", width: "90%" }}>
            <Text align="left" mr="auto" ml="4px">
              Rules
            </Text>
          </strong>
          <Icon style={{ cursor: "pointer" }} as={CloseIcon} onClick={onClose} />
        </HStack>
        <Box bg="brand.500" w="full" h="1px"></Box>
        <Box bg="gray.900" w="full" h="2px"></Box>
      </VStack>
    );
  };

  const AssignmentPanel = () => {
    return (
      <div style={{ padding: 8, paddingTop: 0 }}>
        <Text>Assign</Text>
        <div style={{ display: "grid", gridTemplateColumns: "40% 8% 40%", justifyContent: "space-between" }}>
          <VStack>
            <Text>Question</Text>
            <Divider />
          </VStack>
          <VStack>
            <Text>To</Text>
            <Divider />
          </VStack>
          <VStack>
            <Text>When</Text>
            <Divider />
          </VStack>
        </div>
        {questions?.map((question, index) => (
          <AssignmentRow questions={questions} question={question} key={index} />
        ))}
        <Text mt={1} variant="caption">
          * questions are only displayed if their parent section is displayed
        </Text>
      </div>
    );
  };

  return (
    <div style={{ display: open ? "flex" : "none" }}>
      <Draggable defaultPosition={{ x: 125, y: 125 }} handle="strong">
        <div
          className="box no-cursor"
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            zIndex: 1500,
            boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.39)",
            width: "600px",
          }}
        >
          <Header />
          <AssignmentPanel />
          {/* <Tabs>
            <TabPanels>
              <TabPanel>
                <AssignmentPanel />
              </TabPanel>
              <TabPanel>
                <VariablesPanel questions={questions!} />
              </TabPanel>
            </TabPanels>

            <TabList>
              <Tab>Assignments</Tab>
              <Tab>Variables</Tab>
            </TabList>
          </Tabs> */}
        </div>
      </Draggable>
    </div>
  );
};
