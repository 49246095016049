import { Flex, Icon, Text } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../../../components/Spacer";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RichText from "./RichText";
export const SurveyTakerNotes = ({ text }: { text: any }) => {
  return (
    <Flex alignContent="flex-start" w={"100%"} borderLeftColor={"brand.500"} borderLeftWidth={7} borderRadius={2} pt={3} flexDirection="column">
      <Flex flexDirection="row" alignItems={"flex-end"}>
        <Spacer x={SpacerSizes.sm} />
        <Icon as={ErrorOutlineIcon} />
        <Spacer x={SpacerSizes.sm} />
        <Text variant="bold">Notes and Instructions</Text>
      </Flex>
      <RichText className="pt-0" size="fit" value={text} showToolbar={false} borderColor="border-none" readOnly={true} />
    </Flex>
  );
};
