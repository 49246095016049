import "./styles/app.css";

import { ChakraProvider } from "@chakra-ui/react";
import { Header } from "./components/TopNavBar/TopNavContainer";
import theme from "./theme";
import { Fonts } from "./theme/fonts";
import SideNavContainer from "./components/SideNavBar/SideNavContainer";
import { SideNav } from "./components/SideNavBar/SideNav";
import { Routes, Route } from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import Forms from "./pages/Forms";
import Submissions from "./pages/Submissions";
import { Login } from "./pages/Login";
import UserProfile from "./pages/UserProfile";
import Organization from "./pages/Organization";
import { RequireAuth } from "./util/requireAuth";
import { AuthContextProvider } from "./context/AuthContext";
import { FormBuilder } from "./pages/FormBuilder";
import { SubmissionEditor } from "./pages/SubmissionEditor";
import { TopNavProvider } from "./context/TopNavContext";
import { SelectionContextProvider } from "./context/SelectionContext";
import { PostLogin } from "./pages/PostLogin";
import { PlateController } from "@udecode/plate-common";

export default function App() {
  return (
    <ChakraProvider cssVarsRoot="body" theme={theme}>
      <AuthContextProvider>
        <TopNavProvider>
          <SelectionContextProvider>
            <PlateController>
              <Header />
              <SideNavContainer sideNav={<SideNav />}>
                <Fonts />
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/post-login" element={<PostLogin />} />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/"
                    element={
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/forms"
                    element={
                      <RequireAuth>
                        <Forms />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/submissions"
                    element={
                      <RequireAuth>
                        <Submissions />
                      </RequireAuth>
                    }
                  />
                  <Route path="/form-builder/:id" element={<FormBuilder />} />
                  <Route path="/submission-editor/:id" element={<SubmissionEditor />} />
                  <Route
                    path="/user-profile"
                    element={
                      <RequireAuth>
                        <UserProfile />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/organization"
                    element={
                      <RequireAuth>
                        <Organization />
                      </RequireAuth>
                    }
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </SideNavContainer>
            </PlateController>
          </SelectionContextProvider>
        </TopNavProvider>
      </AuthContextProvider>
    </ChakraProvider>
  );
}
