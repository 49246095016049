export const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, submissionId: string, id: string, postFiles: any) => {
  try {
    const file = event.target.files![0];

    if (!file) return [null, null];

    const fileExtension = file.name.split(".").pop();

    // API request to POST /files
    const fileResponse = await postFiles({ submissionId, elementId: id, fileExt: fileExtension });
    const { fileId, url } = fileResponse as any;

    // Upload file to presigned URL
    await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    return [fileId, file.name];
  } catch (error) {
    console.error("Error uploading file", error);
    return [null, null];
  }
};
