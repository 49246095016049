import { useState } from "react";
import { Text, HStack, Input, InputGroup, Tooltip } from "@chakra-ui/react";
import { SubmissionAdmonition } from "./SubmissionAdmonition";
import { Spacer, SpacerSizes } from "../../../components/Spacer";
import { AudienceSelection, AudienceDisplay } from "./AudienceSelection";
import RichText from "../ElementComponents/RichText";

const RequiredIndicator = ({ required }: { required: boolean }) => {
  return (
    <>
      {required ? (
        <Tooltip label="A response to this quesiton is required">
          <Text color="red.500">*</Text>
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
};

export const BuilderHeader = ({
  title,
  titlePlaceholder,
  titleNumber,
  indentationLevel,
  audience,
  questionPrompt,
  required,
  onChange,
}: {
  title: string;
  titleNumber: string;
  indentationLevel: number;
  audience: string;
  questionPrompt: string;
  required: boolean;
  titlePlaceholder: string;
  onChange: (e: any, field: string) => void;
}) => {
  const [titleInternal, setTitleInternal] = useState(title);
  const [audienceInternal, setAudienceInternal] = useState(audience);

  const updateTitle = (newTitle: any) => {
    onChange(newTitle.target.value, "title");
    setTitleInternal(newTitle.target.value);
  };

  const updateAudience = (newAudience: any) => {
    onChange(newAudience, "audience");
    setAudienceInternal(newAudience);
  };

  return (
    <>
      <HStack
        justifyContent={"space-between"}
        style={{
          marginLeft: `${(indentationLevel - 1) * 20}px`,
        }}
        gap={1}
      >
        <HStack>
          <Text>{titleNumber}</Text>
          <InputGroup width="fit-content">
            <Input
              htmlSize={titleInternal.length}
              placeholder={titlePlaceholder}
              onChange={updateTitle}
              style={{
                fontWeight: "bold",
              }}
              variant="unstyled"
              value={titleInternal}
            />
          </InputGroup>
          <RequiredIndicator required={required} />
        </HStack>
        <AudienceSelection audience={audienceInternal} changeAudience={(x) => updateAudience(x)} />
      </HStack>
      <Text>{questionPrompt}</Text>
    </>
  );
};

export const ResponseHeader = ({
  indentationLevel,
  titleNumber,
  title,
  required,
  adjudicatorNotes,
  surveyTakerNotes,
  role,
  audience,
  buildView,
  question,
  questionPlaceholder,
}: {
  indentationLevel: number;
  titleNumber: string;
  title: string;
  required: boolean;
  adjudicatorNotes: any;
  surveyTakerNotes: any;
  role: string;
  audience: string;
  buildView: boolean;
  question: any;
  questionPlaceholder: string;
}) => {
  function displayQuestion() {
    if (
      buildView ||
      (question && question[0] && question[0].children && question[0].children[0] && question[0].children[0].text && question[0].children[0].text.length > 0)
    ) {
      return true;
    }
    return false;
  }
  return (
    <>
      <HStack
        justifyContent={"space-between"}
        style={{
          marginLeft: `${(indentationLevel - 1) * 20}px`,
        }}
        gap={1}
      >
        <HStack>
          <Text>{titleNumber}</Text>
          <Text variant="bold">{title}</Text>
          <RequiredIndicator required={required} />
        </HStack>
        <AudienceDisplay audience={audience} buildView={buildView} />
      </HStack>
      <Spacer y={SpacerSizes.sm} />
      <SubmissionAdmonition adjudicatorNotes={adjudicatorNotes} submitterNotes={surveyTakerNotes} role={role} />

      {displayQuestion() ? (
        <>
          <Spacer y={SpacerSizes.sm} />
          <RichText placeholder={questionPlaceholder} size="fit" value={question} readOnly={true} showToolbar={false} borderColor="border-none" />
        </>
      ) : null}
    </>
  );
};
