import { useEffect, useState } from "react";
import { Center, Heading, Flex, Card, CardBody, Spacer as FlexSpacer, Tabs, TabList, TabPanel, TabPanels, Tab } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { PageInfo } from "../components/TopNavBar/PageInfo";
import { useTopNavContext } from "../context/TopNavContext";
import { Colors } from "../theme/colors";
import { useSelector } from "react-redux";
import { useSubmissionAPI } from "../endpoints/submissions";
import { SubmissionsSection } from "./SubmissionsPageComponents/SubmissionSection";
import { EmptySubmissionsSection } from "./SubmissionsPageComponents/EmptySubmissionsSection";
import { LoadingSubmissions } from "./SubmissionsPageComponents/LoadingSubmissions";

export default function Submissions() {
  const [submissionsList, setSubmissionsList] = useState<any[]>([]);
  const [finalizedSubmissionsList, setFinalizedSubmissionsList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { getSubmissions } = useSubmissionAPI();
  const { setPageInfo } = useTopNavContext();
  const currentOrgMembership = useSelector((state: any) => state.auth.globalUserInfo?.currentOrgMembership);

  useEffect(() => {
    setPageInfo(<PageInfo title="Submissions" />);

    return () => {
      setPageInfo(<PageInfo />);
    };
  }, [setPageInfo]);

  async function refreshAllSubmissions() {
    setLoading(true);
    await refreshSubmissions();
    await refreshFinalizedSubmissions();
    setLoading(false);
  }

  const refreshSubmissions = async () => {
    await getSubmissions()
      .then((data) => {
        setSubmissionsList(data as any);
      })
      .catch((err) => console.log(err));
    return;
  };

  const refreshFinalizedSubmissions = async () => {
    await getSubmissions("finalized=true")
      .then((data) => {
        setFinalizedSubmissionsList(data as any);
      })
      .catch((err) => console.log(err));
    return;
  };

  useEffect(() => {
    refreshAllSubmissions();
  }, [currentOrgMembership]);

  return (
    <div style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "5%" }}>
      <Flex>
        <Center mt="12px" mb="12px">
          <Heading size="md">Manage Submissions</Heading>
        </Center>
        <FlexSpacer />
      </Flex>
      <Spacer y={SpacerSizes.sm} />
      <Tabs>
        <TabList>
          <Tab>In Progress</Tab>
          <Tab>Finalized</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {loading ? (
              <LoadingSubmissions />
            ) : submissionsList.length ? (
              <SubmissionsSection submissions={submissionsList} refreshSubmissions={refreshAllSubmissions} finalizePanel={false} />
            ) : (
              <EmptySubmissionsSection />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <LoadingSubmissions />
            ) : finalizedSubmissionsList.length ? (
              <SubmissionsSection submissions={finalizedSubmissionsList} refreshSubmissions={refreshAllSubmissions} finalizePanel={true} />
            ) : (
              <Card style={{ cursor: "pointer" }}>
                <CardBody
                  style={{
                    backgroundColor: "white",
                    border: "dashed",
                    borderWidth: "1px",
                    borderColor: Colors.brand[500],
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Heading variant="h2" color="brand.500" mt="6px" mb="6px">
                    You have no finalized Submissions.
                  </Heading>
                  <Spacer x={SpacerSizes.md} />
                </CardBody>
              </Card>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Spacer y={SpacerSizes.xl} />
    </div>
  );
}
