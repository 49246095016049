import BalanceOutlinedIcon from "@mui/icons-material/BalanceOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Text, Tooltip, Icon, HStack } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../../../components/Spacer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RichText from "./RichText";
import { useMemo } from "react";
import { ROLE_1 } from "../../../roles";

export const AdvancedNotes = ({
  onChange,
  surveyTakerNotes,
  adjudicatorNotes,
}: {
  onChange: (e: any, field: string) => void;
  surveyTakerNotes?: any;
  adjudicatorNotes?: any;
}) => {
  const advancedNotes = useMemo(
    () => (
      <Flex flexDirection="column">
        <Flex flexDirection="row" alignItems={"flex-end"} justifyContent={"space-between"}>
          <HStack>
            <Text contentEditable={false}>Notes and Instructions</Text>
            <Tooltip label="Optional notes and instructions for the form assignee. This text is never included in the final document output." fontSize="xs">
              <Icon color="gray.500" boxSize={"4"} as={HelpOutlineIcon} />
            </Tooltip>
          </HStack>
        </Flex>
        <Spacer y={SpacerSizes.sm} />
        <Tabs borderColor="gray.200" borderWidth="2px" colorScheme="brightblue">
          <TabList gap={0} contentEditable={false} style={{ userSelect: "none" }}>
            <Tab>
              <Icon boxSize={4} as={ChecklistOutlinedIcon} color="gray.500" />
              <Spacer x={SpacerSizes.xs} />
              <Text variant={"caption"}>
                {ROLE_1.charAt(0).toUpperCase()}
                {ROLE_1.slice(1)}
              </Text>
            </Tab>
            <Tab>
              <Icon boxSize={4} as={BalanceOutlinedIcon} color="gray.500" />
              <Spacer x={SpacerSizes.xs} />
              <Text variant="caption">Reviewer</Text>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel style={{ minHeight: 150, padding: 0 }}>
              <RichText onChange={(e) => onChange(e, "surveyTakerNotes")} value={surveyTakerNotes} borderColor="border-none" simple={true} />
            </TabPanel>
            <TabPanel style={{ minHeight: 150, padding: 0 }}>
              <RichText onChange={(e) => onChange(e, "adjudicatorNotes")} value={adjudicatorNotes} borderColor="border-none" simple={true} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    ),
    [surveyTakerNotes, adjudicatorNotes]
  );

  return advancedNotes;
};
