import { useCallback, useState, useEffect } from "react";
import { Checkbox, Heading, VStack, Input, Text, Flex, Spacer } from "@chakra-ui/react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { useAuthContext } from "../context/AuthContext";

export const Login = () => {
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [saveUsername, setSaveUsername] = useState(false);

  const sendUnauthenticatedAPIRequest = useCallback(
    async (route: string, method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE" = "GET", body?: { [key: string]: any }, organizationIdOverride?: string) => {
      const headers = {
        "Content-Type": "application/json",
      };

      const options: RequestInit = {
        method,
        headers,
      };

      if (body) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL!}/${route}`, options);
      const data = await response.json();
      return data;
    },
    []
  );

  useEffect(() => {
    const email = sessionStorage.getItem("loginEmail");
    if (email) {
      setEmailAddress(email);
      setEmailIsValid(true);
      setSaveUsername(true);
    }
  }, []);

  async function doLogin() {
    if (saveUsername) {
      console.log("saving username");
      sessionStorage.setItem("loginEmail", emailAddress);
    }
    const resp = await sendUnauthenticatedAPIRequest(`public/login`, "POST", {
      email: emailAddress,
    });

    navigate("/post-login", { state: resp });
  }

  function setEmailAddressWrapper(event: any) {
    let emailValid = false;
    const emailParts = (event.target.value as string).split("@");
    if (emailParts.length == 2 && emailParts[0].length > 0 && emailParts[1].length > 0) {
      emailValid = true;
    }
    setEmailAddress(event.target.value);
    setEmailIsValid(emailValid);
  }

  function setSaveUsernameWrapper(event: any) {
    console.log("event.target.checked");
    if (event.target.checked) {
      console.log("checked");
      setSaveUsername(true);
    } else {
      setSaveUsername(false);
    }
  }

  const { session } = useAuthContext();
  const navigate = useNavigate();
  const LoginComponent = () => {
    return (
      <>
        <div
          style={{
            marginTop: "10%",
            paddingLeft: "25%",
            paddingRight: "25%",
          }}
        >
          <Heading>Welcome</Heading>
          <VStack spacing="0px" mt="16px">
            <Flex w="100%">
              <Spacer mr="auto" />
              <Text fontSize="12px" textAlign="right" ml="auto">
                You&apos;ll be added to our wating list if you don&apos;t have an account.
              </Text>
            </Flex>
            <Input placeholder="Enter your email address to login." size="md" w="100%" onChange={setEmailAddressWrapper} value={emailAddress}></Input>
            <Flex w="100%" alignItems="start">
              {emailIsValid ? (
                <Button onClick={doLogin}>Login</Button>
              ) : (
                <Button isDisabled variant="disabled" onClick={doLogin}>
                  Login
                </Button>
              )}
              <Checkbox onChange={setSaveUsernameWrapper} m="4px" isChecked={saveUsername}></Checkbox>
              <Text fontSize="14px">Remember my email address.</Text>
            </Flex>
          </VStack>
        </div>
      </>
    );
  };

  return session ? <Navigate to="/" replace /> : <>{LoginComponent()}</>;
};
