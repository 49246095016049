import { CommentThread, Comment, FormUser, MentionableUser } from "../types";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useOrganizationAPI, useFormAPI, useSubmissionAPI } from "../endpoints/_index";
import { RootState } from "../store";

const GuestOrgMember = {
  membershipType: "guest",
  permissions: {
    inviteUser: true,
  },
};

function useUpdateConversation() {
  const globalUserInfo = useSelector((state: any) => state.auth.globalUserInfo);
  const mentionableMembers = useSelector((state: RootState) => state.org.mentionableMembers);
  const userId = globalUserInfo?.user._id;
  const userEmail = globalUserInfo?.user.email;
  const toast = useToast();
  const { inviteNewUser, refreshOrganizationMembers } = useOrganizationAPI();
  const { addUsersToForm } = useFormAPI();
  const { addSubmissionUsers } = useSubmissionAPI();

  const inviteUsers = async (emails: string[]) => {
    if (!emails.length) return;

    try {
      const resArray = await Promise.all(emails.map((email) => inviteNewUser({ email, orgMembership: GuestOrgMember })));
      toast({
        title: `${emails.join(", ")} invited to join ${globalUserInfo.currentOrgMembership.organization!.name}`,
        status: "success",
        containerStyle: { textColor: "white" },
      });

      return resArray;
    } catch (err) {
      toast({
        title: `Unable to invite ${emails.join(", ")}`,
        status: "error",
        containerStyle: { textColor: "white" },
      });
      return err;
    }
  };

  const addUsers = (userIds: string[], documentId: string, userNames: string[]) => {
    if (!userIds.length) return;
    const isFormBuilder = window.location.pathname.includes("form-builder");

    const usersToAdd: FormUser[] = userIds.map(
      (user: string): FormUser => ({
        userId: user,
        role: isFormBuilder ? "editor" : "submitter",
        isApproved: true,
      })
    );

    try {
      isFormBuilder ? addUsersToForm(documentId, usersToAdd) : addSubmissionUsers(documentId, usersToAdd);
      toast({ title: `${userNames.join(", ")} added to ${isFormBuilder ? "form" : "submission"}`, status: "success", containerStyle: { textColor: "white" } });
    } catch (err) {
      toast({
        title: `Unable to add ${userNames.join(", ")} to ${isFormBuilder ? "form" : "submission"}`,
        status: "error",
        containerStyle: { textColor: "white" },
      });
      console.error(err);
    }
  };

  const findUserIdsToAdd = (mentionedOrAssigned: string[], currentMembers: FormUser[]) => {
    const formMembersIds = currentMembers.map((member) => member.userId);

    const userIdsToAddToForm = [];

    for (const user of mentionedOrAssigned) {
      // find entry with matching email or text in mentionableMembers
      const userInfo = mentionableMembers.find((member: MentionableUser) => member.text === user || member.data.email === user);

      // if user is not in formMembers, add them
      if (userInfo && !formMembersIds.includes(userInfo.key)) {
        userIdsToAddToForm.push(userInfo.key);
      }
    }

    return userIdsToAddToForm;
  };

  const addNewConversation = async (
    comment: string,
    type: string,
    updateConversation: (x: CommentThread) => void,
    documentId: string,
    formMembers: FormUser[],
    assignedTo?: string[],
    mentioned?: string[]
  ) => {
    const combinedMentionedAssigned = assignedTo ?? mentioned ?? [];

    const usersToInvite = combinedMentionedAssigned.filter(
      (user: any) => mentionableMembers.findIndex((member) => member.data.email === user || member.text === user) === -1
    );

    const userIdsToAddToForm = findUserIdsToAdd(combinedMentionedAssigned, formMembers);

    try {
      const invitedUsers: any = await inviteUsers(usersToInvite);
      const invitedUserIds = invitedUsers.map((invitedUser: any) => {
        return invitedUser.userId;
      });
      if (invitedUserIds) userIdsToAddToForm.push(...invitedUserIds);
      refreshOrganizationMembers();
    } catch (e) {
      //
    }

    addUsers(userIdsToAddToForm, documentId, combinedMentionedAssigned);

    const newConversation: CommentThread = {
      type,
      resolved: false,
      comments: [
        {
          createdAt: new Date().toISOString(),
          user: userId!,
          content: comment,
          assignedTo: assignedTo,
          mentioned: mentioned,
          userEmail: userEmail!,
          pending: false,
        },
      ],
    };

    updateConversation(newConversation);

    return;
  };

  const addNewComment = async (
    comment: any,
    commentThreads: CommentThread[],
    commentThread: CommentThread,
    updateConversations: (x: CommentThread[]) => void,
    documentId: string,
    formMembers: FormUser[],
    assignedTo?: string[],
    mentioned?: string[]
  ) => {
    const mentionedOrAssigned: string[] = assignedTo ?? mentioned ?? [];

    const usersToInvite = mentionedOrAssigned.filter(
      (user: string) => mentionableMembers.findIndex((member) => member.data.email === user || member.text === user) === -1
    );

    const userIdsToAddToForm = findUserIdsToAdd(mentionedOrAssigned, formMembers);
    try {
      const invitedUsers: any = await inviteUsers(usersToInvite);
      const invitedUserIds = invitedUsers.map((invitedUser: any) => {
        return invitedUser.userId;
      });
      if (invitedUserIds) userIdsToAddToForm.push(...invitedUserIds);
      refreshOrganizationMembers();
    } catch (e) {
      //
    }

    addUsers(userIdsToAddToForm, documentId, mentionedOrAssigned);

    const newComment: Comment = {
      user: userId!,
      content: comment,
      createdAt: new Date().toISOString(),
      assignedTo,
      mentioned,
      userEmail: userEmail!,
      pending: false,
    };

    const updatedThreads: CommentThread[] = commentThreads.map((thread: CommentThread) => {
      if (thread === commentThread) {
        return {
          ...thread,
          comments: [...thread.comments, newComment],
        };
      } else {
        return thread;
      }
    });

    updateConversations(updatedThreads);
  };

  const resolveConversation = (commentThread: CommentThread, updateConversations: (x: CommentThread[]) => void, commentThreads: CommentThread[]) => {
    const updatedCommentThreads: CommentThread[] = commentThreads.map((thread) => {
      if (thread === commentThread) {
        return {
          ...thread,
          resolved: true,
        };
      }
      return thread;
    });

    updateConversations(updatedCommentThreads);
  };

  return { addNewConversation, addNewComment, resolveConversation };
}

export default useUpdateConversation;
