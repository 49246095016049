import React, { useState } from "react";
import { HStack, Input, Text } from "@chakra-ui/react";
import { FormElementTypes, SubmissionElementTypes } from "../../types";

interface BuildViewProps {
  value: FormElementTypes.Section;
  id: string;
  titleNumber: string;
  children?: JSX.Element[];
  onChange: (e: any, field: string) => void;
  indentationLevel: number;
}

const Default = {
  type: "section",
  content: { title: "" },
};

const BuildView: React.FC<BuildViewProps> = ({ value, children, onChange, titleNumber, indentationLevel }) => {
  const [title, setTitle] = useState<string>(value.title ?? "");

  const updateTitle = (e: any) => {
    onChange(e.target.value, "title");
    setTitle(e.target.value);
  };

  return (
    <>
      <HStack
        gap={1}
        style={{
          marginLeft: `${(indentationLevel - 1) * 20}px`,
        }}
      >
        <Text>{titleNumber}</Text>
        <Input
          variant="unstyled"
          style={{
            fontWeight: "bold",
          }}
          type="text"
          placeholder={"Untitled Section"}
          value={title}
          onChange={updateTitle}
        />
      </HStack>
      {children}
    </>
  );
};

const BuildViewDebouncedChanges: string[] = ["title", "surveyTakerNotes", "adjudicatorNotes"];

interface EditViewProps {
  value: SubmissionElementTypes.Section;
  titleNumber: string;
  indentationLevel: number;
  children?: JSX.Element[];
}

const EditView: React.FC<EditViewProps> = ({ value, titleNumber, indentationLevel, children }) => {
  return (
    <>
      <HStack
        style={{
          marginLeft: `${(indentationLevel - 1) * 20}px`,
        }}
        gap={1}
      >
        <Text>{titleNumber}</Text>
        <Text variant="bold">{value.title}</Text>
      </HStack>
      {children}
    </>
  );
};

const EditViewDebouncedChanges: string[] = [];

export default { BuildView, EditView, Default, EditViewDebouncedChanges, BuildViewDebouncedChanges };
