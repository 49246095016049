import { useAPIRequest } from "../hooks/useAPI";

export const useUserAPI = () => {
  const sendAPIRequest = useAPIRequest();
  const getUser = async (organizationIdOverride?: string) => {
    return await sendAPIRequest(`user`, "GET", undefined, organizationIdOverride);
  };

  const getUserById = async (userId: string) => {
    return await sendAPIRequest(`user/${userId}`, "GET");
  };

  const updateUser = async (user: any) => {
    return await sendAPIRequest(`user`, "PATCH", user);
  };

  return { getUser, updateUser, getUserById };
};
