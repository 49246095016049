import { OrganizationPrivilegeSettings, UserQuery } from "../types";

// action is an event that describes something that happened in the application.
export const SET_GLOBAL_USER_INFO = "auth/updateGlobalUserInfo";
export const SET_ORGANIZATION_ID = "auth/updateOrganizationId";
export const SET_SESSION = "auth/updateSession";
export const SET_ORGANIZATION_PRIVILEGE = "auth/updateOrganizationPrivilege";

export function setGlobalUserInfo(globalUserInfo: UserQuery) {
  return { type: SET_GLOBAL_USER_INFO, payload: globalUserInfo };
}

export function setOrganizationId(organizationId: string) {
  return { type: SET_ORGANIZATION_ID, payload: organizationId };
}

export function setSession(session: any) {
  return { type: SET_SESSION, payload: session };
}

export function updateOrganizationPrivilege(privilegeSettings: OrganizationPrivilegeSettings) {
  return { type: SET_ORGANIZATION_PRIVILEGE, payload: privilegeSettings };
}
