import { useState } from "react";
import { VStack, Text, Card, useToast, Tooltip, useDisclosure } from "@chakra-ui/react";
import { FormsTable } from "./FormsTable";
import { EnrichedFormContentless } from "../../types";
import { CreateSubmissionModal } from "../../components/Modal/CreateSubmissionModal";
import { useNavigate } from "react-router-dom";
import { SharingModal } from "../../components/Modal/SharingModal";
import { useFormAPI } from "../../endpoints/forms";
import { ApproveSharingModal } from "../../components/Modal/ApproveSharingModal";

export const FormsSection = ({ forms, refreshForms, archivePanel }: { forms: any; refreshForms: () => void; archivePanel: boolean }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [formIdForModal, setFormIdForModal] = useState("");
  const [formForModal, setFormForModal] = useState<EnrichedFormContentless>();
  const [becomeAdjudicatorForModal, setBecomeAdjudicatorForModal] = useState(false);
  const [formNameForModal, setFormNameForModal] = useState("");
  const { isOpen: submissionModalIsOpen, onOpen: openSubmissionModal, onClose: closeSubmissionModal } = useDisclosure();
  const { isOpen: sharingModalIsOpen, onOpen: openSharingModal, onClose: closeSharingModal } = useDisclosure();
  const { isOpen: sharingApprovalModalIsOpen, onOpen: openSharingApprovalModal, onClose: closeSharingApprovalModal } = useDisclosure();
  const { archiveForm, copyForm, unarchiveForm } = useFormAPI();

  function truncate(inStr: string, idx: number) {
    const maxlen = 40;
    return inStr.length > maxlen ? (
      <Tooltip key={`form-desc-tt-${idx}`} label={inStr} openDelay={1000}>
        {inStr.slice(0, maxlen - 1) + "..."}
      </Tooltip>
    ) : (
      inStr
    );
  }

  const headers = ["", "Title", "Description", "Last Updated", "Actions"];
  const rowsData = forms.map((form: any, idx: number) => [
    form.meta.title,

    truncate(form.meta.description, idx),
    <VStack key={`form-timestamp-${idx}`} spacing="0px">
      <Text fontSize="11px">{new Date(form.meta.updatedAt).toDateString()}</Text>
      <Text fontSize="11px">{new Date(form.meta.updatedAt).toLocaleTimeString()}</Text>
    </VStack>,
  ]);
  const rowsAllowedActions = forms.map((form: any) => form.readOnly.actions);
  const rowsAttentionItems = forms.map((form: any) => form.readOnly.attentionItems);
  const rowClickHandlers = forms.map((form: any) => () => navigate(`/form-builder/${form._id}`));

  const handleCopy = async (formIndex: number) => {
    const formId = forms[formIndex]._id;

    await copyForm(formId);
    refreshForms();
    toast({
      title: "Form copied successfully.",
      status: "success",
      containerStyle: {
        textColor: "white",
      },
    });
  };

  const handleArchive = async (formIndex: number) => {
    const formId = forms[formIndex]._id;

    await archiveForm(formId);
    refreshForms();
    toast({
      title: "Form archived successfully.",
      status: "success",
      containerStyle: {
        textColor: "white",
      },
    });
  };

  const handleUnarchive = async (formIndex: number) => {
    const formId = forms[formIndex]._id;

    await unarchiveForm(formId);
    refreshForms();
    toast({
      title: "Form unarchived successfully.",
      status: "success",
      containerStyle: {
        textColor: "white",
      },
    });
  };

  const handleSharingApproval = async (formIndex: number) => {
    const formId = forms[formIndex]._id;
    setFormIdForModal(formId);
    setFormForModal(forms[formIndex]);
    openSharingApprovalModal();
  };

  const handleCreateSubmission = async (formIndex: number) => {
    const formId = forms[formIndex]._id;
    setFormIdForModal(formId);
    setFormNameForModal(forms[formIndex].meta.title);
    setBecomeAdjudicatorForModal(rowsAllowedActions[formIndex].becomeAdjudicator);
    openSubmissionModal();
  };

  const handleSharing = async (formIndex: number) => {
    setFormForModal(forms[formIndex]);
    console.log(`handleSharing ${formIndex}`);
    openSharingModal();
  };

  const rowCallbacks = {
    copy: (index: any) => handleCopy(index),
    archive: (index: any) => handleArchive(index),
    unarchive: (index: any) => handleUnarchive(index),
    sharing: (index: any) => handleSharing(index),
    submission: (index: any) => handleCreateSubmission(index),
    sharingApproval: (index: any) => handleSharingApproval(index),
  };

  const closeApprovalModal = () => {
    closeSharingApprovalModal();
    refreshForms();
  };

  return (
    <>
      {submissionModalIsOpen ? (
        <CreateSubmissionModal
          isOpen={submissionModalIsOpen}
          onClose={closeSubmissionModal}
          formId={formIdForModal}
          formName={formNameForModal}
          becomeAdjudicator={becomeAdjudicatorForModal}
        />
      ) : null}

      {sharingApprovalModalIsOpen ? (
        <ApproveSharingModal document={formForModal} type="form" isOpen={sharingApprovalModalIsOpen} onClose={closeApprovalModal} />
      ) : null}

      <SharingModal document={formForModal} type="form" isOpen={sharingModalIsOpen} onOpen={openSharingModal} onClose={closeSharingModal} />

      <Card variant="outline">
        <FormsTable
          headers={headers}
          rowsData={rowsData}
          rowsAllowedActions={rowsAllowedActions}
          rowsAttentionItems={rowsAttentionItems}
          rowCallbacks={rowCallbacks}
          rowClickHandlers={rowClickHandlers}
          archivePanel={archivePanel}
        />
      </Card>
    </>
  );
};
