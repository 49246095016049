import React, { useMemo, useState, useEffect } from "react";
import RichText from "./ElementComponents/RichText";
import { RequiredToggle } from "./ElementComponents/RequiredToggle";
import { AdvancedSectionWrapper } from "./ElementComponents/AdvancedSectionWrapper";
import { nanoid } from "nanoid";
import {
  Text,
  RadioGroup,
  Stack,
  Radio,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  Tooltip,
  Icon,
  HStack,
  StackDivider,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../../components/Spacer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { AdvancedNotes } from "./ElementComponents/AdvancedNotes";
import { SubmissionElementTypes, FormElementTypes } from "../../types";
import { useSelectionContext } from "../../context/SelectionContext";
import { ROLE_2, ROLE_1 } from "../../roles";
import { BuilderHeader, ResponseHeader } from "./ElementComponents/QuestionHeaders";
import _ from "lodash";

interface BuildViewProps {
  value: FormElementTypes.TextResponse;
  titleNumber: string;
  indentationLevel: number;
  id: string;
  onChange: (e: any, field: string) => void;
}

const Default = {
  type: "textResponse",
  content: {
    audience: ROLE_1,
    title: "",
    textType: "rich",
    surveyTakerNotes: {},
    adjudicatorNotes: {},
    question: "",
    required: true,
    lengthLimit: 512,
    response: "",
  },
};

const BuildView: React.FC<BuildViewProps> = ({ value, titleNumber, indentationLevel, id, onChange }) => {
  const { selectedElement } = useSelectionContext();
  const [textType, setTextType] = useState<string>(value.textType);
  const [textLimit, setTextLimit] = useState<number>(value.lengthLimit);
  const [required, setRequired] = useState<boolean>(value.required);
  const [isSelectedElement, setIsSelectedElement] = useState(false);

  useEffect(() => {
    setIsSelectedElement(selectedElement === id);
  }, [selectedElement]);

  const updateRequired = () => {
    onChange(!required, "required");
    setRequired(!required);
  };

  const updateTextLimit = (e: any) => {
    onChange(e, "lengthLimit");
    setTextLimit(e);
  };

  const updateTextType = (e: any) => {
    onChange(e, "textType");
    setTextType(e);
  };

  const textResponseComponents = useMemo(
    () => (
      <>
        <BuilderHeader
          questionPrompt="Question"
          title={value.title}
          titlePlaceholder="Untitled Text Response"
          titleNumber={titleNumber}
          indentationLevel={indentationLevel}
          audience={value.audience}
          required={value.required}
          onChange={onChange}
        />
        <RichText placeholder="Enter the question" value={value.question} onChange={(e) => onChange(e, "question")} selected={isSelectedElement} />
        <Spacer y={SpacerSizes.sm} />
        <RequiredToggle value={required} onChange={updateRequired} />
        <AdvancedSectionWrapper>
          <HStack alignItems={"flex-start"} gap={1} w={"97%"} divider={<StackDivider borderColor="gray.600" />}>
            <div style={{ width: "40%" }}>
              <Text>Response Text Length</Text>
              <RadioGroup
                style={{
                  padding: 30,
                  paddingTop: 10,
                }}
                name="Response Text"
                onChange={updateTextType}
                value={textType}
              >
                <Stack direction="column">
                  <HStack>
                    <Radio colorScheme={"brightblue"} value="rich">
                      Rich Text
                    </Radio>
                    <Tooltip label="Allow a rich text response with full formatting functionality and no length limit" fontSize="xs">
                      <Icon color="gray.500" boxSize={"4"} as={HelpOutlineIcon} />
                    </Tooltip>
                  </HStack>
                  <Radio colorScheme={"brightblue"} value="short answer">
                    <HStack>
                      <Text>Short Answer with character limit</Text>
                      <Tooltip label="Allow only a plain text, single line response limited to at most 512 characters." fontSize="xs">
                        <Icon color="gray.500" boxSize={"4"} as={HelpOutlineIcon} />
                      </Tooltip>
                    </HStack>
                    <NumberInput size="sm" maxW={40} value={textLimit} min={0} onChange={updateTextLimit}>
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Radio>
                </Stack>
              </RadioGroup>
            </div>
            <div style={{ width: "60%" }}>
              <AdvancedNotes surveyTakerNotes={value.surveyTakerNotes} adjudicatorNotes={value.adjudicatorNotes} onChange={(e, field) => onChange(e, field)} />
            </div>
          </HStack>
        </AdvancedSectionWrapper>
      </>
    ),
    [id, indentationLevel, isSelectedElement, selectedElement, titleNumber, value, textType, textLimit, required]
  );

  return isSelectedElement ? (
    textResponseComponents
  ) : (
    <EditView role="adjudicator" value={value} id={id} titleNumber={titleNumber} onChange={onChange} indentationLevel={indentationLevel} buildView={true} />
  );
};

const BuildViewDebouncedChanges: string[] = ["title", "question", "surveyTakerNotes", "adjudicatorNotes"];

interface EditViewProps {
  value: SubmissionElementTypes.TextResponse | FormElementTypes.TextResponse;
  titleNumber: string;
  onChange: (e: any, field: string) => void;
  indentationLevel: number;
  id: string;
  role: string;
  buildView?: boolean;
}

const EditView: React.FC<EditViewProps> = ({ id, value, titleNumber, onChange, indentationLevel, role, buildView = false }) => {
  const [response, setResponse] = useState(value.response);
  const [key, setKey] = useState("id");
  const { selectedElement } = useSelectionContext();
  const selected = selectedElement === id;

  useEffect(() => {
    if (!_.isEqual(value.response, response)) {
      setResponse(value.response);
      setKey(nanoid());
    }
  }, [value.response, response]);

  const roleMatchesAudience = value.audience === "all" || value.audience === role;
  if (!roleMatchesAudience && role !== ROLE_2) {
    return null;
  }

  return (
    <div>
      <ResponseHeader
        title={value.title}
        indentationLevel={indentationLevel}
        titleNumber={titleNumber}
        required={value.required}
        role={role}
        adjudicatorNotes={value.adjudicatorNotes}
        surveyTakerNotes={value.surveyTakerNotes}
        audience={value.audience}
        buildView={buildView}
        questionPlaceholder="Enter the question"
        question={value.question}
      />
      <Text ml="12px">Response</Text>
      {value.textType === "rich" ? (
        <RichText
          placeholder="Please enter a response"
          key={key}
          value={response}
          size="fit"
          borderColor={selected ? "" : "border-none"}
          readOnly={!roleMatchesAudience}
          showToolbar={selected && roleMatchesAudience}
          onChange={(e) => onChange(e, "response")}
          simple={true}
        />
      ) : (
        <RichText
          size="fit"
          placeholder="Please enter a response"
          key={key}
          value={response}
          borderColor={selected ? "" : "border-none"}
          readOnly={!roleMatchesAudience}
          onChange={(e) => onChange(e, "response")}
          showToolbar={false}
        />
      )}
    </div>
  );
};

const EditViewDebouncedChanges: string[] = ["response"];

export default { BuildView, EditView, Default, EditViewDebouncedChanges, BuildViewDebouncedChanges };
