import { Flex, Collapse, Icon, Divider, Text, Stack } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import { Spacer, SpacerSizes } from "../../../components/Spacer";

const ICON_SPACING = "0";

export const AdvancedSectionWrapper = ({ children }: { children: ReactElement }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack
      w={"100%"}
      style={{
        alignSelf: "center",
        justifyContent: "flex-end",
      }}
    >
      <Collapse in={open} animateOpacity>
        <div
          style={{
            justifySelf: "center",
          }}
        >
          <Spacer y={SpacerSizes.md} />
          <Divider borderColor={"gray.600"} />
          <Spacer y={SpacerSizes.sm} />
          <Text style={{ textAlign: "center" }} variant="bold">
            Advanced
          </Text>
          <Spacer y={SpacerSizes.md} />
          <Flex style={{ justifyContent: "center" }}>{children}</Flex>
        </div>
      </Collapse>

      <Icon
        style={{
          position: "relative",
          bottom: ICON_SPACING,
          left: "97%",
          height: 25,
        }}
        p={1}
        _hover={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
        boxSize={7}
        as={open ? UnfoldLessIcon : TuneIcon}
      />
    </Stack>
  );
};
