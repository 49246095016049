import { useState, useEffect, useCallback } from "react";
import { Center, Heading, HStack, Icon, MenuList, MenuItem, Text, useDisclosure, Tooltip, Collapse, Spinner } from "@chakra-ui/react";
import _ from "lodash";
import { useTopNavContext } from "../context/TopNavContext";
import { PrivilegeAction } from "../components/TopNavBar/PrivilegeAction";
import { PageInfo } from "../components/TopNavBar/PageInfo";
import ConstructionOutlinedIcon from "@mui/icons-material/Construction";
import { PRIVILEGE_BANNER_HEIGHT, PrivilegedBanner } from "../components/PrivilegedBanner";
import { HEADER_HEIGHT } from "../components/TopNavBar/TopNavContainer";
import { Colors } from "../theme/colors";
import ElementMap from "./Elements/_index";
import useFormManager from "../hooks/useFormManager";
import { DocumentSectionWrapper } from "./Elements/ElementComponents/DocumentSectionWrapper";
import { CommentThread, FormElement, Form } from "../types";
import { SaveStates, SaveIndicator } from "../components/TopNavBar/SaveIndicator";
import { CommentFilter } from "./Comments/CommentFilter";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormDescriptionModal } from "../components/Modal/FormDescriptionModal";
import { SUBMISSION_STATUS_HEIGHT } from "../components/SubmissionStatusDisplay";
import { SharingModal } from "../components/Modal/SharingModal";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import { CreateSubmissionModal } from "../components/Modal/CreateSubmissionModal";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useFormAPI } from "../endpoints/forms";
import { RulesModal } from "../components/Modal/RulesModal/RulesModal";
import RuleFolderOutlinedIcon from "@mui/icons-material/RuleFolderOutlined";
import { PrivilegeConfirmationModal } from "../components/Modal/PrivilegeConfirmationModal";
import { useParams } from "react-router-dom";
import { AutoWidthInput } from "../components/AutoWidthInput";

const debouncedUpdate = _.debounce(
  async (setSaving, documentId, value, updateFormElements) => {
    setSaving(SaveStates.inProgress);
    const formValue = { ...value, _id: undefined };
    await updateFormElements(documentId, formValue);
    setSaving(SaveStates.saved);
  },

  300
);

const debouncedUpdateTitle = _.debounce(
  async (setSaving, documentId, title, updateFormTitle) => {
    setSaving(SaveStates.inProgress);
    await updateFormTitle(documentId, title);
    setSaving(SaveStates.saved);
  },

  300
);

const debouncedUpdateMeta = _.debounce((newValue: any, field: string, form: Form, formFunctions: any) => {
  const newMeta: Form["meta"] = {
    ...form.meta,
    [field]: newValue,
  };

  formFunctions.updateMeta({ meta: newMeta });
}, 500);

const debouncedUpdateFormFields = _.debounce((element: FormElement, newValue: any, field: string, formFunctions: any) => {
  formFunctions.updateElements({
    elements: [
      {
        ...element,
        content: { ...element.content, [field]: newValue },
      },
    ],
  });
}, 100);

export const FormBuilder = () => {
  const { formFunctions, setForm, form, commentFunctions } = useFormManager();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(SaveStates.saved);
  const [documentId, setDocumentId] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [displayPrivilegeBanner, setDisplayPrivilegeBanner] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [elements, setElements] = useState<FormElement[]>([]);
  const { isOpen: descriptionModalIsOpen, onOpen: openDescriptionModal, onClose: closeDescriptionModal } = useDisclosure();
  const { isOpen: sharingModalIsOpen, onOpen: openSharingModal, onClose: closeSharingModal } = useDisclosure();
  const { isOpen: submissionModalIsOpen, onOpen: openSubmissionModal, onClose: closeSubmissionModal } = useDisclosure();
  const { isOpen: rulesModalIsOpen, onOpen: openRulesModal, onClose: closeRulesModal } = useDisclosure();
  const [displayPrivilegeConfirmation, setDisplayPrivilegeConfirmation] = useState(displayPrivilegeBanner);
  const queryParameters = new URLSearchParams(window.location.search);
  const id = useParams().id || queryParameters.get("id");
  const userId = useSelector((state: RootState) => state.auth.globalUserInfo?.user?._id);
  const currentOrgMembership = useSelector((state: RootState) => state.auth.globalUserInfo?.currentOrgMembership);
  const { getFormById, updateFormTitle, updateFormElements } = useFormAPI();

  const { setPageInfo, setPageActions } = useTopNavContext();

  const getFile = useCallback(async () => {
    if (id) {
      setDocumentId(id);
      const doc: any = await getFormById(id);
      if (Object.keys(doc).length > 0) {
        setForm(doc);
        setTitle(doc.meta?.title);
        setDisplayPrivilegeBanner(doc.meta?.privileged);
        setDisplayPrivilegeConfirmation(doc.meta?.privileged);
      } else {
        setNotFound(true);
      }
    } else {
      setNotFound(true);
    }

    setLoading(false);
    return;
  }, [id, currentOrgMembership]);

  useEffect(() => {
    setLoading(true);
    getFile();
  }, [id, currentOrgMembership]);

  useEffect(() => {
    if (loading) return;
    const menuList = (
      <MenuList color="gray.600">
        <MenuItem onClick={openDescriptionModal}>
          <HStack>
            <Icon as={InfoOutlinedIcon} boxSize=".75em" mr="4px"></Icon>
            <Text>Form Description</Text>
          </HStack>
        </MenuItem>
      </MenuList>
    );
    setPageInfo(
      <PageInfo saveIndicator={<SaveIndicator saveStatus={saving} savedOnce={documentId} />} menuList={menuList}>
        <Icon as={ConstructionOutlinedIcon} color="white" />
        <AutoWidthInput
          initialValue={title}
          onChange={(e) => {
            setSaving(SaveStates.unsavedChanges);
            debouncedUpdateMeta(e.target.value, "title", form, formFunctions);
            debouncedUpdateTitle(setSaving, documentId, e.target.value, updateFormTitle);
          }}
        />
      </PageInfo>
    );

    return () => {
      setPageInfo(<PageInfo />);
    };
  }, [title, saving, documentId, setPageInfo, loading]);

  useEffect(() => {
    if (loading) return;

    setPageActions(
      <HStack>
        {documentId && form.readOnly?.actions.submit ? (
          <Tooltip label="Submit Form or Assign Submission" aria-label="Submit form or assign submission.">
            <Icon as={AssignmentIndOutlinedIcon} onClick={openSubmissionModal} />
          </Tooltip>
        ) : null}
        <Tooltip label="Share Form" aria-label="Share form.">
          <Icon as={PersonAddAlt1OutlinedIcon} onClick={openSharingModal} />
        </Tooltip>
        <Tooltip label="Assign Rules to Document Elements" aria-label="Rules.">
          <Icon as={RuleFolderOutlinedIcon} onClick={openRulesModal} />
        </Tooltip>

        <CommentFilter commentManagement={commentFunctions} />
        {form.readOnly?.actions.privilegeApprovals ? (
          <PrivilegeAction
            type="forms"
            id={documentId!}
            displayPrivilegeBanner={displayPrivilegeBanner}
            privilegeWarningDismissed={(form.meta as any).privilegeWarningDismissed}
            setDisplayPrivilegeBanner={setDisplayPrivilegeBanner}
            setDisplayPrivilegeConfirmation={setDisplayPrivilegeConfirmation}
          />
        ) : (
          <> </>
        )}
      </HStack>
    );

    return () => {
      setPageActions(<HStack></HStack>);
    };
  }, [
    loading,
    form.readOnly,
    documentId,
    displayPrivilegeBanner,
    commentFunctions.openCount,
    commentFunctions.resolvedCount,
    commentFunctions.participatingCount,
    commentFunctions.adjudicatorCount,
    commentFunctions.submitterCount,
    commentFunctions.assignedCount,
    commentFunctions.primaryCommentFilter,
    commentFunctions.secondaryCommentFilter,
    commentFunctions.filteredThreadsDictionary,
  ]);

  const onElementValueChange = useCallback(
    (element: FormElement, debounceNeeded: string[]) => (e: any, field: string) => {
      setSaving(SaveStates.unsavedChanges);
      debouncedUpdateFormFields(element, e, field, formFunctions);
      if (!(field in debounceNeeded)) {
        debouncedUpdateFormFields.flush();
      }
      debouncedUpdate(setSaving, documentId, form, updateFormElements);
    },
    [documentId, form]
  );

  const flushDebouncedFunctions = () => {
    // force the debounced functions to execute immediately
    debouncedUpdateFormFields.flush();
    debouncedUpdateMeta.flush();
    debouncedUpdateTitle.flush();
    debouncedUpdate.flush();
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", flushDebouncedFunctions);

    return () => window.removeEventListener("visibilitychange", flushDebouncedFunctions);
  }, []);

  useEffect(() => {
    return () => flushDebouncedFunctions();
  }, []);

  const save = () => {
    setSaving(SaveStates.unsavedChanges);
    debouncedUpdate(setSaving, documentId, form, updateFormElements);
    debouncedUpdate.flush();
  };

  const renderElements = (elements: FormElement[], level: number = 0): JSX.Element[] => {
    return elements?.map((element, i) => {
      const ElementComponent = ElementMap[element.type as keyof typeof ElementMap].build;
      const debounceNeeded = ElementMap[element.type as keyof typeof ElementMap].editViewDebouncedChanges;
      // Construct children elements recursively if they exist
      const children = element.children ? renderElements(element.children, level + 1) : undefined;

      const indentationLevel = formFunctions.getIndentationLevel(element.id!);
      return (
        <DocumentSectionWrapper
          formFunctions={formFunctions}
          commentFunctions={commentFunctions}
          elementId={element.id}
          documentId={documentId}
          key={`${level}-${i}-wrapper`}
          userRoleOnDocument={form.users.find((user) => user.userId === userId)?.role ?? "submitter"}
          updateConversations={(x: CommentThread[]) => {
            formFunctions.updateCommentThreads(element.id!, x);
            setSaving(SaveStates.unsavedChanges);
            debouncedUpdate(setSaving, documentId, form, updateFormElements);
            debouncedUpdate.flush();
          }}
          isSection={element.type === "section"}
          save={save}
        >
          <ElementComponent
            titleNumber={element.titleNumber ?? ""}
            id={element.id!}
            key={`${level}-${i}`}
            value={element.content as any}
            indentationLevel={indentationLevel}
            onChange={onElementValueChange(element, debounceNeeded)}
          >
            {children}
          </ElementComponent>
        </DocumentSectionWrapper>
      );
    });
  };

  useEffect(() => {
    const formElements: any = renderElements(form.elements as FormElement[]);
    setElements(formElements);
  }, [form]);

  return (
    <>
      {loading ? (
        <Center>
          <HStack>
            <Text variant="bold">Loading form...</Text>
            <Spinner />
          </HStack>
        </Center>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: HEADER_HEIGHT,
            backgroundColor: Colors.gray[50],
            height: "100%",
          }}
        >
          {id && form.meta ? (
            <CreateSubmissionModal
              isOpen={submissionModalIsOpen}
              onClose={closeSubmissionModal}
              formId={id}
              formName={form.meta.title}
              becomeAdjudicator={form.readOnly.actions.becomeAdjudicator}
            />
          ) : null}
          <SharingModal document={form} type="form" isOpen={sharingModalIsOpen} onOpen={openSharingModal} onClose={closeSharingModal} />
          <FormDescriptionModal isOpen={descriptionModalIsOpen} onClose={closeDescriptionModal} formId={id} formDescription={form.meta?.description} />
          <PrivilegeConfirmationModal open={displayPrivilegeConfirmation} setOpen={(x) => setDisplayPrivilegeConfirmation(x)} />
          <RulesModal
            save={() => {
              setSaving(SaveStates.unsavedChanges);
              debouncedUpdate(setSaving, documentId, form, updateFormElements);
              debouncedUpdate.flush();
            }}
            form={form}
            open={rulesModalIsOpen}
            onClose={closeRulesModal}
          />
          <div style={{ minWidth: "68px", marginRight: "auto" }} />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50px 1fr",
            }}
          >
            <div style={{ backgroundColor: Colors.gray[50] }}></div>
            <div
              style={{
                width: "800px",
                paddingTop: PRIVILEGE_BANNER_HEIGHT,
                position: "relative",
                minHeight: "100vh",
                backgroundColor: Colors.gray[50],
              }}
            >
              {displayPrivilegeBanner ? <PrivilegedBanner /> : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  backgroundColor: Colors.white[100],
                  border: `1px solid ${Colors.gray[600]}`,
                  padding: 40,
                  paddingTop: SUBMISSION_STATUS_HEIGHT,
                  minHeight: `calc(100vh - ${HEADER_HEIGHT} * 3)`,
                  borderRadius: "3px",
                }}
              >
                <div>
                  {notFound ? (
                    <Center>
                      <Heading size="md">Document Not Found</Heading>
                    </Center>
                  ) : (
                    <>{elements}</>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: 50 }}></div>
          <div style={{ width: "400px", marginTop: HEADER_HEIGHT }}></div>
          <div style={{ minWidth: "4px", marginRight: "auto" }} />
        </div>
      )}
    </>
  );
};
