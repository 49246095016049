import {
  Button,
  Grid,
  GridItem,
  Text,
  useDisclosure,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { OrganizationMembership } from "../../types";
import { LuminosModalHeader } from "./LuminosModalHeader";

export const LeaveOrganizatoinModal = ({
  organizationMembership,
}: {
  organizationMembership: OrganizationMembership;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function leaveOrganization(organizationId: string) {
    /*
       TODO send a request to the backend indicating that the 
       userInfo.userID wants to leave the organization. 
    */
  }

  return (
    <>
      <Button height="28px" w="46px" fontSize="small" onClick={onOpen}>
        Leave
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth="600px">
          <LuminosModalHeader title={"Leave Organization"} closeButton={true} />
          <ModalBody bg="white">
            <Text>
              You are about to submit a request to Luminos.AI support leave the
              organization:
            </Text>
            <VStack spacing="8px" mt="10px" ml="0px" mb="14px">
              <Grid
                w="70%"
                templateRows="repeat(2, 1fr)"
                templateColumns="repeat(4, 1fr)"
                gap={4}
              >
                <GridItem rowSpan={1} colSpan={2}>
                  <Text textAlign="right">Organization Name:</Text>
                </GridItem>
                <GridItem rowSpan={1} colSpan={2}>
                  <Text>{organizationMembership.name}</Text>
                </GridItem>
                <GridItem rowSpan={1} colSpan={2}>
                  <Text textAlign="right">Nickname:</Text>
                </GridItem>
                <GridItem rowSpan={1} colSpan={2}>
                  <Text>{organizationMembership.name}</Text>
                </GridItem>
              </Grid>
            </VStack>
            <Text>
              Are you sure you want to submit a request to leave this
              organization?
            </Text>
          </ModalBody>

          <ModalFooter bg="white">
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                leaveOrganization(organizationMembership.organizationId!);
                onClose();
              }}
            >
              Leave Organization
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
