import { Icon } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Colors } from "../../theme/colors";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";
import { PrivilegeConfigModal } from "../Modal/PrivilegeConfigModal";

const PrivilegeEnabledIcon = VerifiedUserOutlinedIcon;
const PrivilegeWarningIcon = GppMaybeOutlinedIcon;
const PrivilegeDisabledIcon = GppBadOutlinedIcon;

export function PrivilegeAction({
  type,
  id,
  displayPrivilegeBanner,
  setDisplayPrivilegeBanner,
  privilegeWarningDismissed,
  setDisplayPrivilegeConfirmation,
}: {
  type: "forms" | "submissions";
  id: string;
  displayPrivilegeBanner: boolean;
  setDisplayPrivilegeBanner: (x: boolean) => void;
  privilegeWarningDismissed: boolean;
  setDisplayPrivilegeConfirmation: (x: boolean) => void;
}) {
  const [currentDocumentIsPrivileged, setCurrentDocumentIsPrivileged] = useState(displayPrivilegeBanner);
  const [currentDocumentPrivilegeWarningsIgnored, setCurrentDocumentIgnorePrivilegeWarnings] = useState(privilegeWarningDismissed);
  const [displayPrivilegeConfig, setDisplayPrivilegeConfig] = useState(false);
  let currentIcon = PrivilegeDisabledIcon;

  let iconColor = Colors.white[500];
  if (currentDocumentIsPrivileged) {
    currentIcon = PrivilegeEnabledIcon;
  } else if (currentDocumentPrivilegeWarningsIgnored) {
    currentIcon = PrivilegeDisabledIcon;
  } else {
    currentIcon = PrivilegeWarningIcon;
    iconColor = Colors.red[500];
  }

  useEffect(() => {
    setCurrentDocumentIsPrivileged(displayPrivilegeBanner);
    setCurrentDocumentIgnorePrivilegeWarnings(privilegeWarningDismissed);
  }, [displayPrivilegeBanner, privilegeWarningDismissed]);

  return (
    <React.Fragment>
      <Icon as={currentIcon} color={iconColor} onClick={() => setDisplayPrivilegeConfig(true)} />
      <PrivilegeConfigModal
        type={type}
        id={id}
        setCurrentDocumentIsPrivileged={setCurrentDocumentIsPrivileged}
        setDisplayPrivilegeBanner={setDisplayPrivilegeBanner}
        isOpen={displayPrivilegeConfig}
        onClose={() => setDisplayPrivilegeConfig(false)}
        setDisplayPrivilegeConfirmation={setDisplayPrivilegeConfirmation}
        setCurrentDocumentIgnorePrivilegeWarnings={setCurrentDocumentIgnorePrivilegeWarnings}
        currentDocumentPrivilegeWarningsIgnored={currentDocumentPrivilegeWarningsIgnored}
        currentDocumentIsPrivileged={currentDocumentIsPrivileged}
      />
    </React.Fragment>
  );
}
