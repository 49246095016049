import { convertUserToMentionableUser } from "../util/convertUserToMentionableUser";
import { useAPIRequest } from "../hooks/useAPI";
import { setMentionableOrganizationMembers } from "../actions/orgActions";
import store from "../store";

export const useOrganizationAPI = () => {
  const sendAPIRequest = useAPIRequest();
  const getOrganizationMembers = async () => {
    const res: any = await sendAPIRequest(`organization/membershipsDetail`);
    return res;
  };

  const inviteNewUser = async (userInfo: any) => {
    return await sendAPIRequest(`organization/invite-user`, "PUT", { userInfo });
  };

  const updateOrganizationPrivilageSettings = async (settings: any) => {
    const res: any = await sendAPIRequest(`organization/privilegeSettings`, "PATCH", settings);
    return res;
  };

  const updateOrganization = async (organization: any) => {
    const res: any = await sendAPIRequest(`organization`, "PATCH", organization);
    return res;
  };

  const updateOrganizationMembership = async (userInfo: any) => {
    const res: any = await sendAPIRequest(`organization/update-membership`, "PUT", userInfo);
    return res;
  };

  const refreshOrganizationMembers = async () => {
    const newMembers = await getOrganizationMembers();
    const mentionableUsers = newMembers.filter((member: any) => member.user).map(convertUserToMentionableUser);

    store.dispatch(setMentionableOrganizationMembers(mentionableUsers));
  };

  return {
    getOrganizationMembers,
    inviteNewUser,
    updateOrganizationPrivilageSettings,
    updateOrganization,
    updateOrganizationMembership,
    refreshOrganizationMembers,
  };
};
