import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Collapse,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  HStack,
  Icon,
  MenuList,
  Text,
  Menu,
  MenuItem,
  MenuButton,
  Spacer,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import BalanceOutlinedIcon from "@mui/icons-material/BalanceOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { ICommentFunctions } from "../hooks/useCommentManager";
import { useSelector } from "react-redux";
import { Colors } from "../theme/colors";

export const SUBMISSION_STATUS_HEIGHT = "45px";

export const SubmissionStatusDisplay = ({ commentManagement }: { commentManagement: ICommentFunctions }) => {
  const [commentButtonActive, setCommentButtonActive] = useState(false);
  const [adjudicatorButtonActive, setAdjudicatorButtonActive] = useState(false);
  const [submitterButtonActive, setSubmitterButtonActive] = useState(false);
  const [documentProgress, setDocumentProgress] = useState("In Progress");
  const userRole: string = useSelector((state: any) => state.auth.userRole);

  const [openCount, setOpenCount] = useState(0);
  const [resolvedCount, setResolvedCount] = useState(0);
  const [participatingOpenCount, setParticipatingOpenCount] = useState(0);
  const [participatingResolvedCount, setParticipatingResolvedCount] = useState(0);
  const [assignedOpenCount, setAssignedOpenCount] = useState(0);
  const [assignedResolvedCount, setAssignedResolvedCount] = useState(0);
  const [submitterOpenCount, setSubmitterOpenCount] = useState(0);
  const [submitterResolvedCount, setSubmitterResolvedCount] = useState(0);
  const [adjudicatorOpenCount, setAdjudicatorOpenCount] = useState(0);
  const [adjudicatorResolvedCount, setAdjudicatorResolvedCount] = useState(0);

  useEffect(() => {
    setOpenCount(commentManagement.openCount());
    setResolvedCount(commentManagement.resolvedCount());
    setParticipatingOpenCount(commentManagement.participatingCount({ resolved: false }));
    setParticipatingResolvedCount(commentManagement.participatingCount({ resolved: true }));
    setAssignedOpenCount(commentManagement.assignedCount({ resolved: false }));
    setAssignedResolvedCount(commentManagement.assignedCount({ resolved: true }));
    setSubmitterOpenCount(commentManagement.submitterCount({ resolved: false }));
    setSubmitterResolvedCount(commentManagement.submitterCount({ resolved: true }));
    setAdjudicatorOpenCount(commentManagement.adjudicatorCount({ resolved: false }));
    setAdjudicatorResolvedCount(commentManagement.adjudicatorCount({ resolved: true }));
  }, [commentManagement.filteredThreadsDictionary]);

  function submitterButtonClicked() {
    setAdjudicatorButtonActive(false);
    setSubmitterButtonActive(!submitterButtonActive);
    setCommentButtonActive(false);
  }

  function adjudicatorButtonClicked() {
    setAdjudicatorButtonActive(!adjudicatorButtonActive);
    setSubmitterButtonActive(false);
    setCommentButtonActive(false);
  }

  function commentButtonClicked() {
    setAdjudicatorButtonActive(false);
    setSubmitterButtonActive(false);
    setCommentButtonActive(!commentButtonActive);
  }

  function getAllConversationsCount() {
    switch (commentManagement.secondaryCommentFilter) {
      case "open":
        return openCount;
      case "resolved":
        return resolvedCount;
      case "open or resolved":
        return openCount + resolvedCount;
    }
  }

  function getAssignedConversationsCount() {
    switch (commentManagement.secondaryCommentFilter) {
      case "open":
        return assignedOpenCount;
      case "resolved":
        return assignedResolvedCount;
      case "open or resolved":
        return assignedOpenCount + assignedResolvedCount;
    }
  }

  function getForConversationsCount() {
    switch (commentManagement.secondaryCommentFilter) {
      case "open":
        return participatingOpenCount;
      case "resolved":
        return participatingResolvedCount;
      case "open or resolved":
        return participatingOpenCount + participatingResolvedCount;
    }
  }

  function getOpenConversationsCount() {
    switch (commentManagement.primaryCommentFilter) {
      case "all conversations":
        return openCount;
      case "conversations assigned to you":
        return assignedOpenCount;
      case "your conversations":
        return participatingOpenCount;
    }
  }

  function getResolvedConversationsCount() {
    switch (commentManagement.primaryCommentFilter) {
      case "all conversations":
        return resolvedCount;
      case "conversations assigned to you":
        return assignedResolvedCount;
      case "your conversations":
        return participatingResolvedCount;
    }
  }

  function getOpenOrResolvedConversationsCount() {
    switch (commentManagement.primaryCommentFilter) {
      case "all conversations":
        return openCount + resolvedCount;
      case "conversations assigned to you":
        return assignedOpenCount + assignedResolvedCount;
      case "your conversations":
        return participatingOpenCount + participatingResolvedCount;
    }
  }

  /* Note that it doesn't really make sense to filter the progress by the
     open / resolved /  both filter as progress is the ratio of open to
     both */
  function getCommentProgress() {
    switch (commentManagement.primaryCommentFilter) {
      case "all conversations":
        return (1 - openCount / (openCount + resolvedCount)) * 100;
      case "conversations assigned to you":
        return (1 - assignedOpenCount / (assignedOpenCount + assignedResolvedCount)) * 100;
      case "your conversations":
        return (1 - participatingOpenCount / (participatingOpenCount + participatingResolvedCount)) * 100;
    }
  }

  function renderExpandedBox() {
    if (submitterButtonActive) {
      return (
        <>
          {submitterOpenCount == 0 ? (
            <>
              {userRole.localeCompare("adjudicator") === 0 ? (
                <Text m="24px">
                  All caught up. Submitters have responded to all required questions, though more may be asked of them depending on reviewer responses.
                </Text>
              ) : (
                <Text m="24px">
                  All caught up. There are no required questions for you or other submitters to complete at this time. We&#39;ll notify you if that changes.
                </Text>
              )}
            </>
          ) : (
            <Flex direction="column" m="24px">
              <>
                {userRole.localeCompare("adjudicator") === 0 ? (
                  <Text>Submitters have {submitterOpenCount}* outstanding required questions to complete.</Text>
                ) : (
                  <Text>You or other submitters have {submitterOpenCount}* outstanding required questions to complete.</Text>
                )}
              </>
              <Spacer />
              <Text fontSize="xs">*this number can change depending on responses provided to questions</Text>
            </Flex>
          )}
        </>
      );
    } else if (adjudicatorButtonActive) {
      return (
        <>
          {adjudicatorOpenCount === 0 ? (
            <>
              <Text m="24px">
                All caught up. There are no required questions for you or other reviewers to complete at this time. We&#39;ll notify you if that changes.
              </Text>
            </>
          ) : (
            <Flex direction="column" m="24px">
              <Text>You or other reviewers have {adjudicatorOpenCount}* outstanding required questions to complete.</Text>
              <Spacer />
              <Text fontSize="xs">*this number can change depending on responses provided to questions</Text>
            </Flex>
          )}
        </>
      );
    } else if (commentButtonActive) {
      return (
        <>
          <VStack m="24px" align="left">
            <HStack>
              <Wrap>
                <WrapItem>
                  <Center h="100%" fontSize="sm">
                    Show
                  </Center>
                </WrapItem>
                <WrapItem>
                  <Menu>
                    <MenuButton border="1px" borderRadius="3px" p="4px" borderColor="gray.500" fontSize="sm">
                      {commentManagement.primaryCommentFilter}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => commentManagement.setPrimaryCommentFilter("all conversations")} fontSize="sm">
                        all conversations ({getAllConversationsCount()})
                      </MenuItem>
                      <MenuItem onClick={() => commentManagement.setPrimaryCommentFilter("conversations assigned to you")} fontSize="sm">
                        conversations assigned to you ({getAssignedConversationsCount()})
                      </MenuItem>
                      <MenuItem onClick={() => commentManagement.setPrimaryCommentFilter("your conversations")} fontSize="sm">
                        your conversations ({getForConversationsCount()})
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </WrapItem>
                <WrapItem>
                  <Center h="100%" fontSize="sm">
                    that are
                  </Center>
                </WrapItem>
                <WrapItem>
                  <Menu>
                    <MenuButton border="1px" borderRadius="3px" p="4px" borderColor="gray.500" fontSize="sm">
                      {commentManagement.secondaryCommentFilter}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => commentManagement.setSecondaryCommentFilter("open")} fontSize="sm">
                        open ({getOpenConversationsCount()})
                      </MenuItem>
                      <MenuItem onClick={() => commentManagement.setSecondaryCommentFilter("resolved")} fontSize="sm">
                        resolved ({getResolvedConversationsCount()})
                      </MenuItem>
                      <MenuItem onClick={() => commentManagement.setSecondaryCommentFilter("open or resolved")} fontSize="sm">
                        open or resolved ({getOpenOrResolvedConversationsCount()})
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Center h="100%" fontSize="sm">
                    .
                  </Center>
                </WrapItem>
              </Wrap>
            </HStack>
          </VStack>
        </>
      );
    } else {
      return <></>;
    }
  }

  const circularProgressSize = "26px";
  const iconWH = "15px";
  const iconWHsm = "12px";
  return (
    <div
      style={{
        height: SUBMISSION_STATUS_HEIGHT,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <VStack border={`1px solid ${Colors.gray[600]}`} w="100%" spacing="0px" pt="4px" pb="4px" borderRadius="3px" bg="white">
        <HStack w="100%">
          <Flex w="100%">
            <Spacer />
            {documentProgress === "In Progress" ? (
              <>
                <Button variant="circularProgress" isActive={submitterButtonActive} onClick={submitterButtonClicked}>
                  <CircularProgress
                    value={(submitterResolvedCount / (submitterOpenCount + submitterResolvedCount)) * 100}
                    size={circularProgressSize}
                    color="brightblue.500"
                  >
                    <CircularProgressLabel>
                      <Icon as={ChecklistOutlinedIcon} boxSize={4} />
                    </CircularProgressLabel>
                  </CircularProgress>
                </Button>
                <Spacer />
                {userRole.localeCompare("adjudicator") === 0 ? (
                  <Button variant="circularProgress" isActive={adjudicatorButtonActive} onClick={adjudicatorButtonClicked}>
                    <CircularProgress
                      value={(adjudicatorResolvedCount / (adjudicatorOpenCount + adjudicatorResolvedCount)) * 100}
                      size={circularProgressSize}
                      color="brightblue.500"
                    >
                      <CircularProgressLabel>
                        <Icon as={BalanceOutlinedIcon} boxSize={4} />
                      </CircularProgressLabel>
                    </CircularProgress>
                  </Button>
                ) : (
                  <div></div>
                )}
              </>
            ) : (
              <Center>Status: {documentProgress}</Center>
            )}
            <Spacer />
            <Button variant="circularProgress" isActive={commentButtonActive} onClick={commentButtonClicked}>
              <CircularProgress value={getCommentProgress()} size={circularProgressSize} color="brightblue.500">
                <CircularProgressLabel>
                  <div
                    style={{
                      position: "relative",
                      width: iconWH,
                      height: iconWH,
                      alignContent: "center",
                    }}
                  >
                    <Icon w={iconWH} h={iconWH} ml="6px" mt="1px" as={ChatBubbleOutlineOutlinedIcon} />
                    {commentManagement.primaryCommentFilter !== "all conversations" || commentManagement.secondaryCommentFilter !== "open" ? (
                      <div style={{ zIndex: 2, position: "absolute" }}>
                        <Box w={iconWH} h={iconWH}>
                          <Icon w={iconWHsm} h={iconWHsm} ml="35px" mt="-42px" color="red" as={FilterListOutlinedIcon} />
                        </Box>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </CircularProgressLabel>
              </CircularProgress>
            </Button>
            <Spacer />
          </Flex>
        </HStack>
        <Box w="100%">
          <Collapse in={commentButtonActive || submitterButtonActive || adjudicatorButtonActive}>
            <Flex w="100%" minH="150px" bg="white">
              {renderExpandedBox()}
            </Flex>
          </Collapse>
        </Box>
      </VStack>
    </div>
  );
};
