import { useState, useEffect, useCallback } from "react";
import { CommentThread, CommentThreadDictionary } from "../types";
import { useSelector } from "react-redux";
import _ from "lodash";
import { ROLE_2, ROLE_1 } from "../roles";

export interface ICommentFunctions {
  openCount: () => number;
  resolvedCount: () => number;
  participatingCount: (x: { resolved: boolean }) => number;
  adjudicatorCount: (x: { resolved: boolean }) => number;
  submitterCount: (x: { resolved: boolean }) => number;
  assignedCount: (x: { resolved: boolean }) => number;
  secondaryCommentFilter: string;
  setSecondaryCommentFilter: (value: string) => void;
  primaryCommentFilter: "all conversations" | "conversations assigned to you" | "your conversations";
  setPrimaryCommentFilter: (value: "all conversations" | "conversations assigned to you" | "your conversations") => void;
  filteredThreadsDictionary: CommentThreadDictionary;
  commentThreadsDictionary: CommentThreadDictionary;
}

function useCommentManager() {
  const [commentThreadsDictionary, setCommentThreadsDictionary] = useState<any>();
  const [secondaryCommentFilter, setSecondaryCommentFilter] = useState("open");
  const [role, setRole] = useState("");
  const [primaryCommentFilter, setPrimaryCommentFilter] = useState<"all conversations" | "conversations assigned to you" | "your conversations">(
    "all conversations"
  );
  const globalUserInfo = useSelector((state: any) => state.auth.globalUserInfo);
  const email = globalUserInfo?.user?.email;
  const userId = globalUserInfo?.user?._id;
  const name = globalUserInfo?.user?.firstName + " " + globalUserInfo?.user?.lastName;
  const [filteredThreadsDictionary, setFilteredThreadsDictionary] = useState<any>();

  useEffect(() => {
    if (commentThreadsDictionary) {
      let filteredDictionary: any = {};

      for (const [key, value] of Object.entries(commentThreadsDictionary)) {
        if (secondaryCommentFilter === "open") {
          const filteredThread = (value as CommentThread[]).filter((commentThread) => !commentThread.resolved);
          filteredDictionary[key] = filteredThread;
        } else if (secondaryCommentFilter === "resolved") {
          const filteredThread = (value as CommentThread[]).filter((commentThread) => commentThread.resolved);
          filteredDictionary[key] = filteredThread;
        } else {
          filteredDictionary[key] = value;
        }
      }

      // Filter by primary filter
      if (primaryCommentFilter === "conversations assigned to you") {
        const filteredEntries = Object.keys(filteredDictionary).reduce((acc: CommentThreadDictionary, key: any) => {
          const threads = filteredDictionary[key];
          const assignedThreads = threads.filter((thread: CommentThread) => {
            return thread.comments.some((comment: any) => comment.assignedTo?.includes(email) || comment.assignedTo?.includes(name));
          });

          if (assignedThreads.length > 0) {
            acc[key] = assignedThreads;
          }

          return acc;
        }, {} as CommentThreadDictionary);

        filteredDictionary = filteredEntries;
      } else if (primaryCommentFilter === "your conversations") {
        const filteredEntries = Object.keys(filteredDictionary).reduce((acc: CommentThreadDictionary, key: any) => {
          const threads = filteredDictionary[key];
          const userThreads = threads.filter((thread: CommentThread) => {
            return thread.comments.some((comment: any) => comment.user === userId || comment.assignedTo?.includes(email) || comment.mentioned?.includes(email));
          });

          if (userThreads.length > 0) {
            acc[key] = userThreads;
          }

          return acc;
        }, {} as CommentThreadDictionary);

        filteredDictionary = filteredEntries;
      } else {
        setFilteredThreadsDictionary(filteredDictionary);
      }

      // filter out adjudicator threads if role is submitter
      if (role === ROLE_1) {
        filteredDictionary = _.mapValues(filteredDictionary, (threads: CommentThread[]) => {
          return threads.filter((thread) => thread.type !== ROLE_2);
        });
      }
      setFilteredThreadsDictionary(filteredDictionary);
    } else {
      setFilteredThreadsDictionary({});
    }
  }, [primaryCommentFilter, secondaryCommentFilter, commentThreadsDictionary, globalUserInfo, email, userId, name, role]);

  const getElementCommentThreads = useCallback(
    (elementId: string) => {
      return commentThreadsDictionary[elementId] || [];
    },
    [commentThreadsDictionary]
  );

  const openCount = useCallback(() => {
    if (!commentThreadsDictionary) return 0;
    let count = 0;

    Object.keys(commentThreadsDictionary).forEach((elementId) => {
      const threads = commentThreadsDictionary[elementId];
      const openThreads = threads.filter((thread: CommentThread) => !thread.resolved);

      count += openThreads.length;
    });

    return count;
  }, [commentThreadsDictionary]);

  const resolvedCount = useCallback(() => {
    if (!commentThreadsDictionary) return 0;
    let count = 0;

    Object.keys(commentThreadsDictionary).forEach((elementId) => {
      const threads = commentThreadsDictionary[elementId];
      const resolvedThreads = threads.filter((thread: CommentThread) => thread.resolved);

      count += resolvedThreads.length;
    });

    return count;
  }, [commentThreadsDictionary]);

  const assignedCount = useCallback(
    ({ resolved }: { resolved: boolean }) => {
      if (!commentThreadsDictionary) return 0;
      let count = 0;

      Object.keys(commentThreadsDictionary).forEach((elementId) => {
        const threads = commentThreadsDictionary[elementId];
        const assignedThreads = threads.filter(
          (thread: CommentThread) =>
            thread.comments.find((comment: any) => comment.assignedTo?.includes(email) || comment.assignedTo?.includes(name)) && thread.resolved === resolved
        );

        count += assignedThreads.length;
      });

      return count;
    },
    [commentThreadsDictionary]
  );

  const participatingCount = useCallback(
    ({ resolved }: { resolved: boolean }) => {
      if (!commentThreadsDictionary) return 0;

      let count = 0;

      Object.keys(commentThreadsDictionary).forEach((elementId) => {
        const threads = commentThreadsDictionary[elementId];
        const participatingThreads = threads.filter((thread: CommentThread) => {
          return thread.comments.find(
            (comment: any) =>
              (comment.user === userId || comment.assignedTo?.includes(email) || comment.mentioned?.includes(email)) && thread.resolved === resolved
          );
        });

        count += participatingThreads.length;
      });

      return count;
    },
    [commentThreadsDictionary]
  );

  const adjudicatorCount = useCallback(
    ({ resolved }: { resolved: boolean }) => {
      if (!commentThreadsDictionary) return 0;

      let count = 0;

      Object.keys(commentThreadsDictionary).forEach((elementId) => {
        const threads = commentThreadsDictionary[elementId];
        const adjudicatorThreads = threads.filter((thread: CommentThread) => thread.type === ROLE_2 && thread.resolved === resolved);
        count = adjudicatorThreads.length;
      });

      return count;
    },
    [commentThreadsDictionary]
  );

  const submitterCount = useCallback(
    ({ resolved }: { resolved: boolean }) => {
      if (!commentThreadsDictionary) return 0;

      let count = 0;

      Object.keys(commentThreadsDictionary).forEach((elementId) => {
        const threads = commentThreadsDictionary[elementId];
        const submitterThreads = threads.filter((thread: CommentThread) => thread.type === ROLE_1 && thread.resolved === resolved);

        count = submitterThreads.length;
      });

      return count;
    },
    [commentThreadsDictionary]
  );

  return {
    getElementCommentThreads,
    setCommentThreadsDictionary,
    commentThreadsDictionary,
    setRole,
    role,
    commentFunctions: {
      openCount,
      resolvedCount,
      participatingCount,
      adjudicatorCount,
      submitterCount,
      assignedCount,
      primaryCommentFilter,
      setPrimaryCommentFilter,
      secondaryCommentFilter,
      setSecondaryCommentFilter,
      filteredThreadsDictionary,
      commentThreadsDictionary,
    },
  };
}

export default useCommentManager;
