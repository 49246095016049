import React, { useState, useMemo, useEffect } from "react";
import RichText from "./ElementComponents/RichText";
import { RequiredToggle } from "./ElementComponents/RequiredToggle";
import { Text, Tooltip, Icon, HStack, Switch, Flex, VStack, Input, InputGroup, Checkbox } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../../components/Spacer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { AdvancedSectionWrapper } from "./ElementComponents/AdvancedSectionWrapper";
import { AdvancedNotes } from "./ElementComponents/AdvancedNotes";
import { FormElementTypes, SelectItem } from "../../types";
import { useSelectionContext } from "../../context/SelectionContext";
import { ROLE_1, ROLE_2 } from "../../roles";
import { BuilderHeader, ResponseHeader } from "./ElementComponents/QuestionHeaders";

interface BuildViewProps {
  value: FormElementTypes.MultiSelect;
  children?: JSX.Element[];
  titleNumber: string;
  id: string;
  indentationLevel: number;
  onChange: (e: any, field: string) => void;
}

const Default = {
  type: "multiSelect",
  content: {
    audience: ROLE_1,
    title: "",
    surveyTakerNotes: {},
    adjudicatorNotes: {},
    question: "",
    required: true,
    items: [{ label: "Option 1", selected: false }],
    other: {
      allowed: false,
      label: "Other",
      selected: false,
    },
    none: {
      allowed: false,
      label: "None",
      selected: false,
    },
    all: {
      allowed: false,
      label: "All",
      selected: false,
    },
  },
};

const BuildView: React.FC<BuildViewProps> = ({ value, titleNumber, id, indentationLevel, onChange }) => {
  const { selectedElement } = useSelectionContext();
  const [title, setTitle] = useState<string>(value.title);
  const [items, setItems] = useState<FormElementTypes.SingleSelect["items"]>(value.items);
  const [otherAllowed, setOtherAllowed] = useState<boolean>(value.other.allowed);
  const [allAllowed, setAllAllowed] = useState<boolean>(value.all.allowed);
  const [noneAllowed, setNoneAllowed] = useState<boolean>(value.none.allowed);
  const [required, setRequired] = useState<boolean>(value.required);
  const [audience, setAudience] = useState<string>(value.audience);

  const updateLabel = (index: number, newValue: string) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, label: newValue };
      }
      return item;
    });
    setItems(newItems);
    onChange(newItems, "items");
  };

  const deleteItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
    onChange(newItems, "items");
  };

  const addItem = () => {
    const newItems = [...items, { label: `Option ${items.length + 1}`, selected: false, allowed: true }];
    setItems(newItems);
    onChange(newItems, "items");
  };

  const updateOtherAllowed = () => {
    onChange({ ...value.other, allowed: !otherAllowed }, "other");
    setOtherAllowed(!otherAllowed);
  };

  const updateNoneAllowed = () => {
    onChange({ ...value.none, allowed: !noneAllowed }, "none");
    setNoneAllowed(!noneAllowed);
  };

  const updateAllAllowed = () => {
    onChange({ ...value.all, allowed: !allAllowed }, "all");
    setAllAllowed(!allAllowed);
  };

  const updateRequired = (e: any) => {
    setRequired(e.target.checked);
    onChange(e.target.checked, "required");
  };

  const isSelectedElement = selectedElement === id;
  const singleSelectComponents = useMemo(
    () => (
      <div>
        <Flex w={"100%"} alignItems="center" direction="column">
          <div style={{ width: "100%" }}>
            <BuilderHeader
              questionPrompt="Question"
              title={value.title}
              titlePlaceholder="Untitled Multiple Selection Question"
              titleNumber={titleNumber}
              indentationLevel={indentationLevel}
              audience={value.audience}
              required={value.required}
              onChange={onChange}
            />
            <RichText placeholder="Enter the question" selected={isSelectedElement} value={value.question} onChange={(e) => onChange(e, "question")} />
            <RequiredToggle value={required} onChange={updateRequired} />
          </div>
          <Spacer y={SpacerSizes.sm} />

          <HStack w={"100%"} alignItems={"flex-start"} justifyContent={"space-between"}>
            <VStack alignItems={"flex-start"} w={"49%"}>
              <Text>Response Options</Text>
              <Text style={{ marginLeft: 27 }}>Label</Text>
              {items.map((item, i) => (
                <HStack w={"100%"} key={i} spacing={0}>
                  <div style={{ width: 25 }}>
                    <Text>{i + 1}.</Text>
                  </div>

                  <Input onChange={(e) => updateLabel(i, e.target.value)} borderColor={"gray.800"} value={item.label} />
                  <Icon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      deleteItem(i);
                    }}
                    as={DeleteOutlineIcon}
                  />
                </HStack>
              ))}
              <Icon style={{ cursor: "pointer" }} onClick={addItem} boxSize={5} as={MdOutlineAddCircleOutline} />
            </VStack>

            <VStack alignItems={"flex-start"} w={"49%"}>
              <Text>Special Responses</Text>
              <Spacer y={SpacerSizes.md} />
              <HStack w={"100%"} contentEditable={false} alignContent="center" h={"35px"}>
                <Switch contentEditable={false} size="sm" isChecked={otherAllowed} colorScheme={"brightblue"} onChange={updateOtherAllowed} />
                <Text>{value.other.label}</Text>
                <Tooltip label={"When enabled, the end user may provide up to one additional option selection that they specify."} fontSize="xs">
                  <Icon boxSize={"4"} as={HelpOutlineIcon} />
                </Tooltip>
              </HStack>

              <HStack w={"100%"} contentEditable={false} alignContent="center" h={"35px"}>
                <Switch contentEditable={false} size="sm" isChecked={noneAllowed} colorScheme={"brightblue"} onChange={updateNoneAllowed} />
                <Text>{value.none.label}</Text>
                <Tooltip label={"When enabled, the end user may select 'None of the above' as an option."} fontSize="xs">
                  <Icon boxSize={"4"} as={HelpOutlineIcon} />
                </Tooltip>
              </HStack>

              <HStack w={"100%"} contentEditable={false} alignContent="center" h={"35px"}>
                <Switch contentEditable={false} size="sm" isChecked={allAllowed} colorScheme={"brightblue"} onChange={updateAllAllowed} />
                <Text>{value.all.label}</Text>
                <Tooltip label={"When enabled, the end user may select all options."} fontSize="xs">
                  <Icon boxSize={"4"} as={HelpOutlineIcon} />
                </Tooltip>
              </HStack>
            </VStack>
          </HStack>
        </Flex>
        <AdvancedSectionWrapper>
          <div style={{ width: "100%" }}>
            <AdvancedNotes onChange={onChange} surveyTakerNotes={value.surveyTakerNotes} adjudicatorNotes={value.adjudicatorNotes} />
          </div>
        </AdvancedSectionWrapper>
      </div>
    ),
    [id, indentationLevel, isSelectedElement, titleNumber, value, title, items, otherAllowed, allAllowed, noneAllowed, required, audience]
  );

  return isSelectedElement ? (
    singleSelectComponents
  ) : (
    <EditView role={ROLE_2} value={value} titleNumber={titleNumber} id={id} indentationLevel={indentationLevel} onChange={onChange} buildView={true} />
  );
};

const BuildViewDebouncedChanges: string[] = ["title", "question", "surveyTakerNotes", "adjudicatorNotes"];

interface EditViewProps {
  value: FormElementTypes.MultiSelect;
  children?: JSX.Element[];
  titleNumber: string;
  id: string;
  indentationLevel: number;
  onChange: (e: any, field: string) => void;
  buildView?: boolean;
}

const EditView: React.FC<EditViewProps & { role: string }> = ({ value, titleNumber, id, indentationLevel, onChange, role, buildView = false }) => {
  const [items, setItems] = useState<SelectItem[]>();
  const [none, setNone] = useState<SelectItem>();
  const [other, setOther] = useState<SelectItem>();
  const [all, setAll] = useState<SelectItem>();
  const roleMatchesAudience = value.audience === "all" || value.audience === role;

  useEffect(() => {
    setItems(value.items);
    setNone(value.none);
    setOther(value.other);
    setAll(value.all);
  }, [value]);

  const updateCheckbox = (e: string) => {
    if (!roleMatchesAudience) return;
    if (e === none?.label) {
      const newNone: SelectItem = {
        ...none,
        selected: !none.selected,
      };
      setNone(newNone);
      onChange(newNone, "none");

      if (newNone.selected) {
        const newOther: SelectItem = {
          ...other!,
          selected: false,
        };
        setOther(newOther);
        onChange(newOther, "other");

        const newAll: SelectItem = {
          ...all!,
          selected: false,
        };
        setAll(newAll);
        onChange(newAll, "all");

        const newItems = items?.map((item) => {
          return { ...item, selected: false };
        });
        setItems(newItems);
        onChange(newItems, "items");
      }
    } else if (e === other?.label) {
      const newOther = {
        ...other,
        selected: !other.selected,
      };
      setOther(newOther);
      onChange(newOther, "other");

      if (newOther.selected) {
        const newAll: SelectItem = {
          ...all!,
          selected: false,
        };
        setAll(newAll);
        onChange(newAll, "all");

        const newNone: SelectItem = {
          ...none!,
          selected: false,
        };
        setNone(newNone);
        onChange(newNone, "none");
      }
    } else if (e === all?.label) {
      const newAll = {
        ...all,
        selected: !all.selected,
      };
      setAll(newAll);
      onChange(newAll, "all");

      if (newAll.selected) {
        const newOther: SelectItem = {
          ...other!,
          selected: false,
        };
        setOther(newOther);
        onChange(newOther, "other");

        const newNone: SelectItem = {
          ...none!,
          selected: false,
        };
        setNone(newNone);
        onChange(newNone, "none");

        const newItems = items?.map((item) => {
          return { ...item, selected: true };
        });
        setItems(newItems);
        onChange(newItems, "items");
      }
    } else {
      const newItems = items?.map((item) => {
        if (item.label === e) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
      setItems(newItems);
      onChange(newItems, "items");
    }
  };

  const multiSelect = useMemo(
    () => (
      <Flex w={"100%"} alignItems="center" direction="column">
        <div style={{ width: "100%" }}>
          <ResponseHeader
            title={value.title}
            indentationLevel={indentationLevel}
            titleNumber={titleNumber}
            required={value.required}
            role={role}
            adjudicatorNotes={value.adjudicatorNotes}
            surveyTakerNotes={value.surveyTakerNotes}
            audience={value.audience}
            buildView={buildView}
            questionPlaceholder="Enter the question"
            question={value.question}
          />
        </div>
        <HStack justifyContent={"flex-start"} w={"85%"}>
          <VStack gap={1} alignItems={"flex-start"} justifyContent={"flex-start"}>
            {items?.map((item, i) => (
              <Checkbox
                onChange={() => updateCheckbox(item.label)}
                colorScheme="brightblue"
                key={i}
                isChecked={item.selected}
                value={item.label}
                disabled={all?.selected || none?.selected}
              >
                {item.label}
              </Checkbox>
            ))}
            {other && other.allowed ? (
              <Checkbox
                onChange={() => updateCheckbox(other.label)}
                colorScheme="brightblue"
                key={"other"}
                isChecked={other?.selected}
                value={other.label}
                disabled={all?.selected || none?.selected}
              >
                {other.label}
              </Checkbox>
            ) : null}
            {none && none.allowed ? (
              <Checkbox onChange={() => updateCheckbox(none.label)} colorScheme="brightblue" key={"none"} isChecked={none.selected} value={none.label}>
                {none.label}
              </Checkbox>
            ) : null}
            {all && all.allowed ? (
              <Checkbox onChange={() => updateCheckbox(all.label)} colorScheme="brightblue" key={"all"} isChecked={all.selected} value={all.label}>
                {all.label}
              </Checkbox>
            ) : null}
          </VStack>
        </HStack>
      </Flex>
    ),
    [value, titleNumber, id, indentationLevel, all, items, none, other]
  );
  return !roleMatchesAudience && role !== ROLE_2 ? null : multiSelect;
};

const EditViewDebouncedChanges: string[] = [];

export default { BuildView, EditView, Default, EditViewDebouncedChanges, BuildViewDebouncedChanges };
