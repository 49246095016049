import { Plate, useEditorState } from "@udecode/plate-common";
import { Editor } from "../plate-ui/editor";
import { FixedToolbar } from "../plate-ui/fixed-toolbar";
import { FixedToolbarButtons } from "../plate-ui/fixed-toolbar-buttons";
import { TooltipProvider } from "../plate-ui/tooltip";
import { plugins } from "./RichTextPlugins";
import { SimpleFixedToolbarButtons } from "../plate-ui/simple-fixed-toolbar-buttons";
import _ from "lodash";
import { MentionCombobox } from "../plate-ui/mention-combobox";
import { Collapse } from "@chakra-ui/react";
import { useMemo, useEffect } from "react";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";

export const richTextInitialValue = [
  {
    type: "p",
    children: [
      {
        text: "",
      },
    ],
  },
];
export default function RichText({
  simple = false,
  borderColor = "border-gray-300",
  value,
  readOnly = false,
  showToolbar = true,
  onChange,
  placeholder = "Type a paragraph",
  size = "md",
  className = "pt-2",
  selected = true,
  includeMentions = false,
  reset,
}: {
  simple?: boolean;
  borderColor?: string;
  value?: any;
  readOnly?: boolean;
  showToolbar?: boolean;
  onChange?: (e: any) => void;
  placeholder?: string;
  size?: "sm" | "full" | "md" | "fit";
  className?: string;
  selected?: boolean;
  includeMentions?: boolean;
  reset?: boolean;
}) {
  const mentionableMembers = useSelector((state: RootState) => state.org.mentionableMembers);
  const editor = useEditorState();

  useEffect(() => {
    if (reset && !editor.isFallback) {
      editor.reset();
    }
  }, [reset]);

  const plate = useMemo(() => {
    return (
      <Plate key={value} onChange={onChange} plugins={plugins(includeMentions)} initialValue={_.isEqual(value, {}) ? richTextInitialValue : value}>
        {showToolbar ? (
          <Collapse in={selected}>
            <FixedToolbar>{simple ? <SimpleFixedToolbarButtons /> : <FixedToolbarButtons />}</FixedToolbar>
          </Collapse>
        ) : null}
        <Editor placeholder={placeholder} className={className.concat(" overflow-x-hidden")} size={size ?? "fit"} readOnly={readOnly} />
        <MentionCombobox items={mentionableMembers} pluginKey="@" />
      </Plate>
    );
  }, [readOnly, selected, showToolbar, simple, value, mentionableMembers, placeholder, className, size, reset]);

  return (
    <TooltipProvider>
      <div className={`border-[2px] ${borderColor}`}>{plate}</div>
    </TooltipProvider>
  );
}
