import { useState, useEffect } from "react";
import _ from "lodash";
import { useTopNavContext } from "../context/TopNavContext";
import { PRIVILEGE_BANNER_HEIGHT, PrivilegedBanner } from "../components/PrivilegedBanner";
import { HEADER_HEIGHT } from "../components/TopNavBar/TopNavContainer";
import { Colors } from "../theme/colors";
import ElementMap from "./Elements/_index";
import useSubmissionManager from "../hooks/useSubmissionManager";
import { DocumentSectionWrapper } from "./Elements/ElementComponents/DocumentSectionWrapper";
import { SaveStates, SaveIndicator } from "../components/TopNavBar/SaveIndicator";
import { PageInfo } from "../components/TopNavBar/PageInfo";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { PrivilegeAction } from "../components/TopNavBar/PrivilegeAction";
import { Center, Heading, HStack, Icon, useDisclosure, Text, Spinner } from "@chakra-ui/react";
import { FormElement, CommentThread } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { SUBMISSION_STATUS_HEIGHT, SubmissionStatusDisplay } from "../components/SubmissionStatusDisplay";
import { PrivilegeConfirmationModal } from "../components/Modal/PrivilegeConfirmationModal";
import { SharingModal } from "../components/Modal/SharingModal";
import { useSubmissionAPI } from "../endpoints/_index";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import { useParams } from "react-router-dom";
import { AutoWidthInput } from "../components/AutoWidthInput";

const debouncedUpdateTitle = _.debounce(
  async (setSaving, documentId, title, updateSubmissionTitle) => {
    setSaving(SaveStates.inProgress);
    await updateSubmissionTitle(documentId, title);
    setSaving(SaveStates.saved);
  },

  5000
);

const debouncedUpdateFormFields = _.debounce(
  async (element: FormElement, newValue: any, field: string, submissionFunctions: any, setSaving: (x: SaveStates) => void) => {
    setSaving(SaveStates.inProgress);
    const updatedElement = { ...element, content: { ...element.content, [field]: newValue } };
    submissionFunctions.updateElements({
      elements: [updatedElement],
    });
    setSaving(SaveStates.saved);
  },
  5000
);

export const SubmissionEditor = () => {
  const { submission, setSubmission, submissionFunctions, commentFunctions } = useSubmissionManager();
  const [saving, setSaving] = useState(SaveStates.saved);
  const [documentId, setDocumentId] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [displayPrivilegeBanner, setDisplayPrivilegeBanner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const userId = useSelector((state: RootState) => state.auth.globalUserInfo?.user?._id);
  const currentOrgMembership = useSelector((state: RootState) => state.auth.globalUserInfo?.currentOrgMembership);
  const { getSubmissionById, updateSubmissionTitle, putSubmissionComments } = useSubmissionAPI();
  const [displayPrivilegeConfirmation, setDisplayPrivilegeConfirmation] = useState(displayPrivilegeBanner);
  const { isOpen: sharingModalIsOpen, onOpen: openSharingModal, onClose: closeSharingModal } = useDisclosure();
  const id = useParams<{ id: string }>().id;

  const { setPageInfo, setPageActions } = useTopNavContext();

  const getSubmission = async () => {
    if (id) {
      setDocumentId(id);
      const doc: any = await getSubmissionById(id);
      if (Object.keys(doc).length > 0) {
        if (!_.isEqual(doc, submission)) {
          setSubmission(doc);
        }
      } else {
        setNotFound(true);
      }
    } else {
      setNotFound(true);
    }
  };

  const putComments = async (commentThreads: CommentThread[], elementId: string) => {
    const updatedDictionary = { ...submission.commentThreadsDictionary, [elementId]: commentThreads };
    if (documentId) {
      await putSubmissionComments(documentId, updatedDictionary);
    }
  };

  const flushChanges = () => {
    debouncedUpdateTitle.flush();
    debouncedUpdateFormFields.flush();
  };

  useEffect(() => {
    return () => flushChanges();
  }, []);

  useEffect(() => {
    window.addEventListener("visibilityChange", flushChanges);

    return () => window.removeEventListener("visibilityChange", flushChanges);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(getSubmission, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const getFile = async () => {
      if (id) {
        setDocumentId(id);
        const doc: any = await getSubmissionById(id);
        if (Object.keys(doc).length > 0) {
          setSubmission(doc);
          setTitle(doc.meta?.title);
          setDisplayPrivilegeBanner(doc.meta?.privileged);
          setDisplayPrivilegeConfirmation(doc.meta?.privileged);
        } else {
          setNotFound(true);
        }
      } else {
        setNotFound(true);
      }

      setLoading(false);
      return;
    };

    setLoading(true);
    getFile();
  }, [id, currentOrgMembership, userId]);

  useEffect(() => {
    if (loading) return;
    setPageInfo(
      <PageInfo saveIndicator={<SaveIndicator saveStatus={saving} savedOnce={documentId} />}>
        <Icon as={AssignmentOutlinedIcon} color="white" />
        <AutoWidthInput
          initialValue={title}
          onChange={(e) => {
            setSaving(SaveStates.unsavedChanges);
            debouncedUpdateTitle(setSaving, documentId, e.target.value, updateSubmissionTitle);
            setTitle(e.target.value);
          }}
        />
      </PageInfo>
    );

    return () => {
      setPageInfo(<PageInfo />);
    };
  }, [title, saving, documentId, setPageInfo, loading]);

  useEffect(() => {
    if (loading) return;
    setPageActions(
      <HStack>
        <Icon as={PersonAddAlt1OutlinedIcon} onClick={openSharingModal} />
        {submission.readOnly.actions.privilegeApprovals ? (
          <PrivilegeAction
            type="submissions"
            id={documentId!}
            displayPrivilegeBanner={displayPrivilegeBanner}
            setDisplayPrivilegeBanner={setDisplayPrivilegeBanner}
            privilegeWarningDismissed={(submission.meta as any).privilegeWarningDismissed}
            setDisplayPrivilegeConfirmation={setDisplayPrivilegeConfirmation}
          />
        ) : (
          <></>
        )}
      </HStack>
    );

    return () => {
      setPageActions(<HStack></HStack>);
    };
  }, [documentId, setPageActions, loading]);

  const renderElements = (elements: FormElement[], level: number = 0): JSX.Element[] => {
    return elements?.map((element, i) => {
      const ElementComponent = ElementMap[element.type as keyof typeof ElementMap].edit;
      const debounceNeeded = ElementMap[element.type as keyof typeof ElementMap].editViewDebouncedChanges;
      if (!element.isVisibleByRules) return <></>;

      // Construct children elements recursively if they exist
      const children = element.children ? renderElements(element.children, level + 1) : undefined;
      const indentationLevel = submissionFunctions.getIndentationLevel(element.id!);
      return (
        <DocumentSectionWrapper
          currentDocument={submission}
          commentFunctions={commentFunctions}
          formFunctions={submissionFunctions}
          elementId={element.id}
          documentId={documentId!}
          userRoleOnDocument={submissionFunctions.role!}
          key={`${level}-${i}-wrapper`}
          readOnly={true}
          updateConversations={(x: CommentThread[]) => {
            submissionFunctions.updateCommentThreads(element.id!, x);
            putComments(x, element.id!);
          }}
          isSection={element.type === "section"}
        >
          <ElementComponent
            role={submissionFunctions.role!}
            titleNumber={element.titleNumber ?? ""}
            id={element.id!}
            key={`${level}-${i}`}
            indentationLevel={indentationLevel}
            value={element.content as any}
            onChange={(e: any, field: string) => {
              setSaving(SaveStates.unsavedChanges);
              debouncedUpdateFormFields(element, e, field, submissionFunctions, setSaving);
              if (!debounceNeeded.includes(field)) {
                debouncedUpdateFormFields.flush();
              }
            }}
          >
            {children}
          </ElementComponent>
        </DocumentSectionWrapper>
      );
    });
  };

  const elements = renderElements(submission.elements as FormElement[]);

  return (
    <>
      {loading ? (
        <Center>
          <HStack>
            <Text variant="bold">Loading submission...</Text>
            <Spinner />
          </HStack>
        </Center>
      ) : (
        <div
          style={{
            display: "flex",
            marginTop: HEADER_HEIGHT,
            height: `100%`,
            minHeight: `100vh`,
            backgroundColor: Colors.gray[50],
          }}
        >
          <SharingModal document={submission} type="submission" isOpen={sharingModalIsOpen} onOpen={openSharingModal} onClose={closeSharingModal} />
          <PrivilegeConfirmationModal open={displayPrivilegeConfirmation} setOpen={(x) => setDisplayPrivilegeConfirmation(x)} />
          <div style={{ minWidth: "68px", marginRight: "auto" }} />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50px 1fr",
            }}
          >
            <div style={{ backgroundColor: Colors.gray[50] }}></div>
            <div style={{ position: "relative", width: "800px", marginTop: PRIVILEGE_BANNER_HEIGHT, backgroundColor: Colors.gray[50] }}>
              {displayPrivilegeBanner ? <PrivilegedBanner /> : null}
              <div
                style={{
                  borderRadius: "3px",
                  backgroundColor: Colors.white[100],
                  padding: 40,
                  paddingTop: SUBMISSION_STATUS_HEIGHT,
                  border: `1px solid ${Colors.gray[600]}`,
                  minHeight: `calc(100vh - ${HEADER_HEIGHT} * 3)`,
                }}
              >
                <>
                  {notFound ? (
                    <Center>
                      <Heading size="md">Document Not Found</Heading>
                    </Center>
                  ) : (
                    <> {elements}</>
                  )}
                </>
              </div>
            </div>
          </div>
          <div style={{ width: 50 }} />
          <div
            style={{
              width: "400px",
              marginTop: PRIVILEGE_BANNER_HEIGHT,
            }}
          >
            <SubmissionStatusDisplay commentManagement={commentFunctions} />
          </div>
          <div style={{ minWidth: "4px", marginRight: "auto" }} />
        </div>
      )}
    </>
  );
};
