import { useAPIRequest } from "../hooks/useAPI";

export const useSubmissionAPI = () => {
  const sendAPIRequest = useAPIRequest();
  const updateSubmissionElements = async (submissionId: string, submissionValue: any) => {
    const res: any = await sendAPIRequest(`submissions/${submissionId}/elements`, "PATCH", submissionValue);
    return res;
  };

  const createNewSubmission = async (submissionValue: any) => {
    return await sendAPIRequest(`submissions`, "POST", submissionValue);
  };

  const updateSubmissionTitle = async (submissionId: string, title: string) => {
    return await sendAPIRequest(`submissions/${submissionId}/title`, "PUT", {
      title: title,
    });
  };

  const getSubmissionById = async (submissionId: string) => {
    return await sendAPIRequest(`submissions/${submissionId}`);
  };

  const deleteSubmission = async (submissionId: string) => {
    return await sendAPIRequest(`submissions/${submissionId}`, "DELETE");
  };

  const finalizeSubmission = async (submissionId: string) => {
    return await sendAPIRequest(`submissions/${submissionId}/finalize`, "PUT");
  };

  const unfinalizeSubmission = async (submissionId: string) => {
    return await sendAPIRequest(`submissions/${submissionId}/unfinalize`, "PUT");
  };

  const getSubmissions = async (params?: string) => {
    const url = params ? `submissions?${params}` : `submissions`;
    const res: any = await sendAPIRequest(url);
    return res;
  };

  const addSubmissionUsers = async (submissionId: string, users: any) => {
    return await sendAPIRequest(`submissions/${submissionId}/users`, "POST", { users });
  };

  const putSubmissionComments = async (submissionId: string, commentThreadsDictionary: any) => {
    return await sendAPIRequest(`submissions/${submissionId}/comment-threads`, "PUT", { commentThreadsDictionary });
  };

  return {
    updateSubmissionElements,
    createNewSubmission,
    updateSubmissionTitle,
    getSubmissionById,
    deleteSubmission,
    finalizeSubmission,
    unfinalizeSubmission,
    getSubmissions,
    addSubmissionUsers,
    putSubmissionComments,
  };
};
