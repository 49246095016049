import React, { useState, useMemo } from "react";
import RichText from "./ElementComponents/RichText";
import { RequiredToggle } from "./ElementComponents/RequiredToggle";
import { Text, Tooltip, Icon, HStack, Switch, Flex, VStack, Input, RadioGroup, Radio } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../../components/Spacer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { AdvancedSectionWrapper } from "./ElementComponents/AdvancedSectionWrapper";
import { AdvancedNotes } from "./ElementComponents/AdvancedNotes";
import { FormElementTypes, SelectItem } from "../../types";
import { useSelectionContext } from "../../context/SelectionContext";
import { ROLE_1, ROLE_2 } from "../../roles";
import { BuilderHeader, ResponseHeader } from "./ElementComponents/QuestionHeaders";

interface BuildViewProps {
  value: FormElementTypes.SingleSelect;
  children?: JSX.Element[];
  titleNumber: string;
  id: string;
  indentationLevel: number;
  onChange: (e: any, field: string) => void;
}

const Default = {
  type: "singleSelect",
  content: {
    audience: ROLE_1,
    title: "",
    surveyTakerNotes: {},
    adjudicatorNotes: {},
    question: "",
    required: true,
    items: [
      { label: "Option 1", selected: false, allowed: true },
      { label: "Other", selected: false, allowed: false, other: true },
      { label: "None", selected: false, allowed: false, none: true },
    ],
  },
};

const BuildView: React.FC<BuildViewProps> = ({ value, titleNumber, id, indentationLevel, onChange }) => {
  const { selectedElement } = useSelectionContext();
  const [items, setItems] = useState<FormElementTypes.SingleSelect["items"]>(value.items);
  const [otherAllowed, setOtherAllowed] = useState(false);
  const [noneAllowed, setNoneAllowed] = useState<boolean>(false);
  const [required, setRequired] = useState<boolean>(value.required);

  const updateLabel = (index: number, newValue: string) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, label: newValue };
      }
      return item;
    });

    onChange(newItems, "items");
    setItems(newItems);
  };

  const addItem = () => {
    const newItems = [...items];
    newItems.splice(items.length - 2, 0, { label: `New Option`, selected: false, allowed: true });
    onChange(newItems, "items");
    setItems(newItems);
  };

  const deleteItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    onChange(newItems, "items");
    setItems(newItems);
  };

  const updateOtherAllowed = () => {
    const updatedItems = value.items.map((item) => {
      if (item.other) {
        return { ...item, allowed: !otherAllowed };
      }
      return item;
    });
    onChange(updatedItems, "items");
    setOtherAllowed(!otherAllowed);
  };

  const updateNoneAllowed = () => {
    const updatedItems = value.items.map((item) => {
      if (item.none) {
        return { ...item, allowed: !noneAllowed };
      }
      return item;
    });
    onChange(updatedItems, "items");
    setNoneAllowed(!noneAllowed);
  };

  const updateRequired = () => {
    onChange(!required, "required");
    setRequired(!required);
  };

  const isSelectedElement = selectedElement === id;

  const singleSelectComponents = useMemo(
    () => (
      <div>
        <Flex w={"100%"} alignItems="center" direction="column">
          <div style={{ width: "100%" }}>
            <BuilderHeader
              questionPrompt="Question"
              title={value.title}
              titlePlaceholder="Untitled Single Selection Question"
              titleNumber={titleNumber}
              indentationLevel={indentationLevel}
              audience={value.audience}
              required={value.required}
              onChange={onChange}
            />
            <RichText placeholder="Enter the question" selected={isSelectedElement} value={value.question} onChange={(e) => onChange(e, "question")} />
            <RequiredToggle value={required} onChange={updateRequired} />
          </div>
          <Spacer y={SpacerSizes.sm} />

          <HStack w={"100%"} alignItems={"flex-start"} justifyContent={"space-between"}>
            <VStack alignItems={"flex-start"} w={"47%"}>
              <Text>Single Select Response Options</Text>
              <Text style={{ marginLeft: 27 }}>Label</Text>
              {items
                .filter((item) => !item.none && !item.other)
                .map((item, i) => (
                  <HStack w={"100%"} key={i} spacing={0}>
                    <div style={{ width: 25 }}>
                      <Text>{i + 1}.</Text>
                    </div>

                    <Input size="sm" onChange={(e) => updateLabel(i, e.target.value)} borderColor={"gray.300"} value={item.label} />
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        deleteItem(i);
                      }}
                      as={DeleteOutlineIcon}
                    />
                  </HStack>
                ))}
              <Icon style={{ cursor: "pointer" }} onClick={addItem} boxSize={5} as={MdOutlineAddCircleOutline} />
            </VStack>

            <VStack alignItems={"flex-start"} w={"49%"}>
              <Text>Special Responses</Text>
              <Spacer y={SpacerSizes.md} />
              <HStack w={"100%"} contentEditable={false} alignContent="center" h={"35px"}>
                <Switch contentEditable={false} size="sm" isChecked={otherAllowed} colorScheme={"brightblue"} onChange={updateOtherAllowed} />
                <Text>{items.find((item) => item.other)?.label}</Text>
                <Tooltip label={"When enabled, the end user may provide up to one additional option selection that they specify."} fontSize="xs">
                  <Icon color="gray.500" boxSize={"4"} as={HelpOutlineIcon} />
                </Tooltip>
              </HStack>

              <HStack w={"100%"} contentEditable={false} alignContent="center" h={"35px"}>
                <Switch contentEditable={false} size="sm" isChecked={noneAllowed} colorScheme={"brightblue"} onChange={updateNoneAllowed} />
                <Text>{items.find((item) => item.none)?.label}</Text>
                <Tooltip label={"When enabled, the end user may select 'None of the above' as an option."} fontSize="xs">
                  <Icon color="gray.500" boxSize={"4"} as={HelpOutlineIcon} />
                </Tooltip>
              </HStack>
            </VStack>
          </HStack>
        </Flex>
        <AdvancedSectionWrapper>
          <div style={{ width: "100%" }}>
            <AdvancedNotes onChange={onChange} surveyTakerNotes={value.surveyTakerNotes} adjudicatorNotes={value.adjudicatorNotes} />
          </div>
        </AdvancedSectionWrapper>
      </div>
    ),
    [id, indentationLevel, isSelectedElement, titleNumber, value, items, otherAllowed, noneAllowed, required]
  );

  return isSelectedElement ? (
    singleSelectComponents
  ) : (
    <EditView role={ROLE_2} id={id} value={value} titleNumber={titleNumber} onChange={onChange} indentationLevel={indentationLevel} buildView={true} />
  );
};

const BuildViewDebouncedChanges: string[] = ["title", "question", "surveyTakerNotes", "adjudicatorNotes"];

interface EditViewProps {
  value: FormElementTypes.SingleSelect;
  children?: JSX.Element[];
  titleNumber: string;
  id: string;
  indentationLevel: number;
  onChange: (e: any, field: string) => void;
  buildView?: boolean;
}

const EditView: React.FC<EditViewProps & { role: string }> = ({ value, titleNumber, onChange, indentationLevel, role, buildView = false }) => {
  const roleMatchesAudience = value.audience === "all" || value.audience === role;
  const [selectedValue, setSelectedValue] = useState<string>(getSelectedValue(value.items));

  useMemo(() => {
    setSelectedValue(getSelectedValue(value.items));
  }, [value.items]);

  function getSelectedValue(items: SelectItem[]): string {
    let rtn = "";
    for (let i = 0; i < items.length; i++) {
      if (items[i].selected) {
        rtn = items[i].label;
        break;
      }
    }
    return rtn;
  }

  const updateSelectedRadio = (e: string) => {
    if (!roleMatchesAudience) {
      return;
    }

    let newItems = value.items.map((item) => {
      return { ...item, selected: false };
    });

    newItems = newItems.map((item) => {
      if (item.label === e) {
        return { ...item, selected: true };
      }
      return { ...item, selected: false };
    });
    setSelectedValue(getSelectedValue(newItems));
    onChange(newItems, "items");
  };

  if (!roleMatchesAudience && role !== ROLE_2) {
    return null;
  }

  return (
    <Flex w={"100%"} alignItems="center" direction="column">
      <div style={{ width: "100%" }}>
        <ResponseHeader
          title={value.title}
          indentationLevel={indentationLevel}
          titleNumber={titleNumber}
          required={value.required}
          role={role}
          adjudicatorNotes={value.adjudicatorNotes}
          surveyTakerNotes={value.surveyTakerNotes}
          audience={value.audience}
          buildView={buildView}
          questionPlaceholder="Enter the question"
          question={value.question}
        />
      </div>
      <HStack justifyContent={"flex-start"} w={"85%"}>
        <RadioGroup onChange={(e) => updateSelectedRadio(e)} value={selectedValue}>
          <VStack gap={1} alignItems={"flex-start"} justifyContent={"flex-start"}>
            {value.items
              .filter((item) => item.allowed)
              .map((item, i) => (
                <Radio colorScheme="brightblue" key={i} value={item.label}>
                  {item.label}
                </Radio>
              ))}
          </VStack>
        </RadioGroup>
      </HStack>
    </Flex>
  );
};

const EditViewDebouncedChanges: string[] = [];

export default { BuildView, EditView, Default, EditViewDebouncedChanges, BuildViewDebouncedChanges };
