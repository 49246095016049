import { Colors } from "../theme/colors";
import { useSelector } from "react-redux";
import { RootState } from "../store";
export const PRIVILEGE_BANNER_HEIGHT = "30px";

export const PrivilegedBanner = () => {
  const privilegeSettings = useSelector((state: RootState) => state.auth.globalUserInfo?.currentOrgMembership?.organization?.privilegeSettings);
  return (
    <div
      style={{
        zIndex: 10,
        height: PRIVILEGE_BANNER_HEIGHT,
        marginBottom: PRIVILEGE_BANNER_HEIGHT,
        backgroundColor: Colors.brand[200],
        borderRadius: "3px",
      }}
    >
      <div
        style={{
          padding: "2px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {privilegeSettings?.privilegeBanner ?? "Privileged and Confidential"}
      </div>
    </div>
  );
};
