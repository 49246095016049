import { useState, useRef, useEffect } from "react";
import { nanoid } from "nanoid";
import { cloneDeep } from "lodash";
import romanize from "../util/romanize";
import { EnrichedForm, FormElement, PathIndex, Position } from "../types";
import useCommentManager from "./useCommentManager";

const defaultForm: EnrichedForm = {
  _id: "",
  meta: {
    title: "",
    description: "",
    version: 1,
    publishedVersion: 1,
    privileged: false,
    createdAt: "",
    createdByUserId: "",
    organizationId: "",
    privilegeWarningDismissed: false,
    archived: false,
    updatedAt: "",
  },
  elements: [],
  users: [],
  commentThreadsDictionary: {},
  availability: {
    discoverable: false,
    audience: [],
  },
  readOnly: {
    actions: {
      share: true,
      comment: true,
      edit: true,
      submit: true,
      copy: true,
      archive: true,
      privilegeApprovals: false,
      becomeAdjudicator: true,
    },
    creator: {
      userId: "",
    },
    attentionItems: {
      privilegeApprovalsNeeded: false,
    },
    usersInfo: [],
  },
};

function useFormManager(initialForm?: EnrichedForm) {
  const [form, setForm] = useState<EnrichedForm>(initialForm ?? defaultForm);
  const formRef = useRef<EnrichedForm>(initialForm ?? defaultForm);
  const { commentThreadsDictionary, setCommentThreadsDictionary, getElementCommentThreads, commentFunctions } = useCommentManager();

  useEffect(() => {
    formRef.current = form;
    setCommentThreadsDictionary(form.commentThreadsDictionary);
  }, [form]);

  const getTitleNumber = (path: PathIndex): string => {
    let titleNumber = "";
    path.forEach((levelIndex, nestingLevel) => {
      const levelIndexNumber = levelIndex + 1;

      titleNumber += [levelIndexNumber, String.fromCharCode(96 + levelIndexNumber), romanize(levelIndexNumber), levelIndexNumber][nestingLevel];

      titleNumber += ".";
    });

    return titleNumber;
  };

  const assignPathIdsAndSectionNumbers = (elements: FormElement[], currentPath: PathIndex = []) => {
    elements
      ?.filter((element) => element.type !== "textBlock")
      .forEach((element, index) => {
        element.pathIndex = [...currentPath, index];
        element.titleNumber = getTitleNumber(element.pathIndex);
        if (element.children && element.children.length > 0) {
          assignPathIdsAndSectionNumbers(element.children, element.pathIndex);
        }
      });
  };

  const triggerUpdate = () => {
    if (formRef.current.elements) {
      assignPathIdsAndSectionNumbers(formRef.current.elements);
    }

    setForm({ ...formRef.current });
  };

  const loadForm = (form: EnrichedForm) => {
    formRef.current = cloneDeep(form);
    setCommentThreadsDictionary(form.commentThreadsDictionary);
    triggerUpdate();
  };

  const updateMeta = ({ meta }: { meta: EnrichedForm["meta"] }) => {
    formRef.current.meta = {
      ...meta,
    };

    setForm({ ...formRef.current });
  };

  const getElementPathIndexes = (elementIds: string[]): PathIndex[] => {
    const findPathIndex = (id: string, elements: FormElement[], currentPath: PathIndex = []): PathIndex | null => {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        if (element.id === id) {
          return [...currentPath, i];
        }

        if (element.children) {
          const childPath = findPathIndex(id, element.children, [...currentPath, i]);

          if (childPath) {
            return childPath;
          }
        }
      }

      return null;
    };

    return elementIds.map((id) => findPathIndex(id, formRef.current.elements) || []);
  };

  const getElementIds = (pathIndexes: PathIndex[]): string[] => {
    return pathIndexes.map((pathIndex) => {
      let currentElements = formRef.current.elements;

      let targetId = "";
      for (let i = 0; i < pathIndex.length; i++) {
        const index = pathIndex[i];
        if (index < 0 || index >= currentElements.length) {
          targetId = "";
          break;
        }
        const element = currentElements[index];
        targetId = element.id!;
        currentElements = element.children || [];
      }

      return targetId;
    });
  };

  const getIndentationLevel = (elementId: string): number => {
    return getElementPathIndexes([elementId])[0].length;
  };

  const getElementAndParent = (pathIndex: PathIndex): [FormElement | undefined, FormElement | undefined] => {
    let currentElements = formRef.current.elements;
    let parentElement: FormElement | undefined;
    let targetElement: FormElement | undefined;

    for (let i = 0; i < pathIndex.length; i++) {
      const index = pathIndex[i];

      if (index < 0 || index >= currentElements.length) {
        return [undefined, undefined];
      }

      parentElement = targetElement;
      targetElement = currentElements[index];
      currentElements = targetElement.children || [];
    }

    return [targetElement, parentElement];
  };

  const getSiblingAbove = (pathIndex: PathIndex): FormElement | null => {
    if (pathIndex.length === 0) return null;

    const targetIndex = pathIndex[pathIndex.length - 1];
    const parentPathIndex = pathIndex.slice(0, -1);

    const parentElements = parentPathIndex.length > 0 ? getElementAndParent(parentPathIndex)[0]?.children : formRef.current.elements;

    return parentElements && targetIndex > 0 ? parentElements[targetIndex - 1] : null;
  };

  const getSiblingBelow = (pathIndex: PathIndex): FormElement | null => {
    if (pathIndex.length === 0) return null;

    const targetIndex = pathIndex[pathIndex.length - 1];
    const parentPathIndex = pathIndex.slice(0, -1);

    const parentElements = parentPathIndex.length > 0 ? getElementAndParent(parentPathIndex)[0]?.children : formRef.current.elements;

    return parentElements && targetIndex < parentElements.length - 1 ? parentElements[targetIndex + 1] : null;
  };

  const addElements = ({
    elements = [],
    position,
    referenceElement,
  }: {
    elements: FormElement[];
    position: Position;
    referenceElement?: string | PathIndex;
  }) => {
    if (!elements.length) {
      throw new Error("No elements provided.");
    }

    const newElements = elements.map((element) => ({
      ...(element.type === "section" && { children: [] }),
      ...element,
      id: nanoid(16),
    }));

    if (referenceElement === undefined) {
      formRef.current.elements = [...formRef.current.elements, ...newElements];
    } else {
      const targetPathIndex = typeof referenceElement === "string" ? getElementPathIndexes([referenceElement])[0] : referenceElement;

      const [targetElement, parentElement] = getElementAndParent(targetPathIndex);

      if (!targetElement) {
        throw new Error("Target element not found.");
      }

      if (position === Position.In && targetElement.type !== "section") {
        throw new Error("Position is 'in', but target element is not a section.");
      }

      if (position === Position.In) {
        targetElement.children = [...(targetElement.children || []), ...newElements];
      } else {
        const parentArray = parentElement ? parentElement.children : formRef.current.elements;

        const index = parentArray!.indexOf(targetElement);

        parentArray!.splice(index + 1, 0, ...newElements);
      }
    }

    triggerUpdate();
  };

  const updateElements = ({ elements }: { elements: FormElement[] }) => {
    elements?.forEach((element) => {
      const pathIndex = getElementPathIndexes([element.id!])[0];
      const [targetElement] = getElementAndParent(pathIndex);

      if (targetElement) {
        targetElement.content = {
          ...element.content,
        };
      }
    });

    triggerUpdate();
  };

  const indentElement = ({ elementIdOrPath }: { elementIdOrPath: string | PathIndex }) => {
    const targetPathIndex = typeof elementIdOrPath === "string" ? getElementPathIndexes([elementIdOrPath])[0] : elementIdOrPath;

    const [targetElement, parentElement] = getElementAndParent(targetPathIndex);

    if (!targetElement) {
      throw new Error("Target element not found.");
    }

    const sibling = getSiblingAbove(targetPathIndex);

    if (sibling && sibling.type === "section") {
      sibling.children = [...(sibling.children || []), targetElement];

      const parentArray = parentElement ? parentElement.children : formRef.current.elements;

      const index = parentArray!.indexOf(targetElement);
      parentArray!.splice(index, 1);
    }

    triggerUpdate();
  };

  const dedentElement = ({ elementIdOrPath }: { elementIdOrPath: string | PathIndex }) => {
    const targetPathIndex = typeof elementIdOrPath === "string" ? getElementPathIndexes([elementIdOrPath])[0] : elementIdOrPath;

    const [targetElement, parentElement] = getElementAndParent(targetPathIndex);

    if (!targetElement || !parentElement || targetPathIndex.length <= 1) {
      throw new Error("Cannot dedent at the top level.");
    }

    const grandParentPathIndex = targetPathIndex.slice(0, -2);

    const [grandParentElement] = getElementAndParent(grandParentPathIndex);

    const parentIndex = parentElement.children!.indexOf(targetElement);

    if (parentIndex !== -1) {
      const newPath = parentElement.children!.length;
      parentElement.children!.splice(parentIndex, 1); // remove target element from current indentation level

      if (grandParentElement) {
        const newPath = grandParentElement.children!.length;
        grandParentElement.children!.splice(parentIndex + newPath, 0, targetElement); // inserts target element at the end of the grandparent's children
      } else {
        formRef.current.elements.splice(newPath + 1, 0, targetElement); // inserts target element as its parent's sibling
      }
    }

    triggerUpdate();
  };

  const removeElements = ({ elementIds = [] }: { elementIds: string[] }) => {
    elementIds.forEach((id) => {
      const pathIndex = getElementPathIndexes([id])[0];
      const [targetElement, parentElement] = getElementAndParent(pathIndex);

      if (targetElement) {
        const parentArray = parentElement ? parentElement.children : formRef.current.elements;
        const index = parentArray!.indexOf(targetElement);
        parentArray!.splice(index, 1);
      }
    });

    triggerUpdate();
  };

  const swapElement = ({ elementId, position }: { elementId: string; position: Position }) => {
    const pathIndex = getElementPathIndexes([elementId])[0];
    const [targetElement, parentElement] = getElementAndParent(pathIndex);

    if (!targetElement) {
      throw new Error("Target element not found.");
    }

    const sibling = position === Position.Above ? getSiblingAbove(pathIndex) : getSiblingBelow(pathIndex);

    if (!sibling) {
      throw new Error("No sibling found.");
    }

    const parentArray = parentElement ? parentElement.children : formRef.current.elements;

    const targetIndex = parentArray!.indexOf(targetElement);
    const siblingIndex = parentArray!.indexOf(sibling);

    parentArray!.splice(targetIndex, 1, sibling);
    parentArray!.splice(siblingIndex, 1, targetElement);

    triggerUpdate();
  };

  const updateCommentThreads = (elementId: string, commentThreads: any) => {
    commentThreadsDictionary[elementId] = commentThreads;

    formRef.current.commentThreadsDictionary = {
      ...commentThreadsDictionary,
    };

    setCommentThreadsDictionary(formRef.current.commentThreadsDictionary);

    triggerUpdate();
  };

  return {
    form: formRef.current,
    setForm: loadForm,
    commentFunctions,
    formFunctions: {
      updateMeta,
      addElements,
      updateElements,
      indentElement,
      dedentElement,
      removeElements,
      getElementPathIndexes,
      getElementIds,
      getIndentationLevel,
      getSiblingAbove,
      getSiblingBelow,
      getElementAndParent,
      swapElement,
      getElementCommentThreads,
      updateCommentThreads,
      formMembers: formRef.current.users,
    },
  };
}

export default useFormManager;
