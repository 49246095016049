import { Heading, Card, CardBody } from "@chakra-ui/react";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { Spacer, SpacerSizes } from "../../components/Spacer";
import { Colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import { useFormAPI } from "../../endpoints/forms";
export const EmptyFormsSection = () => {
  const navigate = useNavigate();
  const { createNewForm } = useFormAPI();

  async function createFormAndGo() {
    const formContent: any = {
      meta: {
        title: "Untitled Form",
        description: "",
      },
      elements: [
        {
          type: "section",
          content: { title: "Untitled Section" },
          id: nanoid(16),
        },
      ],
      commentThreadsDictionary: {},
    };
    const newForm: any = await createNewForm(formContent);
    navigate(`/form-builder/${newForm.id}`);
  }

  return (
    <Card style={{ cursor: "pointer" }} onClick={createFormAndGo}>
      <CardBody
        style={{
          backgroundColor: "white",
          border: "dashed",
          borderWidth: "1px",
          borderColor: Colors.brand[500],
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Heading variant="h2" color="brand.500">
          Create your first Form to get started.
        </Heading>
        <Spacer x={SpacerSizes.md} />
        <PlusSquareIcon w="40px" h="40px" color="brand.500" />
      </CardBody>
    </Card>
  );
};
