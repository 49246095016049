import BalanceOutlinedIcon from "@mui/icons-material/BalanceOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import { EyeIcon } from "lucide-react";
import { Icon, Tooltip } from "@chakra-ui/react";
import { Colors } from "../../../theme/colors";
import { ROLE_1, ROLE_2 } from "../../../roles";

function getTooltipText({ audience, buildView, staticContent }: { audience: string; buildView: boolean; staticContent: boolean }) {
  const textAllSubmission = `This element is visible to and editable by ${ROLE_1}s and reviewers.`;
  const textAllBuilder = `When submitting, this element will be visible to and editable by ${ROLE_1}s and reviewers. Use this option sparingly.`;
  const textSubmitterSubmission = `This element is visible to all users, but is only editable by ${ROLE_1}s.`;
  const textSubmitterBuilder = `When submititng, this element will be visible to all users, but only editable by ${ROLE_1}s.`;
  const textAdjudicatorSubmission = `This element is visible to all users, but is only editable by ${ROLE_1}s.`;
  const textAdjudicatorBuilder = `When submitting, this element will only be visible to and editable by reviewers. Once finalized, both categories of users can see this element.`;

  const textAllSubmissionStatic = `This element is visible to ${ROLE_1}s and reviewers.`;
  const textAllBuilderStatic = `When submitting, this element will be visible to ${ROLE_1}s and reviewers.`;
  const textSubmitterSubmissionStatic = `This element is visible to ${ROLE_1}s and reviewers`;
  const textSubmitterBuilderStatic = `When submitting, this element will be visible to ${ROLE_1}s and reviewers.`;
  const textAdjudicatorSubmissionStatic = `This element is only visible to reviewers. After the submission is finalized, both categories of users can see this element.`;
  const textAdjudicatorBuilderStatic = `When submitting, this element will only be visible to reviewers. After the submission is finalized, both categories of users can see this element.`;

  if (staticContent) {
    if (buildView) {
      if (audience === "submitter") {
        return textSubmitterBuilderStatic;
      } else if (audience === "adjudicator") {
        return textAdjudicatorBuilderStatic;
      } else {
        return textAllBuilderStatic;
      }
    } else {
      if (audience === "submitter") {
        return textSubmitterSubmissionStatic;
      } else if (audience === "adjudicator") {
        return textAdjudicatorSubmissionStatic;
      } else {
        return textAllSubmissionStatic;
      }
    }
  }
  if (buildView) {
    if (audience === "submitter") {
      return textSubmitterBuilder;
    } else if (audience === "adjudicator") {
      return textAdjudicatorBuilder;
    } else {
      return textAllBuilder;
    }
  } else {
    if (audience === "submitter") {
      return textSubmitterSubmission;
    } else if (audience === "adjudicator") {
      return textAdjudicatorSubmission;
    } else {
      return textAllSubmission;
    }
  }
}

export const AudienceSelection = ({
  audience,
  changeAudience,
  staticContent = false,
}: {
  audience: string;
  changeAudience: (x: string) => void;
  staticContent?: boolean;
}) => {
  const audiences = ["all", ROLE_1, ROLE_2];
  const audienceIconMap: { [key: string]: any } = {
    all: {
      icon: EyeIcon,
      tooltip: `${getTooltipText({ audience: audience, buildView: true, staticContent: staticContent })} Click to change.`,
    },
    submitter: {
      icon: ChecklistOutlinedIcon,
      tooltip: `${getTooltipText({ audience: audience, buildView: true, staticContent: staticContent })} Click to change.`,
    },
    adjudicator: {
      icon: BalanceOutlinedIcon,
      tooltip: `${getTooltipText({ audience: audience, buildView: true, staticContent: staticContent })} Click to change.`,
    },
  };

  const handleAudienceChange = () => {
    const currentIndex = audiences.indexOf(audience);
    const nextIndex = (currentIndex + 1) % audiences.length;
    // if (staticContent && audiences[nextIndex] === "submitter") {
    // nextIndex = (nextIndex + 1) % audiences.length;
    // }
    changeAudience(audiences[nextIndex]);
  };

  return (
    <div onClick={handleAudienceChange}>
      <Tooltip label={audienceIconMap[audience]?.tooltip} aria-label="A tooltip">
        <Icon
          style={{
            height: 25,
          }}
          p={1}
          _hover={{ color: Colors.gray[700], cursor: "pointer" }}
          boxSize={7}
          as={audienceIconMap[audience]?.icon}
        />
      </Tooltip>
    </div>
  );
};

export const AudienceDisplay = ({ audience, buildView, staticContent = false }: { audience: string; buildView: boolean; staticContent?: boolean }) => {
  const audienceIconMap: { [key: string]: any } = {
    all: {
      icon: EyeIcon,
      tooltip: getTooltipText({ audience: "all", buildView: buildView, staticContent: staticContent }),
    },
    submitter: {
      icon: ChecklistOutlinedIcon,
      tooltip: getTooltipText({ audience: "submitter", buildView: buildView, staticContent: staticContent }),
    },
    adjudicator: {
      icon: BalanceOutlinedIcon,
      tooltip: getTooltipText({ audience: "adjudicator", buildView: buildView, staticContent: staticContent }),
    },
  };

  return (
    <div>
      <Tooltip label={audienceIconMap[audience]?.tooltip} aria-label="A tooltip">
        <Icon
          style={{
            height: 25,
          }}
          p={1}
          _hover={{ color: Colors.gray[700], cursor: "pointer" }}
          boxSize={7}
          as={audienceIconMap[audience]?.icon}
        />
      </Tooltip>
    </div>
  );
};
