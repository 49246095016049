import { useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { OrganizationMembershipModal } from "../../components/Modal/OrganizationMembershipModal";
import { MembershipDetail } from "../../types";
import { useSelector } from "react-redux";

import MembersTableBody from "./MembersTabPanel/MembersTableBody";
import { useOrganizationAPI } from "../../endpoints/organization";

export default function MembersTabPanel({ refreshMemberships }: { refreshMemberships: number }) {
  const { organizationId, globalUserInfo } = useSelector((state: any) => state.auth);
  const [membershipData, setMembershipData] = useState<MembershipDetail[]>();
  const [membershipToEdit, setMembershipToEdit] = useState<MembershipDetail>();
  const [internalRefreshMemberships, setInteranalRefreshMemberships] = useState<number>(0);
  const { getOrganizationMembers } = useOrganizationAPI();

  const thStyle = {
    height: "140px",
    whiteSpace: "nowrap",
    verticalAlign: "bottom",
  };
  const spanStyle = {
    padding: "5px 10px",
    fontSize: "11.0pt",
    color: "var(--chakra-colors-gray-600)",
  };

  const spanStyleNarrow = {
    padding: "5px 10px",
    fontSize: "11.0pt",
    color: "var(--chakra-colors-gray-600)",

    width: "140px",
  };

  const rotationStyle = {
    transform: "translate(4px, -4px) rotate(315deg)",
    width: "20px",
    overflow: "visible",
  };

  const getMembers = async () => {
    const qr: any = await getOrganizationMembers();
    setMembershipData(qr.filter((member: any) => member.user) as MembershipDetail[]);
  };

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    getMembers();
  }, [organizationId, refreshMemberships, internalRefreshMemberships]);

  const { isOpen: addUserModalOpen, onOpen: openAddUserModal, onClose: closeAddUserModal } = useDisclosure();

  function setRefreshMembershipsWrapper() {
    setInteranalRefreshMemberships(internalRefreshMemberships + 1);
  }

  return (
    <>
      <OrganizationMembershipModal
        isOpen={addUserModalOpen}
        onClose={closeAddUserModal}
        setRefreshMemberships={setRefreshMembershipsWrapper}
        organizationName={globalUserInfo!.currentOrgMembership.organization!.name}
        membershipToEdit={membershipToEdit as MembershipDetail}
      />
      <table style={{ width: "1200px" }}>
        <thead>
          <tr>
            <th></th>
            <th style={thStyle}>
              <div>
                <span style={spanStyle}>Name</span>
              </div>
            </th>
            <th style={thStyle}>
              <div>
                <span style={spanStyle}>Email</span>
              </div>
            </th>
            <th style={thStyle}>
              <div>
                <span style={spanStyle}>Relationship</span>
              </div>
            </th>
            <th style={thStyle}>
              <div>
                <span style={spanStyle}>Accepted</span>
              </div>
            </th>
            <th style={thStyle}>
              <div>
                <span style={spanStyle}>Expiration</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Manage All Permissions</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Manage Org Settings</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Invite Users</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Manage Org Level Tags</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Create or Copy Forms</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Manage Legal Privilege</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>See All Groups</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Manage All Groups</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>Create Groups</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>See All Members</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={rotationStyle}>
                <span style={spanStyleNarrow}>See All Guests</span>
              </div>
            </th>
          </tr>
        </thead>
        {membershipData ? (
          <MembersTableBody membershipData={membershipData} setMembershipToEdit={setMembershipToEdit} openAddUserModal={openAddUserModal} />
        ) : null}
        <tfoot></tfoot>
      </table>
    </>
  );
}
