import { Text, Icon, HStack, Divider, Checkbox } from "@chakra-ui/react";
import SendOutlined from "@mui/icons-material/SendOutlined";
import RichText, { richTextInitialValue } from "../Elements/ElementComponents/RichText";
import { useState } from "react";
import { Collapse } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../../components/Spacer";
import _ from "lodash";

export const NewCommentInput = ({
  placeholderText = "Add a comment",
  onSend,
}: {
  placeholderText?: string;
  onSend: (comment: any, assignedTo?: string[], mentioned?: string[]) => boolean | void | Promise<void>;
}) => {
  const [displayAssignmentOption, setDisplayAssignmentOption] = useState<boolean>(false);
  const [assign, setAssign] = useState<boolean>(false);
  const [mentionedUser, setMentionedUser] = useState<string[]>([]);
  const [text, setText] = useState<any>(richTextInitialValue);
  const [reset, setReset] = useState<boolean>(false);

  const changeText = (value: any) => {
    setReset(false);
    setText(value);

    const textNodes = value[0].children;

    const mentionedUsers = textNodes.filter((textNode: any) => textNode.type == "@");

    if (mentionedUsers.length > 0) {
      setDisplayAssignmentOption(true);
      setMentionedUser(mentionedUsers.map((textNode: any) => textNode.value));
    } else {
      setDisplayAssignmentOption(false);
    }
  };

  const onClick = async () => {
    if (_.isEqual(text, richTextInitialValue)) return;

    if (assign) {
      await onSend(text, mentionedUser);
    } else if (mentionedUser && !assign) {
      await onSend(text, undefined, mentionedUser);
    } else {
      await onSend(text);
    }

    setText({});
    setMentionedUser([]);
    setDisplayAssignmentOption(false);
    setReset(true); // clear text
  };

  return (
    <>
      <HStack width={"100%"} alignContent={"flex-start"} justifyItems={"space-between"}>
        <div style={{ width: "85%" }}>
          <RichText
            includeMentions={true}
            value={text}
            onChange={(value) => changeText(value)}
            size={"fit"}
            placeholder={placeholderText}
            showToolbar={false}
            reset={reset}
            borderColor="border-none"
          />
        </div>
        <Icon
          cursor={_.isEqual(text, richTextInitialValue) ? "default" : "pointer"}
          onClick={onClick}
          color={_.isEqual(text, richTextInitialValue) ? "gray.200" : "brightblue.500"}
          boxSize={5}
          as={SendOutlined}
        />
      </HStack>
      <Divider borderWidth="1px" borderColor={"gray.800"} />
      <Collapse in={displayAssignmentOption}>
        <Spacer y={SpacerSizes.sm} />
        <Checkbox colorScheme="brightblue" isChecked={assign} onChange={() => setAssign(!assign)}>
          <Text variant="caption">Assign to {mentionedUser.join(", ")}. They will be notified and responsible for marking as done.</Text>
        </Checkbox>
      </Collapse>
    </>
  );
};
