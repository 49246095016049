import { HStack, Menu, MenuButton, Text } from "@chakra-ui/react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export function PageInfo({
  title,
  saveIndicator,
  children,
  menuList,
}: {
  title?: string;
  saveIndicator?: JSX.Element;
  children?: JSX.Element[];
  menuList?: JSX.Element;
}) {
  return (
    <HStack
      style={{
        width: "fit-content",
      }}
    >
      {title && (
        <Text color="white" size="lg" fontWeight={"bold"}>
          {title}
        </Text>
      )}

      {children}

      {menuList && (
        <Menu>
          <MenuButton as={KeyboardArrowDownIcon} aria-label="Options" style={{ color: "white" }} />
          <>{menuList ? <>{menuList}</> : <></>}</>
        </Menu>
      )}

      {saveIndicator}
    </HStack>
  );
}
