import { CommentThread, FormUser } from "../../types";
import { Collapse, Tabs, Tab, TabList, TabPanels, TabPanel, Icon, Tooltip } from "@chakra-ui/react";
import { NewCommentInput } from "./NewCommentInput";
import { ROLE_1, ROLE_2 } from "../../roles";
import { useState, useEffect, memo } from "react";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { MemoizedConversation } from "./Conversation";
import useUpdateConversation from "../../hooks/useUpdateConversation";
import _ from "lodash";

const ElementConversations = ({
  filteredCommentThreads,
  commentThreads,
  isSelected,
  updateConversation,
  updateConversations,
  formMembers,
  userRoleOnDocument,
  documentId,
}: {
  filteredCommentThreads: CommentThread[];
  commentThreads: CommentThread[];
  isSelected: boolean;
  updateConversation: (x: CommentThread) => void;
  updateConversations: (x: CommentThread[]) => void;
  formMembers: FormUser[];
  userRoleOnDocument: string;
  documentId: string;
}) => {
  const [businessComments, setBusinessComments] = useState<CommentThread[]>([]);
  const [tabOneCommentInputVisible, setTabOneCommentInputVisible] = useState(false);
  const [tabTwoCommentInputVisible, setTabTwoCommentInputVisible] = useState(false);
  const [governanceComments, setGovernanceComments] = useState<CommentThread[]>([]);

  const pathname = window.location.pathname;
  const isFormBuilder = pathname.includes("form-builder");
  const { addNewConversation, addNewComment, resolveConversation } = useUpdateConversation();

  useEffect(() => {
    const businessComments = filteredCommentThreads.filter((commentThread) => commentThread.type === ROLE_1);
    setBusinessComments(businessComments);

    const governanceComments = filteredCommentThreads.filter((commentThread) => [ROLE_2, "owner"].includes(commentThread.type));
    setGovernanceComments(governanceComments);
  }, [filteredCommentThreads]);

  return (
    <Collapse in={isSelected}>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 2,
          minWidth: "400px",
          boxShadow: isSelected ? "0 4px 8px 0 rgba(0, 0, 0, 0.2)" : "none",
          zIndex: isSelected ? 10 : 0,
        }}
      >
        <Tabs w={"100%"}>
          <TabList>
            {(!isFormBuilder && userRoleOnDocument === ROLE_1) || (isFormBuilder && [ROLE_2, "owner", "editor"].includes(userRoleOnDocument)) ? (
              <Tab>Comments</Tab>
            ) : (
              <>
                <Tab>{ROLE_1}</Tab>
                <Tab>reviewer</Tab>
              </>
            )}
          </TabList>
          <TabPanels>
            {!isFormBuilder ? (
              <TabPanel flexDirection="column" alignContent={"flex-end"}>
                {businessComments.map((commentThread, i) => (
                  <MemoizedConversation
                    key={i}
                    commentThread={commentThread}
                    onResolve={() => resolveConversation(commentThread, updateConversations, businessComments)}
                    onSend={(newComment: any, commentThread: CommentThread, assignedTo: string[], mentioned: string[]) =>
                      addNewComment(newComment, commentThreads, commentThread, updateConversations, documentId, formMembers, assignedTo, mentioned)
                    }
                  />
                ))}
                <Tooltip label={`Start a new ${ROLE_1} conversation`} aria-label="Add Comment">
                  <Icon
                    onClick={() => setTabOneCommentInputVisible(!tabOneCommentInputVisible)}
                    as={AddCommentOutlinedIcon}
                    boxSize={5}
                    color={"brightblue.500"}
                  />
                </Tooltip>
                <Collapse in={tabOneCommentInputVisible}>
                  <NewCommentInput
                    onSend={(commentText, assignedTo, mentioned) => {
                      addNewConversation(commentText, ROLE_1, updateConversation, documentId, formMembers, assignedTo, mentioned);
                      setTabOneCommentInputVisible(false);
                    }}
                    placeholderText={`Start new ${ROLE_1} conversation`}
                  />
                </Collapse>
              </TabPanel>
            ) : null}
            {[ROLE_2, "owner", "editor"].includes(userRoleOnDocument) ? (
              <TabPanel justifyItems={"flex-end"}>
                {governanceComments.map((commentThread, i) => (
                  <MemoizedConversation
                    key={i}
                    commentThread={commentThread}
                    onResolve={() => resolveConversation(commentThread, updateConversations, governanceComments)}
                    onSend={(newComment: any, commentThread: CommentThread, assignedTo: string[], mentioned: string[]) =>
                      addNewComment(newComment, commentThreads, commentThread, updateConversations, documentId, formMembers, assignedTo, mentioned)
                    }
                  />
                ))}
                <Tooltip label={`Start a new reviewer conversation`} aria-label="Add Comment">
                  <Icon
                    onClick={() => setTabTwoCommentInputVisible(!tabTwoCommentInputVisible)}
                    as={AddCommentOutlinedIcon}
                    boxSize={5}
                    color={"brightblue.500"}
                  />
                </Tooltip>
                <Collapse in={tabTwoCommentInputVisible}>
                  <NewCommentInput
                    onSend={(newCommentText, assignedTo, mentioned) => {
                      addNewConversation(newCommentText, ROLE_2, updateConversation, documentId, formMembers, assignedTo, mentioned);
                      setTabTwoCommentInputVisible(false);
                    }}
                    placeholderText={`Start new reviewer conversation`}
                  />
                </Collapse>
              </TabPanel>
            ) : null}
          </TabPanels>
        </Tabs>
      </div>
    </Collapse>
  );
};

export default memo(ElementConversations);
