import React from "react";
import { cn } from "@udecode/cn";
import { PlateContent } from "@udecode/plate-common";
import { cva } from "class-variance-authority";

import type { PlateContentProps } from "@udecode/plate-common";
import type { VariantProps } from "class-variance-authority";

const editorVariants = cva(
  cn(
    "relative overflow-x-auto whitespace-pre-wrap break-words",
    "w-full rounded-md px-3 py-2 text-md ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none",
    "[&_[data-slate-placeholder]]:text-muted-foreground [&_[data-slate-placeholder]]:!opacity-100",
    "[&_[data-slate-placeholder]]:top-[auto_!important]",
    "[&_strong]:font-bold"
  ),
  {
    variants: {
      variant: {
        outline: "border-[2px] border-gray-500",
        ghost: "",
      },

      disabled: {
        true: "cursor-not-allowed opacity-50",
      },

      size: {
        sm: "max-h-[50px]",
        md: "min-h-[100px]",
        fit: "h-fit",
        full: "h-full",
      },
    },
    defaultVariants: {
      variant: "ghost",
      size: "sm",
    },
  }
);

export type EditorProps = PlateContentProps & VariantProps<typeof editorVariants>;

const Editor = React.forwardRef<HTMLDivElement, EditorProps>(({ className, disabled, readOnly, size, variant, ...props }, ref) => {
  return (
    <div ref={ref} className="relative w-full">
      <PlateContent
        className={cn(
          editorVariants({
            disabled,
            size,
            variant,
          }),
          className
        )}
        disableDefaultStyles
        readOnly={disabled ?? readOnly}
        aria-disabled={disabled}
        {...props}
      />
    </div>
  );
});
Editor.displayName = "Editor";

export { Editor };
