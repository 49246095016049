import { MentionableUser } from "../types";

export interface IOrgState {
  mentionableMembers: MentionableUser[];
  currentOrganization: any;
}

const initialState = {
  mentionableMembers: [],
  currentOrganization: null,
};

function organizationReducer(state = initialState, action: any) {
  switch (action.type) {
    case "SET_MENTIONABLE_ORGANIZATION_MEMBERS":
      return {
        ...state,
        mentionableMembers: action.payload,
      };
    default:
      return state;
  }
}

export default organizationReducer;
