import React, { useEffect } from "react";
import RichText from "./ElementComponents/RichText";
import { RequiredToggle } from "./ElementComponents/RequiredToggle";
import { AdvancedSectionWrapper } from "./ElementComponents/AdvancedSectionWrapper";
import {
  Flex,
  Text,
  HStack,
  StackDivider,
  Input,
  NumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  Switch,
  VStack,
  Checkbox,
  InputGroup,
  Icon,
  InputLeftElement,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../../components/Spacer";
import { AdvancedNotes } from "./ElementComponents/AdvancedNotes";
import { useSelectionContext } from "../../context/SelectionContext";
import BeenhereOutlined from "@mui/icons-material/BeenhereOutlined";
import { FormElementTypes, SubmissionElementTypes, AttestationInstance, Form } from "../../types";
import "@fontsource/birthstone";
import _ from "lodash";
import { useMemo, useState } from "react";
import { ROLE_2, ROLE_1 } from "../../roles";
import { BuilderHeader, ResponseHeader } from "./ElementComponents/QuestionHeaders";

interface BuildViewProps {
  id: string;
  value: FormElementTypes.Attestation;
  titleNumber: string;
  indentationLevel: number;
  onChange: (e: any, field: string) => void;
}

const DefaultAttestation: AttestationInstance = {
  person: null,
  title: "",
  organization: "",
  signed: false,
  signedTimestamp: "",
  signature: "",
  date: "",
};

const Default = {
  type: "attestation",
  content: {
    audience: ROLE_1,
    title: "",
    question: {},
    attestationType: "eSignature",
    collectTitle: false,
    collectOrganization: false,
    requiredNumber: 1,
    attestations: [_.clone(DefaultAttestation)],
    surveyTakerNotes: {},
    adjudicatorNotes: {},
  },
};

const BuildView: React.FC<BuildViewProps> = ({ id, value, titleNumber, indentationLevel, onChange }) => {
  const { selectedElement } = useSelectionContext();
  const [attestationType, setAttestationType] = useState(value.attestationType);
  const [collectTitle, setCollectTitle] = useState(value.collectTitle);
  const [collectOrganization, setCollectOrganization] = useState(value.collectOrganization);
  const [requiredNumber, setRequiredNumber] = useState(value.requiredNumber);
  const isSelectedElement = selectedElement === id;

  const updateCollectTitle = () => {
    onChange(!collectTitle, "collectTitle");
    setCollectTitle(!collectTitle);
  };

  const updateCollectOrganization = () => {
    onChange(!collectOrganization, "collectOrganization");
    setCollectOrganization(!collectOrganization);
  };

  const updateRequiredNumber = (newRequiredNumber: any) => {
    const attestations = [];
    for (let i = 0; i < Number(newRequiredNumber); i++) {
      attestations.push(_.clone(DefaultAttestation));
    }

    onChange(attestations, "attestations");
    onChange(newRequiredNumber, "requiredNumber");

    setRequiredNumber(newRequiredNumber);
  };

  const attestationComponent = useMemo(
    () => (
      <>
        <BuilderHeader
          questionPrompt="Attestation Statement"
          title={value.title}
          titlePlaceholder="Untitled Attestation"
          titleNumber={titleNumber}
          indentationLevel={indentationLevel}
          audience={value.audience}
          required={requiredNumber > 0}
          onChange={onChange}
        />
        <RichText placeholder="Enter the attestation statement" onChange={(e) => onChange(e, "question")} value={value.question} />
        <Spacer y={SpacerSizes.sm} />
        <RequiredToggle value={true} onChange={() => {}} />
        <Text>Options</Text>
        <HStack>
          <VStack w={"50%"} alignItems={"flex-start"}>
            <HStack>
              <Switch colorScheme="brightblue" size="sm" isChecked={collectTitle} onChange={updateCollectTitle} />
              <Text>Collect Title</Text>
            </HStack>
            <HStack>
              <Switch colorScheme="brightblue" size="sm" isChecked={collectOrganization} onChange={updateCollectOrganization} />
              <Text>Collect Organization Name</Text>
            </HStack>
          </VStack>
        </HStack>
        <AdvancedSectionWrapper>
          <HStack gap={1} w={"100%"} divider={<StackDivider borderColor="gray.600" />}>
            <div style={{ width: "30%" }}>
              <Text># of Attestations to Collect</Text>
              <NumberInput onChange={updateRequiredNumber} size="sm" maxW={20} min={1} value={requiredNumber}>
                <NumberInputField value={requiredNumber} onChange={updateRequiredNumber} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </div>
            <div style={{ width: "70%" }}>
              <AdvancedNotes onChange={onChange} adjudicatorNotes={value.adjudicatorNotes} surveyTakerNotes={value.surveyTakerNotes} />
            </div>
          </HStack>
        </AdvancedSectionWrapper>
      </>
    ),
    [value, titleNumber, indentationLevel, value.title, attestationType, collectTitle, collectOrganization, requiredNumber, value.audience]
  );

  return isSelectedElement ? (
    attestationComponent
  ) : (
    <EditView value={value} titleNumber={titleNumber} id={id} onChange={onChange} indentationLevel={indentationLevel} role={ROLE_2} buildView={true} />
  );
};

const BuildViewDebouncedChanges: string[] = ["title", "question", "surveyTakerNotes", "adjudicatorNotes"];

interface EditViewProps {
  value: SubmissionElementTypes.Attestation;
  titleNumber: string;
  onChange: (e: any, field: string) => void;
  indentationLevel: number;
  role: string;
  id: string;
  buildView?: boolean;
}

const AttestationDate = ({
  index,
  attestation,
  roleMatchesAudience,
  updateAttestation,
}: {
  index: number;
  attestation: AttestationInstance;
  roleMatchesAudience: boolean;
  updateAttestation: (index: number, field: string, newValue: any) => void;
}) => {
  const [attestationDate, setAttestationDate] = useState(attestation.date ?? "");
  const date = new Date();

  const updateDate = () => {
    if (!roleMatchesAudience) return;
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;

    setAttestationDate(formattedDate);
    updateAttestation(index, "date", formattedDate);
  };

  return (
    <VStack mb={2}>
      <InputGroup>
        <InputLeftElement>
          <Icon as={BeenhereOutlined} color={"red.600"} />
        </InputLeftElement>
        <Input placeholder="Click to date" readOnly={true} onClick={updateDate} variant="flushed" style={{ cursor: "pointer" }} value={attestationDate} />
      </InputGroup>
    </VStack>
  );
};

const AttestationRow = ({
  index,
  attestation,
  updateAttestation,
  roleMatchesAudience,
  value,
}: {
  index: number;
  attestation: AttestationInstance;
  updateAttestation: (index: number, field: string, newValue: any) => void;
  roleMatchesAudience: boolean;
  value: SubmissionElementTypes.Attestation;
}) => {
  const [signature, setSignature] = useState(attestation.signature ?? "");
  return (
    <div
      key={index}
      style={{
        width: "75%",
      }}
    >
      {value.attestationType === "eSignature" ? (
        <HStack justifyContent="space-between">
          <Flex w={"70%"} mb={2} gap={0}>
            <InputGroup>
              <InputLeftElement>
                <Icon as={BeenhereOutlined} color={"red.600"} />
              </InputLeftElement>
              <Input
                readOnly={!roleMatchesAudience}
                placeholder="Signature"
                onChange={(e) => {
                  setSignature(e.target.value);
                  updateAttestation(index, "signature", e.target.value);
                }}
                variant="flushed"
                style={{ fontFamily: "birthstone", fontSize: 24 }}
                value={signature}
              />
            </InputGroup>
          </Flex>
          <AttestationDate index={index} attestation={attestation} roleMatchesAudience={roleMatchesAudience} updateAttestation={updateAttestation} />
        </HStack>
      ) : (
        <HStack alignItems={"center"} justifyContent={"space-between"}>
          <HStack mb={2}>
            <Icon as={BeenhereOutlined} color={"red.600"} />
            <Checkbox
              colorScheme="brightblue"
              defaultChecked={attestation.signed}
              onChange={(e) => {
                updateAttestation(index, "signed", e.target.checked);
              }}
            />
            <Text>I attest to the above statement.</Text>
          </HStack>
          <AttestationDate index={index} attestation={attestation} roleMatchesAudience={roleMatchesAudience} updateAttestation={updateAttestation} />
        </HStack>
      )}
      <HStack>
        {value.collectOrganization ? (
          <VStack gap={0} alignItems={"flex-start"}>
            <Text>Organization</Text>
            <Input
              _placeholder={{ textColor: "gray.600" }}
              placeholder={"Organization Name"}
              value={attestation.organization}
              onChange={(e) => updateAttestation(index, "organization", e.target.value)}
            />
          </VStack>
        ) : null}
        {value.collectTitle ? (
          <VStack gap={0} alignItems={"flex-start"}>
            <Text>Title</Text>
            <Input
              _placeholder={{ textColor: "gray.600" }}
              placeholder={"Your Title"}
              value={attestation.title}
              onChange={(e) => updateAttestation(index, "title", e.target.value)}
            />
          </VStack>
        ) : null}
      </HStack>
      <Spacer y={SpacerSizes.md} />
    </div>
  );
};

const EditView: React.FC<EditViewProps> = ({ value, titleNumber, onChange, indentationLevel, role, buildView = false }) => {
  const roleMatchesAudience = value.audience === "all" || value.audience === role;
  const [attestations, setAttestations] = useState<AttestationInstance[]>();

  useEffect(() => {
    setAttestations(value.attestations);
  }, []);

  const updateAttestation = (index: number, field: string, newValue: any) => {
    if (!roleMatchesAudience) return;
    const newAttestations = value.attestations.map((attestation, i) => {
      if (i === index) {
        return { ...attestation, [field]: newValue };
      }
      return attestation;
    });

    setAttestations(newAttestations);
    onChange(newAttestations, "attestations");
  };

  if (!roleMatchesAudience && role !== ROLE_2) {
    return null;
  }

  return (
    <div>
      <ResponseHeader
        title={value.title}
        indentationLevel={indentationLevel}
        titleNumber={titleNumber}
        required={value.requiredNumber > 0}
        role={role}
        adjudicatorNotes={value.adjudicatorNotes}
        surveyTakerNotes={value.surveyTakerNotes}
        audience={value.audience}
        buildView={buildView}
        questionPlaceholder="Enter the attestation statement"
        question={value.question}
      />
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {attestations?.map((attestation, index) => (
          <AttestationRow
            value={value}
            roleMatchesAudience={roleMatchesAudience}
            updateAttestation={updateAttestation}
            key={index}
            index={index}
            attestation={attestation}
          />
        ))}
      </div>
    </div>
  );
};

const EditViewDebouncedChanges: string[] = [];

export default { BuildView, EditView, Default, EditViewDebouncedChanges, BuildViewDebouncedChanges };
