import { Button, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { useAuthContext } from "../context/AuthContext";

export const NotFound = () => {
  const navigate = useNavigate();
  const { session } = useAuthContext();

  if (!session) {
    navigate("/login");
  }

  return (
    <div
      style={{
        paddingLeft: "25%",
        paddingRight: "25%",
        gap: "10px",
        marginTop: "5%",
      }}
    >
      <Heading>404</Heading>
      <Text>{`Sorry, we can't find the page you're looking for.`}</Text>
      <Spacer y={SpacerSizes.lg} />
      <Button onClick={() => navigate("/")}>Go home</Button>
    </div>
  );
};
