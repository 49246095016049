import { TopNav } from "./TopNav";
import { HideIfNoAuth } from "../../util/requireAuth";
import { useSelector } from "react-redux";
export const TOPNAV_Z_INDEX = 100;

import { Box, Center, HStack, VStack } from "@chakra-ui/react";
export const HEADER_HEIGHT = "43px";
export const Header = () => {
  // force rerender if sessionstorage changes
  useSelector(() => sessionStorage.getItem("token"));

  return (
    <VStack zIndex={TOPNAV_Z_INDEX} w="full" pos={"fixed"} h={HEADER_HEIGHT} spacing="0px" className="navbar">
      <HStack w="full" h="40px" bg="gray.900" pl="12px" pr="12px">
        <Center>
          <img style={{ width: 100, height: "auto" }} src="/Logo.svg" />
        </Center>
        <HideIfNoAuth>
          <HStack w="full">
            <TopNav />
          </HStack>
        </HideIfNoAuth>
      </HStack>
      <Box w="full" h="1px" bg="brand.900" />
      <Box w="full" h="2px" bg="gray.900" />
    </VStack>
  );
};
