import { ReactElement, useState, useEffect, useRef, useMemo } from "react";
import { Flex, Collapse, Icon, Divider } from "@chakra-ui/react";
import { Colors } from "../../../theme/colors";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { DocumentSectionToolbar } from "./DocumentSectionToolbar";
import React from "react";
import SelectStaticComponent from "./SelectStaticComponent";
import ElementConversations from "../../Comments/ElementConversations";
import ForumOutlined from "@mui/icons-material/ForumOutlined";
import { NewConversationButton } from "../../Comments/NewConversationButton";
import { CommentThread } from "../../../types";
import { useSelectionContext } from "../../../context/SelectionContext";
import { ICommentFunctions } from "../../../hooks/useCommentManager";

const COLLAPSE_ICON_Y = -25;
export const COMMENT_ICON_X = "810px";

export const DocumentSectionWrapper: React.FC<any> = ({
  formFunctions,
  commentFunctions,
  elementId,
  documentId,
  updateConversations,
  readOnly = false,
  children,
  userRoleOnDocument,
  isSection,
  save,
}: {
  formFunctions: any;
  commentFunctions: ICommentFunctions;
  elementId: string;
  documentId: string;
  updateConversations: (newCommentThreads: CommentThread[]) => void;
  readOnly?: boolean;
  children: ReactElement;
  userRoleOnDocument: string;
  isSection: boolean;
  save: () => void;
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [addSectionVisible, setAddSectionVisible] = useState(false);
  const [sectionOpen, setSectionOpen] = useState(true);
  const [isTopLevelNode, setIsTopLevelNode] = useState(false);
  const [isHovered, setIsHovered] = useState<any>();
  const { setSelectedElement, selectedElement } = useSelectionContext();
  const divRef = useRef(null);
  const filteredDictionary = commentFunctions.filteredThreadsDictionary;
  const filteredCommentThreads = filteredDictionary[elementId] ?? [];
  const commentThreads = commentFunctions.commentThreadsDictionary[elementId] ?? [];

  useEffect(() => {
    const path = [0];
    if (path.length < 2) {
      setIsTopLevelNode(true);
    }
  }, []);

  useEffect(() => {
    if (selectedElement !== elementId) setIsSelected(false);
    else setIsSelected(true);
  }, [selectedElement]);

  const handleMouseEnter = (e: any) => {
    const closestParent = (e.target as HTMLElement).closest(".element-wrapper");
    setIsHovered(closestParent === divRef.current);
    e.stopPropagation();
  };

  const handleClick = (event: MouseEvent) => {
    const closestParent = (event.target as HTMLElement).closest(".element-wrapper")?.id;
    if (closestParent) {
      setSelectedElement(closestParent);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", (event: MouseEvent) => {
      handleClick(event);
      setIsHovered(false);
    });

    document.addEventListener("mouseover", (event: MouseEvent) => {
      handleMouseEnter(event);
    });

    () => {
      document.removeEventListener("mousedown", (event: MouseEvent) => {
        handleClick(event);
      });

      document.removeEventListener("mouseover", (event: MouseEvent) => {
        handleMouseEnter(event);
      });
    };
  }, []);

  const addSectionBelow = async (newNode: Element) => {
    formFunctions.addElements({
      elements: [newNode],
      position: isSection ? "in" : "below",
      referenceElement: elementId,
    });
    save();
  };

  const documentSectionWrapper = useMemo(
    () => (
      <div
        id={elementId}
        ref={divRef}
        className="element-wrapper"
        style={{
          marginBottom: isSection ? 0 : 40,
          flexDirection: "column",
          alignItems: "flex-start",
          height: "fit-content",
        }}
      >
        {filteredCommentThreads && filteredCommentThreads.length !== 0 ? (
          <>
            <Icon
              style={{
                zIndex: 0,
                position: "absolute",
                left: COMMENT_ICON_X,
                padding: 2,
              }}
              as={ForumOutlined}
              boxSize={6}
              color={isSelected ? "brightblue.500" : Colors.gray[500]}
            />
            {isSelected ? (
              <div style={{ position: "absolute", left: 850, display: isSelected ? "flex" : "none" }}>
                <ElementConversations
                  commentThreads={commentThreads}
                  documentId={documentId}
                  isSelected={isSelected}
                  filteredCommentThreads={filteredCommentThreads}
                  updateConversation={(newCommentThread: CommentThread) => updateConversations([...commentThreads, newCommentThread])}
                  updateConversations={updateConversations}
                  formMembers={formFunctions.formMembers}
                  userRoleOnDocument={userRoleOnDocument}
                />
              </div>
            ) : null}
          </>
        ) : isSelected ? (
          <div
            style={{
              position: "absolute",
              left: COMMENT_ICON_X,
              ...(isSelected ? styles.selectedIcon : isHovered ? styles.hoveredIcon : styles.disabledIcon),
            }}
          >
            <NewConversationButton
              documentId={documentId}
              userRoleOnDocument={userRoleOnDocument}
              isSelected={isSelected}
              isHovered={isHovered}
              updateConversations={(newCommentThread: CommentThread) => updateConversations([...commentThreads, newCommentThread])}
              formMembers={formFunctions.formMembers}
            />
          </div>
        ) : null}
        <Flex
          direction={"column"}
          style={{
            justifyContent: "flex-start",
            height: "fit-content",
            paddingLeft: 5,
            ...(isSelected ? styles.border : isHovered ? styles.hoveredBorder : styles.disabledBorder),
          }}
        >
          <Divider
            style={{
              border: isSelected ? `1px solid ${Colors.brightblue[500]}` : "0px solid transparent",
              opacity: 1,
              transition: "border 0.3s ease-in-out",
              left: readOnly ? COLLAPSE_ICON_Y : COLLAPSE_ICON_Y * 2,
              position: "absolute",
              marginTop: -2,
              width: "130px",
            }}
          />

          {readOnly ? null : (
            <DocumentSectionToolbar
              formFunctions={formFunctions}
              id={elementId}
              setAddSectionVisible={setAddSectionVisible}
              addSectionVisible={addSectionVisible}
              isSelected={isSelected}
              save={save}
            />
          )}

          {isTopLevelNode ? (
            <div
              style={{
                display: "flex",
                position: "absolute",
                left: COLLAPSE_ICON_Y,
                marginTop: -7,
                ...(isSelected ? styles.selectedIconBox : isHovered ? styles.hoveredIconBox : styles.disabledIconBox),
              }}
            >
              <Icon
                onClick={() => setSectionOpen(!sectionOpen)}
                style={{
                  cursor: "pointer",
                  ...(isSelected ? styles.selectedIcon : isHovered ? styles.hoveredIcon : styles.disabledIcon),
                }}
                color={isSelected ? Colors.brightblue[500] : Colors.gray[200]}
                as={sectionOpen ? RemoveIcon : AddIcon}
                boxSize={4}
              />
            </div>
          ) : null}

          <Collapse style={{ width: "100%", height: "fit-content" }} startingHeight={30} in={sectionOpen} animateOpacity>
            {children}
          </Collapse>

          <Flex
            style={{
              display: addSectionVisible ? "block" : "none",
              padding: 15,
            }}
          >
            <Collapse in={addSectionVisible} animateOpacity>
              <SelectStaticComponent
                onSelect={(newNode: Element) => {
                  setAddSectionVisible(false);
                  addSectionBelow(newNode);
                }}
              />
            </Collapse>
          </Flex>
        </Flex>
      </div>
    ),
    [children, documentId, divRef, elementId, isHovered, isSelected, isTopLevelNode, readOnly, sectionOpen, addSectionVisible]
  );

  return documentSectionWrapper;
};

const styles = {
  border: {
    borderTop: `2px solid ${Colors.brightblue[500]}`,
    borderLeft: `2px solid ${Colors.brightblue[500]}`,
    borderBottom: `2px solid ${Colors.brightblue[500]}`,
    borderRight: "2px solid transparent",
    transition: `border 0.3s ease-in-out`,
  },
  hoveredBorder: {
    borderTop: `2px solid ${Colors.gray[200]}`,
    borderLeft: `2px solid ${Colors.gray[200]}`,
    borderBottom: `2px solid ${Colors.gray[200]}`,
    borderRight: `2px solid transparent`,
    transition: `border 0.3s ease-in-out 1s`,
  },
  disabledBorder: {
    transition: `border 0.3s ease-in-out`,
    border: "2px solid transparent",
  },
  disabledIconBox: {
    border: "transparent",
    backgroundColor: "transparent",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out, background-color 0.3s ease-in-out",
  },
  hoveredIconBox: {
    border: `2px solid ${Colors.gray[200]}`,
    backgroundColor: "white",
    marginTop: -7,
    opacity: 1,
    transition: "opacity 0.3s ease-in-out 1s, background-color 0.3s ease-in-out 1s",
  },
  selectedIconBox: {
    border: `2px solid ${Colors.brightblue[500]}`,
    backgroundColor: "white",
    opacity: 1,
  },
  selectedIcon: {
    opacity: 1,
  },
  hoveredIcon: {
    opacity: 1,
    transition: "border 0.3s ease-in-out 1s, opacity 0.3s ease-in-out 1s",
  },
  disabledIcon: {
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },
};
