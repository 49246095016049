import { useState, useRef, useEffect } from "react";
import { Input } from "@chakra-ui/react";

export const AutoWidthInput = ({ initialValue, onChange }: { initialValue?: string; onChange: (e: any) => void }) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const spanRef = useRef<any>();
  const inputRef = useRef<any>();

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      const spanWidth = spanRef.current.offsetWidth;
      inputRef.current.style.width = `${spanWidth + 2}px`;
    }
  }, [inputValue]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Input
        ref={inputRef}
        value={inputValue}
        onChange={handleInputChange}
        style={{ minWidth: "40px" }}
        variant="unstyled"
        color="white"
        placeholder="Untitled Form"
      />
      <span
        ref={spanRef}
        style={{
          position: "absolute",
          visibility: "hidden",
          height: "0",
          whiteSpace: "pre",
        }}
      >
        {inputValue || "Untitled Form"}
      </span>
    </div>
  );
};

export default AutoWidthInput;
