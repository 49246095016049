export interface FormElement {
  id?: string;
  type: string;
  content: any;
  children?: FormElement[];
  pathIndex?: PathIndex;
  titleNumber?: string;
  rules?: any;
  isVisibleByRules?: boolean;
  audience: string;
}

export interface FormMeta {
  title: string;
  description: string;
  version: number;
  publishedVersion: number;
  privileged: boolean;
  createdAt: string;
  createdByUserId: string;
  organizationId: string;
  privilegeWarningDismissed: boolean;
  archived: boolean;
  updatedAt: string;
}

export type MembershipType = "member" | "guest";

export interface FormAvailability {
  discoverable: boolean;
  audience: MembershipType[];
}

/* represents what's stored inthe database - not sure we should use this in the frontend */
export interface Form {
  _id?: string;
  meta: FormMeta;
  elements: FormElement[];
  commentThreadsDictionary: CommentThreadDictionary;
  users: FormUser[];
  availability: FormAvailability;
}

export interface OtherUserInfo {
  userId: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  initials?: string;
  title?: string;
  pronouns?: string;
  otherContactInformation?: string;
  accountStatus?: AccountStatus;
}

export interface FormEnrichment {
  actions: FormActions;
  creator: OtherUserInfo;
  attentionItems: AttentionItems;
  usersInfo: OtherUserInfo[];
}

/* what's returned by the GET /forms/:id endpoint */
export interface EnrichedForm extends Form {
  readOnly: FormEnrichment;
}

/* an array of these is what is returned by the GET /forms endpoint */
export interface EnrichedFormContentless {
  _id: string;
  meta: FormMeta;
  availability: FormAvailability;
  readOnly: FormEnrichment;
  users: FormUser[];
}

export interface Comment {
  user: string;
  userEmail: string;
  content: any;
  mentioned?: string[];
  assignedTo?: string[];
  createdAt: string;
  pending: boolean;
}

export interface CommentThread {
  _id?: string;
  resolved: boolean;
  type: string;
  comments: Comment[];
}

export interface CommentThreadDictionary {
  [key: string]: CommentThread[];
}

export enum RuleOperator {
  IsEqualTo = "isEqualTo",
  IsNotEqualTo = "isNotEqualTo",
}

export interface Rule {
  firstOperandId: string;
  operator: RuleOperator;
  secondOperandId: string;
  continuation?: "AND" | "OR";
}

export type SubmissionUserRole = "adjudicator" | "submitter";

export interface SubmissionUser {
  userId: string;
  isApproved: boolean;
  role: SubmissionUserRole;
}

export interface SubmissionElement {
  id?: string;
  type: string;
  content: any;
  audience: string;
  children?: SubmissionElement[];
  pathIndex?: PathIndex;
  titleNumber?: string;
  isVisibleByRules?: boolean;
  rules?: Rule[];
}

export interface SubmissionActions {
  assignSubmitters: boolean;
  assignAdjudicators: boolean;
  open: boolean;
  finalize: boolean;
  privilegeApprovals: boolean;
  delete: boolean;
}

export interface SubmissionMeta {
  title: string;
  version: number;
  publishedVersion: number;
  privileged: boolean;
  createdAt: string;
  createdByUserId: string;
  createdFromFormId: string;
  organizationId: string;
  updatedAt: string;
  privilegeWarningdismissed: boolean;
  finalized: boolean;
}

/* represents what's stored inthe database - not sure we should use this in the frontend */
export interface Submission {
  _id: string;
  meta: SubmissionMeta;
  users: SubmissionUser[];
  elements: SubmissionElement[];
  commentThreadsDictionary?: any;
}

export interface SubmissionEnrichment {
  actions: SubmissionActions;
  creator: OtherUserInfo;
  attentionItems: AttentionItems;
  formInfo: {
    _id: string;
    name: string;
    description: string;
  };
  usersInfo: OtherUserInfo[];
}

/* what's returned by the GET /forms/:id endpoint */
export interface EnrichedSubmission extends Submission {
  readOnly: SubmissionEnrichment;
}

/* an array of these is what is returned by the GET /forms endpoint */
export interface EnrichedSubmissionContentless {
  _id: string;
  meta: SubmissionMeta;
  readOnly: SubmissionEnrichment;
  users: FormUser[];
}

export type PathIndex = number[];

export enum Position {
  Above = "above",
  Below = "below",
  In = "in",
}

export interface ElementViewProps {
  id: string;
  content: any;
  onChange: any;
  level?: number;
  titleNumber?: string;
  children?: JSX.Element[];
}

export interface SelectItem {
  label: string;
  selected: boolean;
  allowed: boolean;
  other?: boolean;
  none?: boolean;
}

export interface AttestationInstance {
  person: any;
  title: string;
  organization: string;
  signed: boolean;
  signedTimestamp: string;
  signature: string;
  date: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export module FormElementTypes {
  export interface Section {
    title: string;
  }

  export interface TextBlock {
    value: any;
    audience: string;
  }

  export interface TextResponse {
    title: string;
    textType: string;
    surveyTakerNotes: any;
    adjudicatorNotes: any;
    question: any;
    required: boolean;
    lengthLimit: number;
    audience: string;
    response: any;
  }

  export interface SingleSelect {
    title: string;
    surveyTakerNotes: any;
    adjudicatorNotes: any;
    question: any;
    required: boolean;
    items: SelectItem[];
    audience: string;
  }

  export interface MultiSelect {
    title: string;
    surveyTakerNotes: any;
    adjudicatorNotes: any;
    question: any;
    required: boolean;
    items: SelectItem[];
    audience: string;
    other: {
      allowed: boolean;
      label: string;
      selected: boolean;
    };
    none: {
      allowed: boolean;
      label: string;
      selected: boolean;
    };
    all: {
      allowed: boolean;
      label: string;
      selected: boolean;
    };
  }

  export interface Attestation {
    title: string;
    question: string;
    attestationType: "checkbox" | "eSignature";
    collectTitle: boolean;
    collectOrganization: boolean;
    requiredNumber: number;
    attestations: AttestationInstance[];
    surveyTakerNotes: any;
    adjudicatorNotes: any;
    audience: string;
  }

  export interface FileUpload {
    title: string;
    surveyTakerNotes: any;
    audience: string;
    adjudicatorNotes: any;
    question: any;
    required: boolean;
    allowMultiple: boolean;
    restrictDeletion: boolean;
    uploadedFiles: any[];
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export module SubmissionElementTypes {
  export interface Section {
    title: string;
  }

  export interface TextBlock {
    value: any;
    audience: string;
  }

  export interface TextResponse {
    title: string;
    textType: string;
    surveyTakerNotes: any;
    adjudicatorNotes: any;
    question: any;
    required: boolean;
    lengthLimit: number;
    response: any;
    audience: string;
  }

  export interface SingleSelect {
    title: string;
    surveyTakerNotes: any;
    adjudicatorNotes: any;
    question: any;
    required: boolean;
    items: SelectItem[];
    audience: string;
  }

  export interface Attestation {
    title: string;
    question: string;
    attestationType: "checkbox" | "eSignature";
    collectTitle: boolean;
    collectOrganization: boolean;
    requiredNumber: number;
    attestations: AttestationInstance[];
    surveyTakerNotes: any;
    adjudicatorNotes: any;
    audience: string;
  }
}

export type AccountStatus = "pending" | "active" | "disabled";
export interface User {
  _id: string;
  supabaseId: string;
  email: string;
  firstName?: string;
  lastName?: string;
  initials?: string;
  title?: string;
  pronouns?: string | null;
  otherContactInformation?: string;
  accountStatus: AccountStatus;
}

export interface MentionableUser {
  key: string;
  text: string;
  data: {
    email: string;
  };
}

export interface OrganizationMembership {
  // todo add org nicknames

  _id?: string;
  userId?: string;
  organizationId?: string;
  membershipType: MembershipType;
  membershipExpiration?: string | null; // YYYY-mm-ddTHH:MM:ssZ or maybe Date()
  permissions: OrganizationPermissions;

  /* this list is useful for determining what document content a user should
     be able to see */
  /* we may take a different approach
  groupMemberships: string[];*/

  /* this list is useful for presenting sharing optins to the user */
  /* we may take a different approach
  visibleGroups: string[];*/

  /* this list is useful for displaying group editing functionality to a user.
        the sepecifics of what is editable is defined in another object */
  /* we may take a different approach
  manageableGroups: string[]; */

  /* nickname should only be visible to the user w/userID. It is also the
     only field that the user can edit if they have no management ability */
  nickname?: string;

  name?: string;

  /* as a convenience on read */
  organization?: Organization;
}

export interface OrganizationPermissions {
  manageOrgPermissions: boolean;
  manageOrgSettings: boolean;
  inviteUser: boolean;
  manageOrgTags: boolean;
  manageLegalPrivilege: boolean;
  manageAllGroups: boolean;
  seeAllGroups: boolean;
  createOrCopyForms: boolean;
  createGroups: boolean;
  seeAllMembers: boolean;
  seeAllGuests: boolean;
}

export interface Organization {
  _id: string;
  name: string;
  description: string;
  privilegeSettings: OrganizationPrivilegeSettings;
  commentInvitationMemberPermissions: string[];
  commentInvitationGuestPermissions: string[];
  libraryShelf: string[];
  groups: string[];
}

export interface OrganizationPrivilegeSettings {
  privilegeTerms: string;
  privilegeAgreement: string;
  privilegeBanner: string;
}

export interface UserQuery {
  user: User;
  currentOrgMembership: OrganizationMembership;
  orgMemberships: OrganizationMembership[];
}

export interface MembershipDetail extends OrganizationMembership {
  user: User;
}

export type FormUserRole = "owner" | "editor" | "submitter";

export interface FormUser {
  userId: string;
  isApproved: boolean;
  role: string;
}

/* the below are used for sharing and should be used for assignment, but are not yet */
export interface AttentionItems {
  privilegeApprovalsNeeded: boolean;
}

export interface FormActions {
  share: boolean;
  comment: boolean;
  edit: boolean;
  submit: boolean;
  copy: boolean;
  archive: boolean;
  privilegeApprovals: boolean;
  becomeAdjudicator: boolean;
}

export interface FormInfoForSharing {
  readOnly: {
    actions: FormActions;
    creator: User;
    attentionItems: AttentionItems;
    usersInfo: OtherUserInfo[];
  };
}
