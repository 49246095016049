import { ROLE_2, ROLE_1 } from "../../../roles";
import { Spacer, SpacerSizes } from "../../../components/Spacer";
import { SurveyTakerNotes } from "./SurveyTakerNotes";

export const SubmissionAdmonition = ({ submitterNotes, adjudicatorNotes, role }: { submitterNotes: string; adjudicatorNotes: string; role: string }) => {
  function noteValid(note: any) {
    if (note && note[0] && note[0].children && note[0].children[0] && note[0].children[0].text && note[0].children[0].text.length > 0) {
      return true;
    }
    return false;
  }
  return (
    <div>
      {noteValid(adjudicatorNotes) && role === ROLE_2 ? (
        <div style={{ marginLeft: 12 }}>
          <SurveyTakerNotes text={adjudicatorNotes} />
          <Spacer y={SpacerSizes.xs} />
        </div>
      ) : null}

      {noteValid(submitterNotes) && role === ROLE_1 ? (
        <div style={{ marginLeft: 12 }}>
          <SurveyTakerNotes text={submitterNotes} />
          <Spacer y={SpacerSizes.xs} />
        </div>
      ) : null}
    </div>
  );
};
