import { useEffect, useState } from "react";
import {
  Button,
  Heading,
  HStack,
  Input,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  TableContainer,
  Table,
  Tbody,
  Thead,
  Td,
  Th,
  Tr,
  Text,
  Textarea,
  Tooltip,
  Circle,
} from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { useTopNavContext } from "../context/TopNavContext";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { OrganizationMembership, UserQuery } from "../types";
import { LeaveOrganizatoinModal } from "../components/Modal/LeaveOrganizationModal";
import { PageInfo } from "../components/TopNavBar/PageInfo";
import { EmailAddressChangeModal } from "../components/Modal/EmailAddressChangeModal";
import _ from "lodash";
import store from "../store";
import { setGlobalUserInfo } from "../actions/authActions";
import { useUserAPI } from "../endpoints/_index";

const debouncedUpdate = _.debounce(async (newValue, updateUser, getUser) => {
  await updateUser(newValue);
  const qr: any = await getUser();
  store.dispatch(setGlobalUserInfo(qr! as UserQuery));
}, 5000);

export default function UserProfile() {
  const { setPageInfo } = useTopNavContext();
  const { getUser, updateUser } = useUserAPI();
  const [userInfo, setUserInfo] = useState<UserQuery>();

  const getUserInfo = async () => {
    const qr: any = await getUser();
    if (!qr.user.initials) {
      if (qr.user.firstName && qr.user.lastName) {
        qr.user.initials = qr.user.firstName[0] + qr.user.lastName[0];
      } else if (qr.user.lastName) {
        qr.user.initials = qr.user.lastName[0];
      } else if (qr.user.firstName) {
        qr.user.initials = qr.user.firstName[0];
      } else {
        qr.user.initials = qr.user.email.split("@")[0][0] + qr.user.email.split("@")[1][0];
      }
      debouncedUpdate(userInfo, updateUser, getUser);
    }
    setUserInfo(qr as UserQuery);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  // Update the Page Info block in the navbar
  useEffect(() => {
    setPageInfo(<PageInfo title="User Profile" />);
  }, []);

  function setFirstName(event: any) {
    if (!userInfo) return;
    setUserInfo({
      ...userInfo,
      user: {
        ...userInfo.user,
        firstName: event.target.value,
      },
    });
  }

  function setLastName(event: any) {
    if (!userInfo) return;
    const newUserInfo = {
      ...userInfo,
      user: {
        ...userInfo.user,
        lastName: event.target.value,
      },
    };
    setUserInfo(newUserInfo);
  }

  function updateInitials(event: any) {
    if (!userInfo) return;
    const newUserInfo = {
      ...userInfo,
      user: {
        ...userInfo.user,
        initials: event.target.value,
      },
    };
    setUserInfo(newUserInfo);
  }

  function getRenderedPronouns() {
    if (!userInfo?.user.pronouns) {
      return "-";
    } else {
      return userInfo?.user.pronouns;
    }
  }

  function setPronouns(newValue: string) {
    if (!userInfo) return;
    if (newValue === "-") {
      setUserInfo({
        ...userInfo,
        user: {
          ...userInfo.user,
          pronouns: null,
        },
      });
    } else {
      setUserInfo({
        ...userInfo,
        user: {
          ...userInfo.user,
          pronouns: newValue,
        },
      });
    }
  }

  function setTitle(event: any) {
    if (!userInfo) return;
    setUserInfo({
      ...userInfo,
      user: {
        ...userInfo.user,
        title: event.target.value,
      },
    });
  }

  function setOtherContactInformation(event: any) {
    if (!userInfo) return;
    setUserInfo({
      ...userInfo,
      user: {
        ...userInfo.user,
        otherContactInformation: event.target.value,
      },
    });
  }

  useEffect(() => {
    if (!userInfo) return;
    debouncedUpdate(userInfo, updateUser, getUser);
  }, [userInfo]);

  if (!userInfo) return <div>Loading...</div>;

  const AccountSettings = () => {
    return (
      <>
        <Heading variant="h2">Account Settings</Heading>
        <Spacer y={SpacerSizes.lg} />

        {/* Avatar (initials) Circle */}
        <Grid templateRows="repeat(8, 1fr)" templateColumns="repeat(5, 1fr)" gap="3" alignItems="center">
          <GridItem rowSpan={10} colSpan={1} alignSelf="start">
            <HStack>
              <Circle size="84px" bg="brightblue.200" marginLeft="auto" mt="10px">
                <Text fontWeight="bold">{userInfo?.user?.initials}</Text>
              </Circle>
            </HStack>
          </GridItem>

          {/* Row for First Name Entry */}
          <GridItem colSpan={1} rowSpan={1}>
            <Text align="right">First Name</Text>
          </GridItem>
          <GridItem colSpan={3} rowSpan={1}>
            <Input placeholder="First Name" size="md" value={userInfo?.user?.firstName} onChange={setFirstName}></Input>
          </GridItem>

          {/* Row for Last Name Entry */}
          <GridItem colSpan={1} rowSpan={1}>
            <Text align="right">Last Name</Text>
          </GridItem>
          <GridItem colSpan={3} rowSpan={1}>
            <Input placeholder="Last Name" size="md" value={userInfo?.user.lastName} onChange={setLastName}></Input>
          </GridItem>

          {/* Row for Initials Entry */}
          <GridItem colSpan={1} rowSpan={1}>
            <Text align="right">Initials</Text>
          </GridItem>
          <GridItem colSpan={1} rowSpan={1}>
            <Tooltip label="Three character maximum." fontSize="md">
              <Input placeholder="Initials" size="md" value={userInfo.user.initials} onChange={updateInitials}></Input>
            </Tooltip>
          </GridItem>
          <GridItem colSpan={2} rowSpan={1}></GridItem>

          {/* Row for Pronouns Entry */}
          <GridItem colSpan={1} rowSpan={1}>
            <Text align="right">Pronouns</Text>
          </GridItem>
          <GridItem colSpan={1} rowSpan={1}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ExpandMoreOutlinedIcon />}
                variant="gray_dropdown"
                value={userInfo?.user.pronouns ? userInfo.user.pronouns : ""}
                justifyItems={"flex-start"}
              >
                {getRenderedPronouns()}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setPronouns("-")}>-</MenuItem>
                <MenuItem onClick={() => setPronouns("She/Her")}>She/Her</MenuItem>
                <MenuItem onClick={() => setPronouns("He/Him")}>He/Him</MenuItem>
                <MenuItem onClick={() => setPronouns("They/Them")}>They/Them</MenuItem>
              </MenuList>
            </Menu>
          </GridItem>
          <GridItem colSpan={2} rowSpan={1}></GridItem>

          {/* Row for Initials Entry */}
          <GridItem colSpan={1} rowSpan={1}>
            <Text align="right">Title</Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={3}>
            <Input placeholder="Title" size="md" value={userInfo?.user?.title} onChange={setTitle}></Input>
          </GridItem>

          {/* Row for Email Address Entry */}
          <GridItem colSpan={1} rowSpan={1}>
            <Text align="right">Email Address</Text>
          </GridItem>
          <GridItem colSpan={2} rowSpan={1}>
            <Tooltip
              label="Email Address changes require assistance from Luminos.AI support and may cause you to lose access to certain organizations."
              fontSize="md"
            >
              <Input placeholder="Email Address" size="md" isDisabled={true} value={userInfo?.user?.email}></Input>
            </Tooltip>
          </GridItem>
          <GridItem colSpan={1} rowSpan={1}>
            <EmailAddressChangeModal userInfo={userInfo.user} />
          </GridItem>

          {/* Row for User ID Display */}
          <GridItem colSpan={1} rowSpan={1}>
            <Tooltip label="Your User ID is not editable, but may be useful should you need to communicate with Luminos.AI support." fontSize="md">
              <Text align="right">User ID</Text>
            </Tooltip>
          </GridItem>
          <GridItem colSpan={3} rowSpan={1}>
            <Text color="gray.500">{userInfo?.user._id}</Text>
          </GridItem>

          {/* TODO allow for rich text here so that we can support hyperlinks */}
          {/* Row for Other Contact Information */}
          <GridItem colSpan={1} rowSpan={1}>
            <Text align="right">Other Contact Information</Text>
          </GridItem>
          <GridItem colSpan={3} rowSpan={3}>
            <Textarea onChange={setOtherContactInformation} value={userInfo.user.otherContactInformation} />
          </GridItem>
        </Grid>
      </>
    );
  };

  const OrganizationMembership = () => {
    return (
      <>
        <Heading variant="h2">Organization Memberships</Heading>
        <Spacer y={SpacerSizes.md} />

        {/* Header Row */}
        <TableContainer>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Role</Th>
                <Th>Membership</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {userInfo?.orgMemberships.map((organization: OrganizationMembership, index: number) => (
                <Tr key={index}>
                  <Td>
                    <Text>{organization.name}</Text>
                  </Td>

                  <Td>
                    <Text>{/* organization.branding.privilegeText */}</Text>
                  </Td>

                  <Td>
                    <Text>{organization.membershipType}</Text>
                  </Td>
                  <Td>
                    <LeaveOrganizatoinModal organizationMembership={userInfo.orgMemberships[index]}></LeaveOrganizatoinModal>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <div style={{ justifyContent: "center", width: "100%", display: "flex" }}>
      <div
        style={{
          paddingLeft: "2.5%",
          maxWidth: "900px",
          marginTop: "4%",
          marginBottom: "4%",
        }}
      >
        {AccountSettings()}
        <Spacer y={SpacerSizes.xl} />
        {OrganizationMembership()}
      </div>
    </div>
  );
}
