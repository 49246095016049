// https://ui.dev/react-router-protected-routes-authentication
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

export function RequireAuth({ children }: { children: ReactElement }) {
  const session = sessionStorage.getItem("token");
  return session ? children : <Navigate to="/login" replace />;
}

export function HideIfNoAuth({ children }: { children: ReactElement }) {
  const session = sessionStorage.getItem("token");
  return session ? children : <></>;
}
