import React, { useState, useMemo } from "react";
import RichText from "./ElementComponents/RichText";
import { SubmissionElementTypes, FormElementTypes } from "../../types";
import { useSelectionContext } from "../../context/SelectionContext";
import { AudienceSelection, AudienceDisplay } from "./ElementComponents/AudienceSelection";
import { ROLE_1, ROLE_2 } from "../../roles";

interface BuildViewProps {
  value: FormElementTypes.TextBlock;
  id: string;
  onChange: (e: any, field: string) => void;
}

const Default = {
  type: "textBlock",
  content: {
    audience: ROLE_1,
    type: "p",
    children: [{ text: "" }],
  },
};

const BuildView: React.FC<BuildViewProps> = ({ value, id, onChange }) => {
  const { selectedElement } = useSelectionContext();
  const [audience, setAudience] = useState(value.audience);

  const updateAudience = (newAudience: string) => {
    setAudience(newAudience);
    onChange(newAudience, "audience");
  };

  const textBlockBuildView = useMemo(
    () => (
      <div style={{ paddingTop: 5, paddingBottom: 5 }}>
        <div style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}>
          <AudienceSelection audience={audience} changeAudience={(x) => updateAudience(x)} staticContent={true} />
        </div>
        <RichText selected={selectedElement === id} value={value.value} onChange={(e) => onChange(e, "value")} />
      </div>
    ),
    [id, audience, value]
  );

  return selectedElement === id ? textBlockBuildView : <EditView value={value} role={ROLE_2} buildView={true} />;
};

const BuildViewDebouncedChanges: string[] = ["title", "value", "surveyTakerNotes", "adjudicatorNotes"];

interface EditViewProps {
  value: SubmissionElementTypes.TextBlock;
  role: string;
  buildView?: boolean;
}

const EditView: React.FC<EditViewProps> = ({ value, role, buildView = false }) => {
  const roleMatchesAudience = value.audience === "all" || value.audience === role;

  function displayParagraph() {
    if (
      buildView ||
      (value.value &&
        value.value[0] &&
        value.value[0].children &&
        value.value[0].children[0] &&
        value.value[0].children[0].text &&
        value.value[0].children[0].text.length > 0)
    ) {
      return true;
    }
    return false;
  }

  if (!roleMatchesAudience && role !== ROLE_2) {
    return null;
  }
  return (
    <>
      <div style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}>
        <AudienceDisplay audience={value.audience} buildView={buildView} staticContent={true} />
      </div>
      {displayParagraph() ? <RichText value={value.value} readOnly={true} borderColor="border-none" showToolbar={false} /> : null}
    </>
  );
};

const EditViewDebouncedChanges: string[] = [];

export default { BuildView, EditView, Default, EditViewDebouncedChanges, BuildViewDebouncedChanges };
