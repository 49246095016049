import { useContext, createContext, useState } from "react";

type SelectionContextType = {
  selectedElement: string | null;
  setSelectedElement: (element: any) => void;
};

const SelectionContext = createContext<SelectionContextType | undefined>(undefined);

export const useSelectionContext = (): SelectionContextType => {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error("useSelectionContext must be used within SelectionContextProvider");
  }
  return context;
};

export const SelectionContextProvider = ({ children, ...props }: { children: React.ReactNode }) => {
  const [selectedElement, setSelectedElement] = useState<string | null>(null);

  return (
    <SelectionContext.Provider value={{ selectedElement, setSelectedElement }} {...props}>
      {children}
    </SelectionContext.Provider>
  );
};
