import { useContext, createContext, useEffect, useState } from "react";
import { createClient, AuthSession } from "@supabase/supabase-js";
import { UserQuery } from "../types";
import { setGlobalUserInfo, setOrganizationId } from "../actions/authActions";
import store from "../store";
import { useSelector } from "react-redux";
import { SupabaseClient } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";
import { setMentionableOrganizationMembers } from "../actions/orgActions";
import { convertUserToMentionableUser } from "../util/convertUserToMentionableUser";

interface IAuthContext {
  session: AuthSession | undefined;
  supabase: SupabaseClient;
  logout: () => void;
}

export const supabase = createClient(process.env.REACT_APP_SB_URL!, process.env.REACT_APP_SB_PUBLIC_KEY!);

const AuthContext = createContext<IAuthContext>({
  session: undefined,
  supabase,
  logout: () => {},
});

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthContextProvider({ children, ...props }: { children: React.ReactNode }) {
  const [session, setSession] = useState<AuthSession>();
  const { organizationId } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (!session) return;
      setSession(session!);
      sessionStorage.setItem("token", session!.access_token!);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session!);

      if (session && session.access_token) {
        sessionStorage.setItem("token", session.access_token);
      } else if (!session) {
        sessionStorage.removeItem("token");
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const setUserOrganization = async (token: string) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL!}/user`, {
      headers,
    });
    const data = await response.json();

    store.dispatch(setGlobalUserInfo(data as UserQuery));
    store.dispatch(setOrganizationId(data.currentOrgMembership.organization._id));
  };

  const setMembers = async (token: string) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const res = await fetch(`${process.env.REACT_APP_API_URL!}/organization/membershipsDetail`, { headers });
    const members = await res.json();
    const mentionableMembers = members.filter((member: any) => member.user)?.map(convertUserToMentionableUser);
    store.dispatch(setMentionableOrganizationMembers(mentionableMembers));
  };

  useEffect(() => {
    const token = session ? session.access_token : null;

    sessionStorage.setItem("token", token!);

    if (token && organizationId === "") {
      setUserOrganization(token!);
      setMembers(token!);
    }
  }, [session]);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error("Error logging out:", error.message);
    sessionStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        supabase,
        logout: handleLogout,
      }}
      {...props}
    >
      {children}
    </AuthContext.Provider>
  );
}
