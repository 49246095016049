import React from "react";
import { Flex, Icon, Tooltip } from "@chakra-ui/react";
import { Colors } from "../../../theme/colors";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { MdMoveUp } from "react-icons/md";
import { MdMoveDown } from "react-icons/md";
import { MdFormatIndentIncrease } from "react-icons/md";
import { MdFormatIndentDecrease } from "react-icons/md";
import { FiTrash2 } from "react-icons/fi";

export const DocumentSectionToolbar = ({
  addSectionVisible,
  setAddSectionVisible,
  formFunctions,
  id,
  isSelected,
  save,
}: {
  addSectionVisible: boolean;
  setAddSectionVisible: (x: boolean) => void;
  formFunctions: any;
  id: string;
  isSelected: boolean;
  save: () => void;
}) => {
  const currentPath = formFunctions.getElementPathIndexes([id])[0];
  const deleteItem = () => {
    formFunctions.removeElements({ elementIds: [id] });
    save();
  };

  const moveUp = () => {
    formFunctions.swapElement({ elementId: id, position: "above" });
    save();
  };

  const indent = () => {
    formFunctions.indentElement({ elementIdOrPath: id });
    save();
  };

  const dedent = () => {
    formFunctions.dedentElement({ elementIdOrPath: id });
    save();
  };

  const moveDown = () => {
    formFunctions.swapElement({ elementId: id, position: "below" });
    save();
  };

  const canBeDeleted = () => {
    const indentationLevel = formFunctions.getIndentationLevel(id);
    const siblingAbove = formFunctions.getSiblingAbove(currentPath);
    const siblingBelow = formFunctions.getSiblingBelow(currentPath);

    return indentationLevel > 1 || siblingAbove !== null || siblingBelow !== null;
  };

  const buttons = [
    {
      icon: MdMoveUp,
      ariaLabel: "move up",
      onClick: () => moveUp(),
      visible: formFunctions.getSiblingAbove(currentPath) !== null,
      tooltipText: "Move up",
    },
    {
      icon: MdFormatIndentIncrease,
      ariaLabel: "tab in",
      onClick: () => indent(),
      visible: formFunctions.getIndentationLevel(id) < 4 && formFunctions.getSiblingAbove(currentPath) !== null,
      tooltipText: "Tab in",
    },
    {
      icon: FiTrash2,
      ariaLabel: "delete",
      onClick: () => deleteItem(),
      visible: canBeDeleted(),
      tooltipText: "Delete",
    },
    {
      icon: MdFormatIndentDecrease,
      ariaLabel: "tab out",
      onClick: () => dedent(),
      visible: formFunctions.getIndentationLevel(id) > 1,
      tooltipText: "Tab out",
    },
    {
      icon: MdMoveDown,
      ariaLabel: "move down",
      onClick: () => moveDown(),
      visible: formFunctions.getSiblingBelow(currentPath) !== null,
      tooltipText: "Move down",
    },
    {
      icon: MdOutlineAddCircleOutline,
      ariaLabel: "add section below",
      onClick: () => {
        setAddSectionVisible(!addSectionVisible);
      },
      visible: true,
      tooltipText: "Add element within section",
    },
  ];

  return (
    <Flex
      direction="column"
      gap={1}
      p={1}
      style={{
        marginTop: -2,
        borderColor: Colors.brightblue[500],
        backgroundColor: "white",
        borderWidth: "2px",
        position: "absolute",
        width: "fit-content",
        left: -62,
        opacity: isSelected ? 1 : 0,
        transition: "opacity 0.3s ease-in-out",
        pointerEvents: isSelected ? "auto" : "none",
      }}
    >
      {buttons.map((button) => {
        if (!button.visible) return null;
        return (
          <Tooltip key={`${button.ariaLabel}-${id}`} position="relative" placement="bottom-start" label={button.tooltipText}>
            <span>
              <Icon
                _hover={{ cursor: "pointer" }}
                boxSize={5}
                onClick={(event) => {
                  event.stopPropagation();
                  isSelected && button.onClick();
                }}
                aria-label={button.ariaLabel}
                as={button.icon}
              />
            </span>
          </Tooltip>
        );
      })}
    </Flex>
  );
};
