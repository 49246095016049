import React, { useState, useMemo, useEffect } from "react";
import {
  Text,
  HStack,
  Button,
  Center,
  FormControl,
  Tooltip,
  Icon,
  Switch,
  VStack,
  FormLabel,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Radio,
} from "@chakra-ui/react";
import RichText from "./ElementComponents/RichText";
import { AdvancedSectionWrapper } from "./ElementComponents/AdvancedSectionWrapper";
import { AdvancedNotes } from "./ElementComponents/AdvancedNotes";
import { RequiredToggle } from "./ElementComponents/RequiredToggle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useSelectionContext } from "../../context/SelectionContext";
import { ROLE_2, ROLE_1 } from "../../roles";
import { useJobAPI } from "../../endpoints/jobs";
import { useFileAPI } from "../../endpoints/files";
import { useAPIRequest } from "../../hooks/useAPI";
import { handleFileUpload } from "../../util/handleFileUpload";
import { BuilderHeader, ResponseHeader } from "./ElementComponents/QuestionHeaders";

interface BuildViewProps {
  value: any;
  titleNumber: string;
  indentationLevel: number;
  id: string;
  onChange: (e: any, field: string) => void;
}

interface TestParams {
  prediction_label: string;
  true_label: string;
  demographic_dict: any;
  output_type: string;
  supervised: boolean;
}

interface SelectQuestionProps {
  question: string;
  options: any[];
  value: string;
  onChange: (value: string) => void;
}

const Default = {
  type: "biasAnalysis",
  content: {
    title: "",
    question: "",
    required: false,
    allowMultiple: false,
    adjudicatorNotes: {},
    surveyTakerNotes: {},
    uploadedFiles: [],
    audience: ROLE_1,
    items: [
      {
        label: "pass",
        selected: true,
      },
      {
        label: "fail",
        selected: false,
      },
    ],
  },
};

const BuildView: React.FC<BuildViewProps> = ({ value, titleNumber, indentationLevel, id, onChange }) => {
  const { selectedElement } = useSelectionContext();
  const [required, setRequired] = useState(value.required);
  const [allowMultiple, setAllowMultiple] = useState(value.allowMultiple);
  const [restrictDeletion, setRestrictDeletion] = useState(value.restrictDeletion);
  const isSelectedElement = selectedElement === id;

  const updateRequired = (e: any) => {
    setRequired(e);
    onChange(e, "required");
  };

  const updateAllowMultiple = (e: any) => {
    setAllowMultiple(e.target.checked);
    onChange(e.target.checked, "allowMultiple");
  };

  const updateRestrictDeletion = (e: any) => {
    setRestrictDeletion(e.target.checked);
    onChange(e.target.checked, "restrictDeletion");
  };

  const fileUploadBuildView = useMemo(
    () => (
      <div>
        <div style={{ width: "100%" }}>
          <BuilderHeader
            questionPrompt="Question"
            title={value.title}
            titlePlaceholder="Untitled Bias Analysis"
            titleNumber={titleNumber}
            indentationLevel={indentationLevel}
            audience={value.audience}
            required={value.required}
            onChange={onChange}
          />
          <RichText
            placeholder="Enter the bias analysis instructions"
            selected={isSelectedElement}
            value={value.question}
            onChange={(e) => onChange(e, "question")}
          />
          <RequiredToggle value={required} onChange={(e) => updateRequired(e)} />
          <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
            <Text>Options</Text>
            <HStack>
              <Switch colorScheme="brightblue" isChecked={allowMultiple} onChange={updateAllowMultiple} />
              <Text>Allow multiple files.</Text>
            </HStack>
            <HStack>
              <Switch colorScheme="brightblue" isChecked={restrictDeletion} onChange={updateRestrictDeletion} />
              <Text>Only reviewers may delete. </Text>
              <Tooltip
                label={`When selected only a reviewer may delete attachments on the submission. Attachments may not be deleted after a submission is published.`}
              >
                <Icon boxSize="4" as={HelpOutlineIcon} />
              </Tooltip>
            </HStack>
          </VStack>
        </div>
        <AdvancedSectionWrapper>
          <div style={{ width: "100%" }}>
            <AdvancedNotes onChange={onChange} surveyTakerNotes={value.surveyTakerNotes} adjudicatorNotes={value.adjudicatorNotes} />
          </div>
        </AdvancedSectionWrapper>
      </div>
    ),
    [value, required, allowMultiple, restrictDeletion]
  );

  return isSelectedElement ? (
    fileUploadBuildView
  ) : (
    <EditView value={value} titleNumber={titleNumber} id={id} onChange={onChange} indentationLevel={indentationLevel} role={ROLE_2} buildView={true} />
  );
};

const BuildViewDebouncedChanges: string[] = ["title", "question", "surveyTakerNotes", "adjudicatorNotes"];

const SelectQuestion: React.FC<SelectQuestionProps> = ({ question, options, value, onChange }) => {
  return (
    <FormControl mb={4}>
      <FormLabel>{question}</FormLabel>
      <Select onChange={(e) => onChange(e.target.value)} value={value}>
        {options.map((option, idx) => (
          <option key={idx} value={option[1]}>
            {option[0]}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

interface DemographicTableProps {
  headers: string[];
  demographicValues: any;
  handleDemographicChange: (category: string, label: string, categoryLabels: string[]) => void;
}

const DemographicTable: React.FC<DemographicTableProps> = ({ headers, demographicValues, handleDemographicChange }) => {
  const handleCheckboxChange = (category: string, header: string, isChecked: boolean) => {
    const categoryLabels = [...demographicValues[category].labels];
    if (isChecked) {
      // Add the header to the labels array if checked
      if (!categoryLabels.includes(header)) {
        categoryLabels.push(header);
      }
    } else {
      // Remove the header from the labels array if unchecked
      const index = categoryLabels.indexOf(header);
      if (index !== -1) {
        categoryLabels.splice(index, 1);
      }
    }
    // Call the onDemographicChange function with the updated labels array
    handleDemographicChange(category, "labels", categoryLabels);
  };

  return (
    <VStack align="stretch" spacing={4}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Header</Th>
            {Object.keys(demographicValues).map((category) => (
              <Th key={category}>{category}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {headers.map((header) => (
            <Tr key={header}>
              <Td>{header}</Td>
              {Object.keys(demographicValues).map((category) => (
                <Td key={`${header}-${category}`}>
                  <Checkbox
                    key={`${header}-${category}`}
                    isChecked={demographicValues[category].labels.includes(header)}
                    onChange={(e) => handleCheckboxChange(category, header, e.target.checked)}
                  />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );
};

interface ControlGroupSelectProps {
  demographicValues: any;
  handleControlGroupChange: (category: string, controlGroup: string) => void;
}

const ControlGroupSelect: React.FC<ControlGroupSelectProps> = ({ demographicValues, handleControlGroupChange }) => {
  return (
    <VStack align="stretch" spacing={4}>
      {Object.entries(demographicValues).map(([category, values]) => {
        if ((values as any).labels.length > 0) {
          return (
            <FormControl key={category} as="fieldset">
              <FormLabel as="legend">{category} Control Group</FormLabel>
              <HStack>
                {(values as any).labels.map((label: any) => (
                  <Radio key={label} isChecked={(values as any).control_group === label} onChange={() => handleControlGroupChange(category, label)}>
                    {label}
                  </Radio>
                ))}
              </HStack>
            </FormControl>
          );
        }
        return null;
      })}
    </VStack>
  );
};

interface EditViewProps {
  value: any;
  titleNumber: string;
  onChange: (e: any, field: string) => void;
  indentationLevel: number;
  id: string;
  buildView?: boolean;
}

const EditView: React.FC<EditViewProps & { role: string }> = ({ id, value, titleNumber, onChange, indentationLevel, role, buildView = false }) => {
  const sendAPIRequest = useAPIRequest();

  const [isLoading, setIsLoading] = useState(false);
  const [fileId, setFileId] = useState<string | null>(null);
  const [jobId, setJobId] = useState<string | null>(null);
  const [fileURL, setFileURL] = useState<string | null>(null);
  const [testResults, setTestResults] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  const [headers, setHeaders] = useState<string[]>([]);

  const [selectedValues, setSelectedValues] = useState<TestParams>({
    prediction_label: "",
    true_label: "",
    demographic_dict: {
      Race: {
        labels: [],
        control_group: "",
      },
      Gender: {
        labels: [],
        control_group: "",
      },
      Age: {
        labels: [],
        control_group: "",
      },
      Disability: {
        labels: [],
        control_group: "",
      },
    },
    output_type: "",
    supervised: true,
  });

  const { postJob, getJobById } = useJobAPI();
  const { postFiles } = useFileAPI();

  const urlParts = window.location.href.split("/");
  const submissionId = urlParts[urlParts.length - 1];

  useEffect(() => {
    const run = async () => {
      const submission = await sendAPIRequest(`submissions/${submissionId}`);

      if (!submission.jobs || submission.jobs.length === 0) return;

      const mostRecentJob = submission.jobs[submission.jobs.length - 1];
      const outputFileURL = mostRecentJob.outputFile;

      const headersResponse = (await sendAPIRequest(`file/${mostRecentJob.inputFileId}/headers`, "GET")) as any;

      const res = await fetch(outputFileURL);
      const htmlContent = await res.text();

      setHeaders(headersResponse.headers as string[]);
      setSelectedValues(mostRecentJob.parameters);
      setTestResults(htmlContent);
    };

    run();
  }, []);

  // Update nested properties of selectedValues
  const handleUpdateSelectedValues = (field: string, value: string | boolean) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleDemographicChange = (category: string, label: string, categoryLabels: string[]) => {
    const updatedDemographicValues = {
      ...selectedValues.demographic_dict,
      [category]: {
        ...selectedValues.demographic_dict[category],
        labels: categoryLabels,
      },
    };

    handleUpdateSelectedValues("demographic_dict", updatedDemographicValues);
  };

  const upload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const [fileId, url] = await handleFileUpload(event, submissionId!, id, postFiles);
    setFileId(fileId);
    setFileURL(url);

    try {
      const headersResponse = (await sendAPIRequest(`file/${fileId}/headers`, "GET")) as any;
      setHeaders(headersResponse.headers as string[]);
      console.log(headersResponse.headers);
    } catch (error) {
      console.error("Error retrieving headers", error);
    }
  };

  const handleControlGroupUpdate = (category: string, controlGroup: string) => {
    const updatedDemographicValues = {
      ...selectedValues.demographic_dict,
      [category]: {
        ...selectedValues.demographic_dict[category],
        control_group: controlGroup,
      },
    };

    handleUpdateSelectedValues("demographic_dict", updatedDemographicValues);
  };

  const QuestionsComponent = () => {
    return (
      <VStack align="stretch" spacing={4}>
        <SelectQuestion
          question="Select the type of model output:"
          options={[
            ["Continuous Outcomes (e.g., rankings)", "continuous"],
            ["Discrete Outcomes (e.g., binary predictions)", "binary"],
          ]}
          value={selectedValues.output_type}
          onChange={(value) => handleUpdateSelectedValues("output_type", value)}
        />
        <SelectQuestion
          question="Variable for model output:"
          options={headers.map((header) => [header, header])}
          value={selectedValues.prediction_label}
          onChange={(value) => handleUpdateSelectedValues("prediction_label", value)}
        />
        <SelectQuestion
          question="Variable for truth:"
          options={headers.map((header) => [header, header])}
          value={selectedValues.true_label}
          onChange={(value) => handleUpdateSelectedValues("true_label", value)}
        />
        <DemographicTable headers={headers} demographicValues={selectedValues.demographic_dict} handleDemographicChange={handleDemographicChange} />
        <ControlGroupSelect demographicValues={selectedValues.demographic_dict} handleControlGroupChange={handleControlGroupUpdate} />
      </VStack>
    );
  };

  const handleRunAnalysis = async () => {
    try {
      setIsLoading(true);
      // API request to POST /jobs
      const jobResponse = await postJob({ fileId: fileId!, testParams: selectedValues });
      const { jobId } = jobResponse as any;

      setJobId(jobId);

      // Start progress bar
      const interval = setInterval(() => {
        setProgress((prev) => {
          const newProgress = prev + 5;
          if (newProgress >= 100) {
            clearInterval(interval);
          }
          return newProgress;
        });
      }, 1000);

      // Wait for 20 seconds before making the next API call
      setTimeout(async () => {
        // API request to GET /jobs/:id
        const jobResult = await getJobById(jobId!);
        const { outputFileURL } = jobResult as any;

        // Download and display HTML content
        const res = await fetch(outputFileURL);
        const htmlContent = await res.text();

        console.log("html", htmlContent);

        // const blob = new Blob([htmlContent], { type: 'text/html' });
        // const blobUrl = URL.createObjectURL(blob);

        // setTestResults(blobUrl);
        setTestResults(htmlContent);
        setIsLoading(false);
        setProgress(0);

        const didPass = fileURL!.includes("pass");
        onChange(
          [
            { label: "pass", selected: didPass },
            { label: "fail", selected: !didPass },
          ],
          "items"
        );
      }, 20000);
    } catch (error) {
      setIsLoading(false);
      console.error("Error running bias analysis", error);
    }
  };

  return (
    <div style={{ paddingBottom: 5 }}>
      <ResponseHeader
        title={value.title}
        indentationLevel={indentationLevel}
        titleNumber={titleNumber}
        required={value.required}
        role={role}
        adjudicatorNotes={value.adjudicatorNotes}
        surveyTakerNotes={value.surveyTakerNotes}
        audience={value.audience}
        buildView={buildView}
        questionPlaceholder="Enter the bias analysis instructions"
        question={value.question}
      />
      <Center>
        <FormControl>
          <input id="file-upload" type="file" accept=".csv" onChange={upload} />
        </FormControl>
      </Center>
      {headers.length > 0 && <QuestionsComponent />}
      {fileURL && (
        <Button colorScheme="blue" onClick={handleRunAnalysis} ml={4} disabled={isLoading}>
          Run Bias Analysis
        </Button>
      )}
      {progress !== 0 && progress < 100 && (
        <Center>
          <progress value={progress} max="100"></progress>
        </Center>
      )}
      {testResults && !isLoading && (
        <Center>
          <div
            style={{
              width: "100%",
              border: "1px solid #ccc",
              padding: "10px",
              fontSize: "14px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
            dangerouslySetInnerHTML={{ __html: testResults }}
          />
        </Center>
      )}
    </div>
  );
};

const EditViewDebouncedChanges: string[] = [];

export default { BuildView, EditView, Default, EditViewDebouncedChanges, BuildViewDebouncedChanges };
