import { useState } from "react";
import { VStack, Text, Card, useToast, Tooltip, useDisclosure } from "@chakra-ui/react";
import { SubmissionsTable } from "../../components/SubmissionsTable";
import { SharingModal } from "../../components/Modal/SharingModal";
import { EnrichedSubmissionContentless } from "../../types";
import { useNavigate } from "react-router-dom";
import { useSubmissionAPI } from "../../endpoints/submissions";
export const SubmissionsSection = ({
  submissions,
  refreshSubmissions,
  finalizePanel,
}: {
  submissions: any;
  refreshSubmissions: () => void;
  finalizePanel: boolean;
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen: sharingModalIsOpen, onOpen: openSharingModal, onClose: closeSharingModal } = useDisclosure();
  const [submissionForModal, setSubmissionForModal] = useState<EnrichedSubmissionContentless>();
  const { deleteSubmission, finalizeSubmission, unfinalizeSubmission } = useSubmissionAPI();

  function truncate(inStr: string, idx: number) {
    const maxlen = 40;
    return inStr.length > maxlen ? (
      <Tooltip key={`submission-desc-tt-${idx}`} label={inStr} openDelay={1000}>
        {inStr.slice(0, maxlen - 1) + "..."}
      </Tooltip>
    ) : (
      inStr
    );
  }

  const headers = ["", "Title", "Form", "Form Description", "Last Updated", "Actions"];
  const rowsData = submissions.map((submission: any, idx: number) => [
    submission.meta.title,
    submission.readOnly.formInfo.name,
    truncate(submission.readOnly.formInfo.description, idx),
    <VStack key={`form-timestamp-${idx}`} spacing="0px">
      <Text fontSize="11px">{new Date(submission.meta.updatedAt).toDateString()}</Text>
      <Text fontSize="11px">{new Date(submission.meta.updatedAt).toLocaleTimeString()}</Text>
    </VStack>,
  ]);
  const rowsAllowedActions = submissions.map((submission: any) => submission.readOnly.actions);
  const rowsAttentionItems = submissions.map((submission: any) => submission.readOnly.attentionItems);
  const rowClickHandlers = submissions.map((submission: any) => () => navigate(`/submission-editor/${submission._id}`));

  const handleDelete = async (submissionIndex: number) => {
    const submissionId = submissions[submissionIndex]._id;

    await deleteSubmission(submissionId);
    refreshSubmissions();
    toast({
      title: "Submission deleted successfully.",
      status: "success",
      containerStyle: {
        textColor: "white",
      },
    });
  };

  const handleFinalize = async (submissionIndex: number) => {
    const submissionId = submissions[submissionIndex]._id;

    await finalizeSubmission(submissionId);
    refreshSubmissions();
    toast({
      title: "Submission successfully finalized.",
      status: "success",
      containerStyle: {
        textColor: "white",
      },
    });
  };

  const handleUnfinalize = async (submissionIndex: number) => {
    const submissionId = submissions[submissionIndex]._id;

    await unfinalizeSubmission(submissionId);
    refreshSubmissions();
    toast({
      title: "Submission successfully unfinalized.",
      status: "success",
      containerStyle: {
        textColor: "white",
      },
    });
  };

  const handleSharingApproval = async (submissionIndex: number) => {
    console.log(`handleSubmissionApproval ${submissionIndex}`);
  };

  const handleSharing = async (submissionIndex: number) => {
    setSubmissionForModal(submissions[submissionIndex]);
    console.log(`handleSharing ${submissionIndex}`);
    openSharingModal();
  };

  const rowCallbacks = {
    finalize: (index: any) => handleFinalize(index),
    unfinalize: (index: any) => handleUnfinalize(index),
    sharing: (index: any) => handleSharing(index),
    sharingApproval: (index: any) => handleSharingApproval(index),
    delete: (index: any) => handleDelete(index),
  };

  return (
    <>
      <SharingModal document={submissionForModal} type="submission" isOpen={sharingModalIsOpen} onOpen={openSharingModal} onClose={closeSharingModal} />
      <Card variant="outline">
        <SubmissionsTable
          headers={headers}
          rowsData={rowsData}
          rowsAllowedActions={rowsAllowedActions}
          rowsAttentionItems={rowsAttentionItems}
          rowCallbacks={rowCallbacks}
          rowClickHandlers={rowClickHandlers}
          finalizePanel={finalizePanel}
        />
      </Card>
    </>
  );
};
