import React, { useState, useMemo, useEffect } from "react";
import RichText from "./ElementComponents/RichText";
import { RequiredToggle } from "./ElementComponents/RequiredToggle";
import { Text, Tooltip, Icon, HStack, Switch, VStack, Divider, CircularProgress } from "@chakra-ui/react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { AdvancedSectionWrapper } from "./ElementComponents/AdvancedSectionWrapper";
import { AdvancedNotes } from "./ElementComponents/AdvancedNotes";
import { FormElementTypes } from "../../types";
import { useSelectionContext } from "../../context/SelectionContext";
import { ROLE_2, ROLE_1 } from "../../roles";
import { useFileAPI } from "../../endpoints/files";
import { handleFileUpload } from "../../util/handleFileUpload";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Colors } from "../../theme/colors";
import { BuilderHeader, ResponseHeader } from "./ElementComponents/QuestionHeaders";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

interface BuildViewProps {
  value: FormElementTypes.FileUpload;
  children?: JSX.Element[];
  titleNumber: string;
  id: string;
  indentationLevel: number;
  onChange: (e: any, field: string) => void;
}

const Default = {
  type: "fileUpload",
  content: {
    title: "",
    question: "",
    required: false,
    allowMultiple: false,
    adjudicatorNotes: {},
    surveyTakerNotes: {},
    uploadedFiles: [],
    audience: ROLE_1,
  },
};

const BuildView: React.FC<BuildViewProps> = ({ value, titleNumber, id, indentationLevel, onChange }) => {
  const { selectedElement } = useSelectionContext();
  const [required, setRequired] = useState(value.required);
  const [allowMultiple, setAllowMultiple] = useState(value.allowMultiple);
  const [restrictDeletion, setRestrictDeletion] = useState(value.restrictDeletion);
  const isSelectedElement = selectedElement === id;

  const updateRequired = (e: any) => {
    setRequired(e);
    onChange(e, "required");
  };

  const updateAllowMultiple = (e: any) => {
    setAllowMultiple(e.target.checked);
    onChange(e.target.checked, "allowMultiple");
  };

  const updateRestrictDeletion = (e: any) => {
    setRestrictDeletion(e.target.checked);
    onChange(e.target.checked, "restrictDeletion");
  };

  const fileUploadBuildView = useMemo(
    () => (
      <div>
        <div style={{ width: "100%" }}>
          <BuilderHeader
            questionPrompt="File Upload Prompt"
            title={value.title}
            titlePlaceholder="Untitled File Upload Question"
            titleNumber={titleNumber}
            indentationLevel={indentationLevel}
            audience={value.audience}
            required={value.required}
            onChange={onChange}
          />
          <RichText
            placeholder="Enter the file upload instructions"
            selected={isSelectedElement}
            value={value.question}
            onChange={(e) => onChange(e, "question")}
          />
          <RequiredToggle value={required} onChange={(e) => updateRequired(e)} />
          <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
            <Text>Options</Text>
            <HStack>
              <Switch colorScheme="brightblue" isChecked={allowMultiple} onChange={updateAllowMultiple} />
              <Text>Allow multiple files.</Text>
            </HStack>
            <HStack>
              <Switch colorScheme="brightblue" isChecked={restrictDeletion} onChange={updateRestrictDeletion} />
              <Text>Only reviewers may delete. </Text>
              <Tooltip
                label={`When selected only a reviewer may delete attachments on the submission. Attachments may not be deleted after a submission is finalized.`}
              >
                <Icon boxSize="4" as={HelpOutlineIcon} />
              </Tooltip>
            </HStack>
          </VStack>
        </div>
        <AdvancedSectionWrapper>
          <div style={{ width: "100%" }}>
            <AdvancedNotes onChange={onChange} surveyTakerNotes={value.surveyTakerNotes} adjudicatorNotes={value.adjudicatorNotes} />
          </div>
        </AdvancedSectionWrapper>
      </div>
    ),
    [value, required, allowMultiple, restrictDeletion]
  );
  return isSelectedElement ? (
    fileUploadBuildView
  ) : (
    <EditView role={ROLE_2} value={value} titleNumber={titleNumber} id={id} onChange={onChange} indentationLevel={indentationLevel} buildView={true} />
  );
};

const BuildViewDebouncedChanges: string[] = ["title", "question", "surveyTakerNotes", "adjudicatorNotes"];

const EditViewDebouncedChanges: string[] = [];
interface EditViewProps {
  value: FormElementTypes.FileUpload;
  children?: JSX.Element[];
  titleNumber: string;
  id: string;
  indentationLevel: number;
  onChange: (e: any, field: string) => void;
  buildView?: boolean;
}
const EditView: React.FC<EditViewProps & { role: string }> = ({ value, titleNumber, id, indentationLevel, onChange, role, buildView = false }) => {
  const { postFiles, getFile, deleteFile } = useFileAPI();
  const [disableUpload, setDisableUpload] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>();
  const [allowDelete, setAllowDelete] = useState(false);
  const [uploading, setUploading] = useState(false);
  const submissionId = getQueryStringParameter("id");

  useEffect(() => {
    setUploadedFiles(value.uploadedFiles);
  }, [value]);

  useEffect(() => {
    if (role === ROLE_2) {
      setAllowDelete(true);
    } else if (role === ROLE_1 && value.restrictDeletion) {
      setAllowDelete(false);
    }
  }, [role]);

  useEffect(() => {
    if (!value.allowMultiple && value.uploadedFiles.length > 0) {
      setDisableUpload(true);
    }
  }, [uploadedFiles]);

  function getQueryStringParameter(param: string) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
  }

  const upload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    const [fileId, url] = await handleFileUpload(event, submissionId!, id, postFiles);

    if (!fileId || !url) {
      setUploading(false);
      return;
    }

    const newFile = { fileId, url };
    const allFiles = value.allowMultiple && uploadedFiles ? [...uploadedFiles, newFile] : [newFile];
    setUploadedFiles(allFiles);

    onChange(allFiles, "uploadedFiles");

    setUploading(false);
  };

  const download = async (fileId: string, fileName: string) => {
    // download file to user's local machine
    const fileResponse = await getFile(fileId);
    if (!fileResponse.url) {
      return;
    }

    const res = await fetch(fileResponse.url);

    let type = res.headers.get("content-type");

    type = type!.concat(";charset=utf-8");

    const blob = await res.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName || "downloaded-file";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const deleteFileById = async (fileId: string) => {
    // delete file from the server
    try {
      await deleteFile(fileId);
    } catch (e) {
      console.log(e);
    }
    const newFiles = uploadedFiles?.filter((file) => file.fileId !== fileId);
    setUploadedFiles(newFiles);
    onChange(newFiles, "uploadedFiles");
  };

  const FileRow = ({ file }: { file: { fileId: string; url: string } }) => {
    return (
      <div style={{ width: "88%" }}>
        <HStack justifyContent={"space-between"} alignItems="center">
          <Tooltip label="Download file">
            <HStack onClick={() => download(file.fileId, file.url)} pb={2} style={{ cursor: "pointer" }}>
              <Icon boxSize={5} color="gray.500" as={InsertDriveFileOutlinedIcon} />
              <Text style={{ textDecoration: "underline", color: "#0077db" }}>{file.url}</Text>
            </HStack>
          </Tooltip>
          {allowDelete ? (
            <Tooltip label="Delete file">
              <Icon style={{ cursor: "pointer" }} onClick={() => deleteFileById(file.fileId)} as={ClearOutlinedIcon} boxSize={5} color="gray.500" />
            </Tooltip>
          ) : null}
        </HStack>
        <Divider />
      </div>
    );
  };

  const UploadingLoader = () => {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <HStack pt={2} style={{ width: "88%" }}>
          <CircularProgress size="20px" isIndeterminate color="brightblue.500" />
          <Text>Uploading...</Text>
        </HStack>
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <ResponseHeader
        title={value.title}
        indentationLevel={indentationLevel}
        titleNumber={titleNumber}
        required={value.required}
        role={role}
        adjudicatorNotes={value.adjudicatorNotes}
        surveyTakerNotes={value.surveyTakerNotes}
        audience={value.audience}
        buildView={buildView}
        questionPlaceholder="Enter the file upload instructions"
        question={value.question}
      />
      <VStack style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {uploadedFiles?.map((file: { fileId: string; url: string }, index) => (
          <FileRow key={index} file={file} />
        ))}
      </VStack>
      {uploading ? <UploadingLoader /> : null}
      <input style={{ ...styles.input }} id="file-upload" type="file" accept="*" onChange={upload} />
      <label htmlFor={disableUpload ? "" : "file-upload"}>
        <div style={{ ...styles.label, marginBottom: 8, backgroundColor: disableUpload ? Colors.gray[300] : Colors.brightblue[500] }}>
          <Icon as={FileUploadOutlinedIcon} />
          <Text>Choose a file</Text>
        </div>
      </label>
    </div>
  );
};

export default { BuildView, Default, EditView, EditViewDebouncedChanges, BuildViewDebouncedChanges };

const styles = {
  input: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    zIndex: -1,
  },
  label: {
    width: "150px",
    margin: 1,
    padding: 5,
    borderRadius: "2px",
    fontSize: "12px",
    boxShadow: "11px 11px 34px -25px rgba(0,0,0,0.69)",
    color: "white",
    cursor: "pointer",
    justifyContent: "center",
    display: "flex",
  },
};
