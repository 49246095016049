// List of all the nav items in the sidenav.
import { IconType } from "react-icons";
import { List, ListItem, Tooltip, IconButton } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { navItems } from "./menuItems";

export interface SideNavItem {
  icon: IconType;
  label: string;
  to: string;
}

export function SideNavItems() {
  const sidebarItemInSemiMode = ({ icon: Icon, ...item }: SideNavItem, index: number) => (
    <ListItem key={index}>
      <Tooltip label={item.label} placement="right">
        <IconButton
          key={index}
          as={NavLink}
          color="white.100"
          _focus={{ bg: "brand.500" }}
          _activeLink={{ bg: "brand.500", color: "gray.900" }}
          _hover={{ boxShadow: "md", bg: "brand.300", color: "gray.800" }}
          bg="transparent"
          w="48px"
          aria-label={item.label}
          icon={<Icon />}
          to={item.to}
          borderRadius="none"
        />
      </Tooltip>
    </ListItem>
  );

  return (
    <div>
      <List spacing={1}>{navItems.map((item, index) => sidebarItemInSemiMode(item, index))}</List>
    </div>
  );
}

export default SideNavItems;
