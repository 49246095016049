import {
  Center,
  Tooltip,
  Circle,
  Text,
  Flex,
  HStack,
  VStack,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  MenuDivider,
  Spacer,
  Icon,
  Heading,
} from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";
import { EnrichedForm, FormUser, EnrichedFormContentless, EnrichedSubmission, EnrichedSubmissionContentless, OtherUserInfo, SubmissionUser } from "../../types";
import { useAPIRequest } from "../../hooks/useAPI";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { urlPathPrefix } from "./SharingModal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface DocUser {
  userInfo: OtherUserInfo;
  userDocInfo: FormUser | SubmissionUser;
}

interface IUserAccessCard {
  userId: string;
  docUsers: Map<string, DocUser>;
  docId: string;
  docType: "form" | "submission";
  doc: EnrichedForm | EnrichedFormContentless | EnrichedSubmission | EnrichedSubmissionContentless;
  openErrorModal: () => void;
  setSaving: (arg0: boolean) => void;
  setDocUsers: (du: Map<string, DocUser>) => void;
}
export const UserAccessCard = ({ userId, docUsers, docId, docType, doc, openErrorModal, setSaving, setDocUsers }: IUserAccessCard) => {
  const [savingThis, setSavingThis] = useState(false);
  const sendAPIRequest = useAPIRequest();
  let roleColor = "black";
  let showRoleSelection = true;
  let ownerCount = 0;
  let adjudicatorCount = 0;
  let pendingApproval = false;

  const [role, setRole] = useState(docUsers.get(userId)!.userDocInfo.role);

  function displayRole(role: string) {
    if (role === "adjudicator") {
      return "reviewer";
    }
    return role;
  }

  docUsers.forEach((value: DocUser) => {
    if (value.userDocInfo.role === "owner" && value.userDocInfo.isApproved) {
      ownerCount = ownerCount + 1;
    } else if (value.userDocInfo.role === "adjudicator" && value.userDocInfo.isApproved) {
      adjudicatorCount = adjudicatorCount + 1;
    }
  });

  if (docType === "form") {
    if (role === "owner" && ownerCount === 1) {
      showRoleSelection = false;
      roleColor = "gray.500";
    }
  } else if (docType === "submission") {
    if (role === "adjudicator" && adjudicatorCount === 1) {
      showRoleSelection = false;
      roleColor = "gray.500";
    }
  }
  if (!docUsers.get(userId)!.userDocInfo.isApproved) {
    showRoleSelection = false;
    pendingApproval = true;
    roleColor = "gray.500";
  }

  async function saveRole(role: string) {
    setSaving(true);
    setSavingThis(true);
    try {
      const res: any = await sendAPIRequest(`${urlPathPrefix(docType)}/${docId}/user-role/${docUsers.get(userId)!.userInfo.userId}`, "PUT", { role: role });
      if (!res.message) {
        openErrorModal();
      } else {
        setDocUsers(
          docUsers.set(userId, {
            userDocInfo: {
              ...docUsers.get(userId)!.userDocInfo,
              role: role,
            },
            userInfo: docUsers.get(userId)!.userInfo,
          })
        );
        setRole(role);
      }
    } catch (error) {
      openErrorModal();
    } finally {
      setSaving(false);
      setSavingThis(false);
    }
  }

  function changeToOwner() {
    if (role !== "owner") {
      saveRole("owner");
    }
  }

  function changeToEditor() {
    if (role !== "editor") {
      saveRole("editor");
    }
  }

  function changeToMaySubmit() {
    if (role !== "submitter") {
      saveRole("submitter");
    }
  }

  function changeToAdjudicator() {
    if (role !== "adjudicator") {
      saveRole("adjudicator");
    }
  }

  function renderName(user: OtherUserInfo) {
    if (user.firstName && user.lastName) {
      return <Heading size="sm">{`${user.firstName} ${user.lastName}`}</Heading>;
    }
    if (user.lastName) {
      return <Heading size="sm">{`${user.lastName}`}</Heading>;
    }
    if (user.firstName) {
      return <Heading size="sm">{`${user.firstName}`}</Heading>;
    }
    return <></>;
  }

  function getInitials(user: OtherUserInfo) {
    if (user.initials) {
      return user.initials.toUpperCase();
    }
    if (user.firstName && user.lastName) {
      return user.firstName.substring(0, 1).toUpperCase() + user.lastName.substring(0, 0).toUpperCase();
    }
    if (user.firstName) {
      return user.firstName.substring(0, 1).toUpperCase();
    }
    if (user.lastName) {
      return user.lastName.substring(0, 1).toUpperCase();
    }
    return user.email!.substring(0, 1).toUpperCase();
  }

  async function approveUser() {
    setSaving(true);
    setSavingThis(true);
    try {
      const res: any = await sendAPIRequest(`${urlPathPrefix(docType)}/${docId}/user-approval/${docUsers.get(userId)!.userInfo.userId}`, "PUT");
      if (!res.message) {
        openErrorModal();
      } else {
        setDocUsers(
          docUsers.set(userId, {
            userDocInfo: {
              ...docUsers.get(userId)!.userDocInfo,
              isApproved: true,
            },
            userInfo: docUsers.get(userId)!.userInfo,
          })
        );
      }
    } catch (error) {
      openErrorModal();
    } finally {
      setSaving(false);
      setSavingThis(false);
    }
  }

  async function deleteUser() {
    setSaving(true);
    setSavingThis(true);
    try {
      const res: any = await sendAPIRequest(`${urlPathPrefix(docType)}/${docId}/user/${docUsers.get(userId)!.userInfo.userId}`, "DELETE");
      if (!res.message) {
        openErrorModal();
      } else {
        docUsers.delete(userId);
        setDocUsers(docUsers);
      }
    } catch (error) {
      openErrorModal();
    } finally {
      setSaving(false);
      setSavingThis(false);
    }
  }

  return (
    <Flex w="100%" mt="8px">
      <Circle bg="brightblue.500" size="42px" mr="6px">
        <Text textColor="white" fontWeight="bold" fontSize="14px">
          <>{getInitials(docUsers.get(userId)!.userInfo)}</>
        </Text>
      </Circle>
      <Center>
        <VStack spacing="0px">
          {docUsers.get(userId)!.userInfo.firstName || docUsers.get(userId)!.userInfo.lastName ? <>{renderName(docUsers.get(userId)!.userInfo)}</> : <></>}
          <Text>{docUsers.get(userId)!.userInfo.email}</Text>
        </VStack>
      </Center>
      <Spacer />
      <Center mr="12px">
        {savingThis ? (
          <Text textColor={roleColor}>saving...</Text>
        ) : (
          <>
            {!showRoleSelection ? (
              <>
                {pendingApproval ? (
                  <HStack w="100%">
                    <Text fontSize={"12px"}>{`${role}, pending legal approval`}</Text>
                    {doc!.readOnly.actions.privilegeApprovals ? (
                      <>
                        <Tooltip label={`Approve user access`}>
                          <Icon onClick={approveUser} as={CheckCircleOutlineIcon} color="green.500" />
                        </Tooltip>
                        <Tooltip label={`Reject user access`}>
                          <Icon onClick={deleteUser} as={HighlightOffIcon} color="red.500" />
                        </Tooltip>
                      </>
                    ) : (
                      <></>
                    )}
                  </HStack>
                ) : (
                  <Text textColor={roleColor} w="100%">
                    {displayRole(role)}
                  </Text>
                )}
              </>
            ) : (
              <Menu>
                <MenuButton>
                  <Center>
                    <HStack spacing="2px">
                      <Text>{displayRole(role)}</Text>
                      <Icon as={ArrowDropDownOutlinedIcon} />
                    </HStack>
                  </Center>
                </MenuButton>
                <MenuList>
                  {docType === "form" && (doc as EnrichedFormContentless).readOnly.actions.becomeAdjudicator ? (
                    <MenuItem onClick={changeToOwner}>
                      <Flex w="100%">
                        {role === "owner" ? <Icon as={CheckOutlinedIcon} /> : <></>}
                        <Spacer />
                        <Text>owner</Text>
                      </Flex>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  {docType === "form" ? (
                    <MenuItem onClick={changeToEditor}>
                      <Flex w="100%">
                        {role === "editor" ? <Icon as={CheckOutlinedIcon} /> : <></>}
                        <Spacer />
                        <Text>editor</Text>
                      </Flex>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  {docType === "submission" && (doc as EnrichedSubmissionContentless).readOnly.actions.assignAdjudicators ? (
                    <MenuItem onClick={changeToAdjudicator}>
                      <Flex w="100%">
                        {role === "adjudicator" ? <Icon as={CheckOutlinedIcon} /> : <></>}
                        <Spacer />
                        <Text>reviewer</Text>
                      </Flex>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  <MenuItem onClick={changeToMaySubmit}>
                    <Flex w="100%">
                      {role === "submitter" ? <Icon as={CheckOutlinedIcon} /> : <></>}
                      <Spacer />
                      <Text>submitter</Text>
                    </Flex>
                  </MenuItem>
                  <MenuDivider></MenuDivider>
                  <MenuItem onClick={deleteUser}>Remove Access</MenuItem>
                </MenuList>
              </Menu>
            )}
          </>
        )}
      </Center>
    </Flex>
  );
};
