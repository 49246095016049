import { useEffect, useState } from "react";
import { Heading, HStack, Button, useDisclosure, Tab, Flex, Tabs, TabPanels, TabPanel, TabList, VStack } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { useTopNavContext } from "../context/TopNavContext";
import { OrganizationMembershipModal } from "../components/Modal/OrganizationMembershipModal";
import SettingsTabPanel from "./OrganizationTabPanels/SettingsTabPanel";
import MembersTabPanel from "./OrganizationTabPanels/MembersTabPanel";
import PrivilegeTabPanel from "./OrganizationTabPanels/PrivilegeTabPanel";
import { useSelector } from "react-redux";
import { PageInfo } from "../components/TopNavBar/PageInfo";

export default function Organization() {
  const { setPageInfo, setPageActions } = useTopNavContext();
  const currentOrgMembership = useSelector((state: any) => state.auth.globalUserInfo.currentOrgMembership);

  useEffect(() => {
    setPageInfo(<PageInfo title="Manage Organization" />);
  }, []);

  useEffect(() => {
    setPageActions(<HStack></HStack>);
  }, []);

  const [refreshMemberships, setRefreshMemberships] = useState<number>(0);

  const { isOpen: orgMembershipModalOpen, onOpen: openOrgMembershipModal, onClose: closeOrgMembershipModal } = useDisclosure();

  function setRefreshMembershipsWrapper() {
    setRefreshMemberships(refreshMemberships + 1);
  }

  return (
    <div style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "5%" }}>
      <OrganizationMembershipModal
        isOpen={orgMembershipModalOpen}
        onClose={closeOrgMembershipModal}
        setRefreshMemberships={setRefreshMembershipsWrapper}
        organizationName={currentOrgMembership?.organization?.name}
        membershipToEdit={null}
      />
      <Flex flexDirection="row">
        <Heading variant="h2">{currentOrgMembership?.organization?.name}</Heading>
        <Spacer />
        {currentOrgMembership?.permissions.manageOrgPermissions ? (
          <HStack w="auto" ml="auto">
            <Button onClick={openOrgMembershipModal}>Invite Member</Button>
          </HStack>
        ) : null}
      </Flex>
      <VStack align="left" pl="24px">
        <Spacer y={SpacerSizes.md} />
        <Tabs>
          <TabList>
            {currentOrgMembership?.permissions?.manageOrgSettings ? <Tab>Settings</Tab> : <></>}
            {currentOrgMembership?.permissions?.manageOrgPermissions ? <Tab>Members</Tab> : <></>}
            {currentOrgMembership?.permissions?.manageLegalPrivilege ? <Tab>Privilege Management</Tab> : <></>}
          </TabList>

          <TabPanels>
            {currentOrgMembership?.permissions?.manageOrgSettings ? (
              <TabPanel>
                <SettingsTabPanel />
              </TabPanel>
            ) : (
              <></>
            )}
            {currentOrgMembership?.permissions?.manageOrgPermissions ? (
              <TabPanel>
                <MembersTabPanel refreshMemberships={refreshMemberships} />
              </TabPanel>
            ) : (
              <></>
            )}

            <TabPanel>
              <PrivilegeTabPanel />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </div>
  );
}
