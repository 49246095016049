import { Box, HStack, ModalCloseButton, Text, VStack } from "@chakra-ui/react";
import { ReactElement } from "react";

export const LuminosModalHeader = ({ title, closeButton, iconWrapper }: { title?: string; closeButton?: boolean; iconWrapper?: ReactElement }) => {
  return (
    <VStack w="full" spacing="0px">
      <HStack p={1} bg="gray.800" color="white.500" w="full">
        {iconWrapper}
        <Text align="left" mr="auto" ml="4px">
          {title}
        </Text>
        {closeButton ? <ModalCloseButton size="sm" mt="-7px" /> : <Text></Text>}
      </HStack>
      <Box bg="brand.500" w="full" h="1px"></Box>
      <Box bg="gray.900" w="full" h="2px"></Box>
    </VStack>
  );
};
