import { createClient } from "@supabase/supabase-js";
import { Heading, VStack, Text, Spacer } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

export const PostLogin = () => {
  const { state } = useLocation();

  let displayWaitingList = false;
  let displayError = false;
  let displaySuccess = false;

  console.log(state);
  if ("waitingList" in state) {
    console.log("waiting list");
    displayWaitingList = true;
  } else if ("error" in state) {
    console.log(`error ${state.error}`);
    displayError = true;
  } else {
    console.log(`success: ${state.message}`);
    displaySuccess = true;
    //navigate("login-success", { state: resp.message });
  }
  return (
    <>
      <div
        style={{
          marginTop: "10%",
          paddingLeft: "25%",
          paddingRight: "25%",
        }}
      >
        {displayWaitingList ? <Heading>Thanks</Heading> : <></>}
        {displayError ? <Heading>Error</Heading> : <></>}
        {displaySuccess ? <Heading>Success</Heading> : <></>}
        <VStack spacing="0px" mt="16px">
          <Spacer mr="auto" />
          {displayWaitingList ? (
            <Text>
              We appreciate your interest and have added you to our wating list.
              We&apos;ll be in touch.
            </Text>
          ) : (
            <></>
          )}
          {displayError ? <Text>{state.error}</Text> : <></>}
          {displaySuccess ? <Text>{state.message}</Text> : <></>}
        </VStack>
      </div>
    </>
  );
};
