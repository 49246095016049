import { useState, useRef, useEffect } from "react";
import { cloneDeep } from "lodash";
import romanize from "../util/romanize";
import useCommentManager from "./useCommentManager";
import { CommentThread, SubmissionElement, EnrichedSubmission, PathIndex } from "../types";
import { useSubmissionAPI } from "../endpoints/submissions";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ROLE_1 } from "../roles";
import _ from "lodash";

const defaultSubmission = {
  _id: "",
  meta: {
    title: "",
    version: 1,
    publishedVersion: 1,
    privileged: false,
    createdAt: "",
    createdByUserId: "",
    createdFromFormId: "",
    organizationId: "",
    updatedAt: "",
    privilegeWarningdismissed: false,
    finalized: false,
  },
  users: [],
  elements: [],
  commentThreadsDictionary: {},
  readOnly: {
    actions: {
      assignSubmitters: false,
      assignAdjudicators: false,
      open: false,
      finalize: false,
      privilegeApprovals: false,
      delete: false,
    },
    creator: {
      userId: "",
    },
    attentionItems: {
      privilegeApprovalsNeeded: false,
    },
    formInfo: {
      _id: "",
      name: "",
      description: "",
    },
    usersInfo: [],
  },
};

function useSubmissionManager(initialSubmission?: EnrichedSubmission) {
  const [submission, setSubmission] = useState<EnrichedSubmission>(initialSubmission ?? defaultSubmission);
  const { setRole, role, commentThreadsDictionary, setCommentThreadsDictionary, getElementCommentThreads, commentFunctions } = useCommentManager();
  const submissionRef = useRef<EnrichedSubmission>(initialSubmission ?? defaultSubmission);
  const { updateSubmissionElements } = useSubmissionAPI();
  const globalUserInfo = useSelector((state: RootState) => state.auth.globalUserInfo);

  useEffect(() => {
    if (globalUserInfo.user) {
      const currentRole = submission.users?.find((user) => user.userId === globalUserInfo.user._id)?.role ?? ROLE_1;
      setRole(currentRole);
    }
  }, [globalUserInfo.user, submission.users]);

  useEffect(() => {
    submissionRef.current = submission;

    setCommentThreadsDictionary(submission.commentThreadsDictionary);
  }, [submission]);

  const getTitleNumber = (path: PathIndex): string => {
    let titleNumber = "";
    path.forEach((levelIndex, nestingLevel) => {
      const levelIndexNumber = levelIndex + 1;

      titleNumber += [levelIndexNumber, String.fromCharCode(96 + levelIndexNumber), romanize(levelIndexNumber), levelIndexNumber][nestingLevel];

      titleNumber += ".";
    });

    return titleNumber;
  };

  const getIndentationLevel = (elementId: string): number => {
    return getElementPathIndexes([elementId])[0].length;
  };

  const evaluateVisibilityFromRules = (elements: any[], currentPath: PathIndex = []) => {
    elements?.forEach((element, index) => {
      element.pathIndex = [...currentPath, index];
      element.isVisibleByRules = true;

      if (element.rules && element.rules.length > 0) {
        element.isVisibleByRules = false;

        const ruleResults: any = [];

        element.rules.forEach((rule: any) => {
          const [firstOperandPathIndex] = getElementPathIndexes([rule.firstOperand]);

          const [firstOperand] = getElementAndParent(firstOperandPathIndex);

          let firstOperandValue;
          firstOperand?.content.items.forEach((item: any) => {
            if (item.selected === true) {
              firstOperandValue = item.label;
            }
          });

          if (firstOperandValue !== undefined) {
            if (rule.operator === "isEqualTo") {
              ruleResults.push(firstOperandValue === rule.secondOperand);
            } else if (rule.operator === "isNotEqualTo") {
              ruleResults.push(firstOperandValue !== rule.secondOperand);
            } else if (rule.operator === "gte") {
              ruleResults.push(Number(firstOperandValue) >= rule.secondOperand);
            } else if (rule.operator === "lte") {
              ruleResults.push(Number(firstOperandValue) <= rule.secondOperand);
            }

            if (rule.continuation) {
              ruleResults.push(rule.continuation);
            }
          }
        });

        try {
          const evalResult = eval(ruleResults.join(" "));

          if (evalResult === true || evalResult === false) {
            element.isVisibleByRules = evalResult;
          }
        } catch (e) {
          // nothing
        }
      }

      if (element.children && element.children.length > 0) {
        evaluateVisibilityFromRules(element.children, element.pathIndex);
      }
    });
  };

  const assignPathIdsAndSectionNumbers = (elements: SubmissionElement[], currentPath: PathIndex = []) => {
    elements?.forEach((element, index) => {
      element.pathIndex = [...currentPath, index];
      element.titleNumber = getTitleNumber(element.pathIndex);
      if (element.children && element.children.length > 0) {
        assignPathIdsAndSectionNumbers(element.children, element.pathIndex);
      }
    });
  };

  const triggerUpdate = () => {
    assignPathIdsAndSectionNumbers(submissionRef.current.elements);
    evaluateVisibilityFromRules(submissionRef.current.elements);
    setSubmission({ ...submissionRef.current });
  };

  const loadSubmission = (submission: EnrichedSubmission) => {
    submissionRef.current = cloneDeep(submission);
    triggerUpdate();
  };

  const updateMeta = ({ meta }: { meta: EnrichedSubmission["meta"] }) => {
    submissionRef.current.meta = {
      ...submissionRef.current.meta,
      ...meta,
    };

    triggerUpdate();
  };

  const getElementPathIndexes = (elementIds: string[]): PathIndex[] => {
    const findPathIndex = (id: string, elements: SubmissionElement[], currentPath: PathIndex = []): PathIndex | null => {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        if (element.id === id) {
          return [...currentPath, i];
        }

        if (element.children) {
          const childPath = findPathIndex(id, element.children, [...currentPath, i]);

          if (childPath) {
            return childPath;
          }
        }
      }

      return null;
    };

    return elementIds.map((id) => findPathIndex(id, submissionRef.current.elements) || []);
  };

  const getElementAndParent = (pathIndex: PathIndex): [SubmissionElement | undefined, SubmissionElement | undefined] => {
    let currentElements = submissionRef.current.elements;
    let parentElement: SubmissionElement | undefined;
    let targetElement: SubmissionElement | undefined;

    for (let i = 0; i < pathIndex.length; i++) {
      const index = pathIndex[i];

      if (index < 0 || index >= currentElements.length) {
        return [undefined, undefined];
      }

      parentElement = targetElement;
      targetElement = currentElements[index];
      currentElements = targetElement.children || [];
    }

    return [targetElement, parentElement];
  };

  const updateElements = async ({ elements }: { elements: SubmissionElement[] }) => {
    elements.forEach((element) => {
      const pathIndex = getElementPathIndexes([element.id!])[0];
      const [targetElement] = getElementAndParent(pathIndex);

      if (targetElement) {
        targetElement.content = {
          ...targetElement.content,
          ...element.content,
        };
      }
    });

    updateSubmissionElements(submissionRef.current._id, { elements }).then((res) => {
      submissionRef.current = cloneDeep(res);
      triggerUpdate();
    });
  };

  const updateCommentThreads = (elementId: string, commentThreads: CommentThread[]) => {
    commentThreadsDictionary[elementId] = commentThreads;

    submissionRef.current.commentThreadsDictionary = {
      ...commentThreadsDictionary,
    };

    setCommentThreadsDictionary(submissionRef.current.commentThreadsDictionary);

    triggerUpdate();
  };

  return {
    submission: submissionRef.current,
    setSubmission: loadSubmission,
    commentFunctions,
    submissionFunctions: {
      updateMeta,
      updateElements,
      getElementPathIndexes,
      getElementAndParent,
      getIndentationLevel,
      getElementCommentThreads,
      updateCommentThreads,
      formMembers: submissionRef.current?.users ?? [],
      role,
    },
  };
}

export default useSubmissionManager;
