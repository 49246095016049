import { Text, Flex, VStack, Modal, ModalOverlay, HStack, ModalContent, ModalBody, Icon, Tooltip, useToast, Spinner } from "@chakra-ui/react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { LuminosModalHeader } from "./LuminosModalHeader";
import { useEffect, useState } from "react";
import { Spacer, SpacerSizes } from "../Spacer";
import { useFormAPI } from "../../endpoints/forms";
import { useUserAPI } from "../../endpoints/user";

interface ApproveSharingModalProps {
  document: any;
  type: string;
  isOpen: boolean;
  onClose: () => void;
}

const UserRow = ({ user, document, type, callback }: { user: any; document: any; type: string; callback: (x: string) => void }) => {
  const [loading, setLoading] = useState(false);
  const { approveFormUser, removeFormUser } = useFormAPI();
  const toast = useToast();

  const approveUser = async () => {
    setLoading(true);
    await approveFormUser(document._id, user._id);
    callback(user._id);
    setLoading(false);

    toast({
      title: "User approved",
      description: "User has been granted access to the form.",
      status: "success",
      containerStyle: {
        textColor: "white",
      },
    });
  };

  const rejectUser = async () => {
    setLoading(true);
    await removeFormUser(document._id, user._id);
    callback(user._id);
    setLoading(false);

    toast({
      title: "User rejected",
      description: "User has been rejected from accessing the form.",
      status: "success",
      containerStyle: {
        textColor: "white",
      },
    });
  };

  return (
    <Flex gap={2} w="full" style={{ alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>
      {loading ? (
        <Flex gap={2} alignItems="center">
          <div>
            <Spinner thickness="2px" color="brightblue.500" emptyColor="gray.200" />
          </div>
          <Text>Updating...</Text>
        </Flex>
      ) : (
        <>
          <Flex key={user.id} w="full" alignItems="center" justifyContent="space-between">
            <Text>{user.email}</Text>
            <Text fontSize={"12px"}>{`${user.role}, pending legal approval`}</Text>
          </Flex>
          <Tooltip label={`Approve user access to ${type}`}>
            <Icon onClick={approveUser} style={{ cursor: "pointer" }} as={CheckCircleOutlineIcon} color="green.500" />
          </Tooltip>
          <Tooltip label={`Reject user access to ${type}`}>
            <Icon onClick={rejectUser} style={{ cursor: "pointer" }} as={HighlightOffIcon} color="red.500" />
          </Tooltip>
        </>
      )}
    </Flex>
  );
};

export const ApproveSharingModal = ({ document, type, isOpen, onClose }: ApproveSharingModalProps) => {
  const [usersToApprove, setUsersToApprove] = useState<any[]>([]);
  const { getUserById } = useUserAPI();
  const [enrichedUsers, setEnrichedUsers] = useState<any[]>([]);

  const callback = (userId: string) => {
    const remainingUsers = usersToApprove?.filter((user) => user.userId !== userId);
    setUsersToApprove(remainingUsers);

    if (remainingUsers.length === 0) {
      onClose();
    }
  };

  useEffect(() => {
    const enrichUsersPendingApproval = async () => {
      if (usersToApprove) {
        const responses = await Promise.all(
          usersToApprove.map(async (user) => {
            const res = await getUserById(user.userId);
            return { ...res.user, role: user.role };
          })
        );

        setEnrichedUsers(responses);
      }
    };

    enrichUsersPendingApproval();
  }, [usersToApprove]);

  useEffect(() => {
    if (document) {
      setUsersToApprove(document.users.filter((user: any) => !user.isApproved));
    }
  }, [document]);

  return (
    <Modal isOpen={isOpen && usersToApprove.length > 0} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="white">
        <LuminosModalHeader title={`${type === "form" ? "Form" : "Submission"} Users Pending Approval`} closeButton={false} />
        {usersToApprove && (
          <ModalBody>
            <Spacer y={SpacerSizes.sm} />
            <VStack>
              {enrichedUsers.map((user: any) => (
                <UserRow key={user._id} callback={callback} user={user} document={document} type={type} />
              ))}
            </VStack>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
