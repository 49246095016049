import { useAPIRequest } from "../hooks/useAPI";
import { Form, FormUser } from "../types";

export const useFormAPI = () => {
  const sendAPIRequest = useAPIRequest();

  const updateFormElements = async (formId: string, form: Form) => {
    const res: any = await sendAPIRequest(`forms/${formId}/elements`, "PUT", {
      elements: form.elements,
      commentThreadsDictionary: form.commentThreadsDictionary,
    });
    return res;
  };

  const getFormById = async (formId: string) => {
    const res: any = await sendAPIRequest(`forms/${formId}`);
    return res;
  };

  const updateFormTitle = async (formId: string, title: string) => {
    const res: any = await sendAPIRequest(`forms/${formId}/title`, "PUT", {
      title: title,
    });
    return res;
  };

  const updateFormDescription = async (formId: string, description: string) => {
    const res: any = await sendAPIRequest(`forms/${formId}/description`, "PUT", {
      description: description,
    });
    return res;
  };

  const addUsersToForm = async (formId: string, users: FormUser[]) => {
    return await sendAPIRequest(`forms/${formId}/users`, "POST", { users });
  };

  const copyForm = async (formId: string) => {
    return await sendAPIRequest(`forms/${formId}`, "COPY");
  };

  const archiveForm = async (formId: string) => {
    return await sendAPIRequest(`forms/${formId}/archive`, "PUT");
  };

  const unarchiveForm = async (formId: string) => {
    return await sendAPIRequest(`forms/${formId}/unarchive`, "PUT");
  };

  const getForms = async (params?: string) => {
    const url = params ? `forms?${params}` : `forms`;
    const res: any = await sendAPIRequest(url);
    return res;
  };

  const createNewForm = async (form: Form) => {
    const res: any = await sendAPIRequest(`forms`, "POST", form);
    return res;
  };

  const approveFormUser = async (formId: string, userId: string) => {
    return await sendAPIRequest(`forms/${formId}/user-approval/${userId}`, "PUT");
  };

  const removeFormUser = async (formId: string, userId: string) => {
    return await sendAPIRequest(`forms/${formId}/user/${userId}`, "DELETE");
  };

  return {
    updateFormElements,
    getFormById,
    updateFormTitle,
    addUsersToForm,
    copyForm,
    archiveForm,
    unarchiveForm,
    getForms,
    createNewForm,
    updateFormDescription,
    approveFormUser,
    removeFormUser,
  };
};
