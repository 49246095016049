import {
  ModalFooter,
  ModalBody,
  VStack,
  Text,
  HStack,
  ListItem,
  UnorderedList,
  Alert,
  Switch,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import { useState } from "react";
import { useAPIRequest } from "../../hooks/useAPI";
import { LuminosModalHeader } from "./LuminosModalHeader";

export const PrivilegeConfigModal = ({
  isOpen,
  onClose,
  type,
  id,
  setDisplayPrivilegeBanner,
  setCurrentDocumentIsPrivileged,
  setDisplayPrivilegeConfirmation,
  currentDocumentPrivilegeWarningsIgnored,
  setCurrentDocumentIgnorePrivilegeWarnings,
  currentDocumentIsPrivileged,
}: {
  isOpen: boolean;
  onClose: () => void;
  type: "forms" | "submissions";
  id: string;
  setDisplayPrivilegeBanner: (x: boolean) => void;
  setCurrentDocumentIsPrivileged: (x: boolean) => void;
  setDisplayPrivilegeConfirmation: (x: boolean) => void;
  setCurrentDocumentIgnorePrivilegeWarnings: (x: boolean) => void;
  currentDocumentPrivilegeWarningsIgnored: boolean;
  currentDocumentIsPrivileged: boolean;
}) => {
  const sendAPIRequest = useAPIRequest();
  const [enablingPrivilege, setEnablingPrivilege] = useState(false);
  const [disablingPrivilege, setDisablingPrivilege] = useState(false);
  const modalWidth = "600px";

  function enablePrivilege() {
    setEnablingPrivilege(!enablingPrivilege);
  }

  async function storePrivilegeState(newPrivilegeState: boolean) {
    try {
      const res: any = await sendAPIRequest(`${type}/${id}/privileged`, "PUT", {
        privileged: newPrivilegeState,
      });
      if (res.message) {
        setDisplayPrivilegeBanner(newPrivilegeState);
        setCurrentDocumentIsPrivileged(newPrivilegeState);
        setDisplayPrivilegeConfirmation(newPrivilegeState);
        if (newPrivilegeState) {
          setCurrentDocumentIgnorePrivilegeWarnings(false);
        }
        return true;
      }
      // TODO error modal
      return false;
    } catch (e) {
      // TODO error modal
      return false;
    }
  }

  async function setAndSavePrivilegeassertion() {
    await storePrivilegeState(true);
    onClose();
  }

  async function dismissPrivilegeWarning() {
    try {
      const res: any = await sendAPIRequest(`${type}/${id}/privileged-warning-dismissed`, "PUT", {
        privilegeWarningDismissed: true,
      });
      if (res.message) {
        setCurrentDocumentIgnorePrivilegeWarnings(true);
      } else {
        // TODO error modal
      }
      onClose();
    } catch (e) {
      // TODO error modal
      onClose();
    }
  }

  const DisabledBody = () => {
    return (
      <>
        <ModalBody bg="white">
          <VStack spacing="12px" mt="8px">
            <Alert status={currentDocumentPrivilegeWarningsIgnored ? "warning" : "error"} variant="left-accent">
              Legal Privilege Not Asserted.
            </Alert>
            <HStack w="full">
              <Switch size="sm" isChecked={enablingPrivilege} onChange={enablePrivilege} colorScheme={"brightblue"} />
              <Text mt="20px" mb="20px">
                Assert Legal Privilege
              </Text>
            </HStack>
            {enablingPrivilege ? (
              <>
                <Text w="full">When asserting privilege...</Text>
                <UnorderedList w="full" ml="160px" mr="100px">
                  <ListItem>Form and submission collaborators must be approved a person with the Manage Legal Privilege permission.</ListItem>
                  <ListItem>All collaborators must agree to your communications and confidentiality statement.</ListItem>
                  <ListItem>A privilege assertion banner will be displayed.</ListItem>
                </UnorderedList>
              </>
            ) : (
              <></>
            )}
          </VStack>
        </ModalBody>

        {enablingPrivilege ? (
          <ModalFooter bg="white">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={setAndSavePrivilegeassertion}>Save Assertion</Button>
          </ModalFooter>
        ) : (
          <ModalFooter bg="white">
            {!currentDocumentPrivilegeWarningsIgnored ? (
              <Button variant="tertiary" onClick={dismissPrivilegeWarning}>
                Dismiss Warning
              </Button>
            ) : (
              <></>
            )}
            <Button variant="secondary" onClick={onClose}>
              Acknowledge
            </Button>
          </ModalFooter>
        )}
      </>
    );
  };

  function disablePrivilege() {
    setDisablingPrivilege(!disablingPrivilege);
  }

  async function clearAndSavePrivilegeassertion() {
    await storePrivilegeState(false);
    onClose();
  }

  const EnabledBody = () => {
    return (
      <>
        <ModalBody bg="white">
          <VStack spacing="12px" mt="8px">
            <Alert colorScheme={disablingPrivilege ? "gray" : "green"} variant="left-accent">
              Legal privilege being asserted.
            </Alert>
            <HStack w="full">
              <Switch size="sm" isChecked={!disablingPrivilege} onChange={disablePrivilege} colorScheme={"brightblue"} />
              <Text mt="20px" mb="20px">
                Assert Legal Privilege
              </Text>
            </HStack>
            {disablingPrivilege ? (
              <>
                <Alert status="error" variant="left-accent">
                  You are about to stop asserting legal privilege.
                </Alert>
                <Text w="full">When asserting privilege...</Text>
                <UnorderedList w="full" ml="160px" mr="100px">
                  <ListItem>Form and submission collaborators must be approved by reviewers.</ListItem>
                  <ListItem>All collaborators must agree to your communications and confidentiality statement.</ListItem>
                  <ListItem>A privilege assertion banner will be displayed.</ListItem>
                </UnorderedList>
              </>
            ) : (
              <></>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter bg="white">
          {!disablingPrivilege ? (
            <Button onClick={onClose}>Close</Button>
          ) : (
            <>
              <Button onClick={onClose}>Close</Button>
              <Button variant="secondary" onClick={clearAndSavePrivilegeassertion}>
                Remove Privilege
              </Button>
            </>
          )}
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minWidth={modalWidth}>
        <LuminosModalHeader title={"Legal Privilege Configuration"} closeButton={false} />
        {currentDocumentIsPrivileged ? <EnabledBody></EnabledBody> : <DisabledBody></DisabledBody>}
      </ModalContent>
    </Modal>
  );
};
