// reducers.js
import { UserQuery } from "../types";
import { SET_GLOBAL_USER_INFO, SET_ORGANIZATION_ID, SET_ORGANIZATION_PRIVILEGE } from "../actions/authActions";

// reducer is a function that receives the current state and an action object, decides how to update the state if necessary, and returns the new state.

export interface IAuthState {
  globalUserInfo: UserQuery;
  organizationId: string;
  userRole: string;
}

const initialState = {
  globalUserInfo: {} as UserQuery,
  organizationId: "",
  userRole: "adjudicator", // Assuming a default role, adjust as necessary
};

function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_GLOBAL_USER_INFO:
      return {
        ...state,
        globalUserInfo: action.payload,
      };
    case SET_ORGANIZATION_ID:
      return {
        ...state,
        organizationId: action.payload,
      };

    case SET_ORGANIZATION_PRIVILEGE:
      return {
        ...state,
        globalUserInfo: {
          ...state.globalUserInfo,
          currentOrgMembership: {
            ...state.globalUserInfo?.currentOrgMembership,
            organization: {
              ...state.globalUserInfo?.currentOrgMembership?.organization,
              privilegeSettings: action.payload,
            },
          },
        },
      };

    default:
      return state;
  }
}

export default authReducer;
