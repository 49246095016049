import { useState, useEffect, ReactElement } from "react";
import { Button, Center, Icon, Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, Textarea } from "@chakra-ui/react";
import { LuminosModalHeader } from "./LuminosModalHeader";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import { useFormAPI } from "../../endpoints/forms";

export const FormDescriptionModal = ({
  isOpen,
  onClose,
  formId,
  formDescription,
}: {
  isOpen: boolean;
  onClose: () => void;
  formId: string | null;
  formDescription?: string;
}) => {
  const { updateFormDescription } = useFormAPI();
  function wrappedAssignIcon(): ReactElement {
    return <Icon as={AssignmentIndOutlinedIcon} ml="4px"></Icon>;
  }
  const [description, setDescription] = useState(formDescription);

  useEffect(() => {
    setDescription(formDescription);
  }, [formId, formDescription]);

  async function saveDescription() {
    const res: any = await updateFormDescription(formId!, description!);
    if (!res.error) {
      // TODO display success modal
    }
    // TODO display error modal
    onClose();
  }

  function setDescriptionWrapper(event: any) {
    setDescription(event.target.value);
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth="500px">
          <LuminosModalHeader title={"Form Description"} closeButton={false} iconWrapper={wrappedAssignIcon()} />
          <ModalBody bg="white">
            <Center>
              <Textarea
                placeholder="a description of the usage of this form"
                size="sm"
                w="460px"
                value={description}
                onChange={setDescriptionWrapper}
              ></Textarea>
            </Center>
          </ModalBody>
          <ModalFooter bg="white">
            <Button variant="secondary" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={saveDescription}>Save Description</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
