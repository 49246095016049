import { useEffect, useState } from "react";
import { Box, Text, Button, Flex, Grid, GridItem, Input, VStack, Textarea } from "@chakra-ui/react";
import { UserQuery } from "../../types";
import { useSelector } from "react-redux";
import store from "../../store";
import { setGlobalUserInfo } from "../../actions/authActions";
import { useUserAPI, useOrganizationAPI } from "../../endpoints/_index";

export default function SettingsTabPanel() {
  const { organizationId } = useSelector((state: any) => state.auth);
  const [readOnlyInfo, setReadOnlyInfo] = useState<UserQuery>({} as UserQuery);
  const [saveNeeded, setSaveNeeded] = useState(false);
  const [settingsScratch, setSettingsScratch] = useState<UserQuery>({} as UserQuery);
  const { getUser } = useUserAPI();
  const { updateOrganization } = useOrganizationAPI();

  const getUserInfo = async () => {
    const qr: any = await getUser();
    setReadOnlyInfo(qr as UserQuery);
    setSettingsScratch(qr as UserQuery);
    store.dispatch(setGlobalUserInfo(qr! as UserQuery));
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    getUserInfo();
  }, [organizationId]);

  function updateOrgName(event: any) {
    if (!settingsScratch || !settingsScratch.currentOrgMembership || !settingsScratch.currentOrgMembership.organization) return;
    const newScratch = {
      ...settingsScratch,
      currentOrgMembership: {
        ...settingsScratch.currentOrgMembership,
        organization: {
          ...settingsScratch.currentOrgMembership.organization,
          name: event.target.value,
        },
      },
    };
    setSettingsScratch(newScratch);
    setSaveNeeded(true);
  }

  function updateOrgDescription(event: any) {
    if (!settingsScratch || !settingsScratch.currentOrgMembership || !settingsScratch.currentOrgMembership.organization) return;
    const newScratch = {
      ...settingsScratch,
      currentOrgMembership: {
        ...settingsScratch.currentOrgMembership,
        organization: {
          ...settingsScratch.currentOrgMembership.organization,
          description: event.target.value,
        },
      },
    };
    setSettingsScratch(newScratch);
    setSaveNeeded(true);
  }

  function resetChanges() {
    const newScratch = {
      ...readOnlyInfo,
    };
    setSettingsScratch(newScratch);
    setSaveNeeded(false);
  }

  const saveChanges = async () => {
    await updateOrganization({
      name: settingsScratch!.currentOrgMembership!.organization!.name,
      description: settingsScratch!.currentOrgMembership!.organization!.description,
    });
    await getUser();
    setSaveNeeded(false);
    store.dispatch(setGlobalUserInfo(settingsScratch! as UserQuery));
  };

  return (
    <>
      <Flex alignContent="">
        <Box w="800px">
          <Grid templateRows="repeat(8, 1fr)" templateColumns="repeat(4, 1fr)" gap="3">
            <GridItem colSpan={1} rowSpan={1} alignSelf="center">
              <Text align="right">Organization Name</Text>
            </GridItem>
            <GridItem colSpan={2} rowSpan={1}>
              <Input
                placeholder="Organization Name"
                size="md"
                value={settingsScratch?.currentOrgMembership?.organization?.name}
                onChange={updateOrgName}
              ></Input>
            </GridItem>
            <GridItem colSpan={1}></GridItem>
            <GridItem colSpan={1} rowSpan={1} alignSelf="center">
              <Text align="right">Organization Description</Text>
            </GridItem>
            <GridItem colSpan={3} rowSpan={3}>
              <Textarea
                placeholder="Organization Description"
                size="md"
                value={settingsScratch?.currentOrgMembership?.organization?.description}
                onChange={updateOrgDescription}
              ></Textarea>
            </GridItem>
          </Grid>
        </Box>
        <VStack ml="20px">
          {saveNeeded ? (
            <>
              <Button onClick={saveChanges}>Save</Button>
              <Button variant="secondary" onClick={resetChanges}>
                Cancel
              </Button>
            </>
          ) : (
            <></>
          )}
        </VStack>
      </Flex>
    </>
  );
}
