import Section from "./Section";
import TextBlock from "./TextBlock";
import TextResponse from "./TextResponse";
import SingleSelect from "./SingleSelect";
import Attestation from "./Attestation";
import MultiSelect from "./MultiSelect";
import BiasAnalysis from "./BiasAnalysis";
import FileUpload from "./FileUpload";

const ElementMap = {
  section: {
    build: Section.BuildView,
    edit: Section.EditView,
    editViewDebouncedChanges: Section.EditViewDebouncedChanges,
    buildViewDebouncedChanges: Section.BuildViewDebouncedChanges,
  },
  textBlock: {
    build: TextBlock.BuildView,
    edit: TextBlock.EditView,
    editViewDebouncedChanges: TextBlock.EditViewDebouncedChanges,
    buildViewDebouncedChanges: TextBlock.BuildViewDebouncedChanges,
  },
  textResponse: {
    build: TextResponse.BuildView,
    edit: TextResponse.EditView,
    editViewDebouncedChanges: TextResponse.EditViewDebouncedChanges,
    buildViewDebouncedChanges: TextResponse.BuildViewDebouncedChanges,
  },
  singleSelect: {
    build: SingleSelect.BuildView,
    edit: SingleSelect.EditView,
    editViewDebouncedChanges: SingleSelect.EditViewDebouncedChanges,
    buildViewDebouncedChanges: SingleSelect.BuildViewDebouncedChanges,
  },
  attestation: {
    build: Attestation.BuildView,
    edit: Attestation.EditView,
    editViewDebouncedChanges: Attestation.EditViewDebouncedChanges,
    buildViewDebouncedChanges: Attestation.BuildViewDebouncedChanges,
  },
  multiSelect: {
    build: MultiSelect.BuildView,
    edit: MultiSelect.EditView,
    editViewDebouncedChanges: MultiSelect.EditViewDebouncedChanges,
    buildViewDebouncedChanges: MultiSelect.BuildViewDebouncedChanges,
  },
  biasAnalysis: {
    build: BiasAnalysis.BuildView,
    edit: BiasAnalysis.EditView,
    editViewDebouncedChanges: FileUpload.EditViewDebouncedChanges,
    buildViewDebouncedChanges: FileUpload.BuildViewDebouncedChanges,
  },
  fileUpload: {
    build: FileUpload.BuildView,
    edit: FileUpload.EditView,
    editViewDebouncedChanges: FileUpload.EditViewDebouncedChanges,
    buildViewDebouncedChanges: FileUpload.BuildViewDebouncedChanges,
  },
};

export default ElementMap;
