import { useSelector } from "react-redux";

export const useAPIRequest = () => {
  const { globalUserInfo, organizationId } = useSelector((state: any) => state.auth);

  const sendAPIRequest = async (
    route: string,
    method: "GET" | "POST" | "COPY" | "PUT" | "PATCH" | "DELETE" = "GET",
    body?: { [key: string]: any },
    organizationIdOverride?: string
  ) => {
    const token = sessionStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Organization: organizationIdOverride ?? organizationId,
      User: globalUserInfo?.user?._id ?? "",
    };

    const options: RequestInit = {
      method,
      headers,
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL!}/${route}`, options);
    const data = await response.json();
    return data;
  };

  return sendAPIRequest;
};
