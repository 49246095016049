import { Flex, Icon, Wrap, WrapItem, Text, Divider } from "@chakra-ui/react";
import { TbSectionSign } from "react-icons/tb";
import { TbMathFunction } from "react-icons/tb";
import { Colors } from "../../../theme/colors";
import { AttachFile, Approval, EditNote, ViewHeadline, Checklist, Check } from "@mui/icons-material";
import _ from "lodash";
import TextResponse from "../../Elements/TextResponse";
import TextBlock from "../../Elements/TextBlock";
import SingleSelect from "../../Elements/SingleSelect";
import Section from "../../Elements/Section";
import Attestation from "../../Elements/Attestation";
import MultiSelect from "../MultiSelect";
import BiasAnalysis from "../../Elements/BiasAnalysis";
import FileUpload from "../FileUpload";

interface IOption {
  icon: any;
  title: string;
  node: any;
}

export default function SelectStaticComponent({ onSelect }: { onSelect: (x: Element) => void }) {
  const surveyOptions: IOption[] = [
    {
      icon: EditNote,
      title: "Text Response",
      node: _.cloneDeep(TextResponse.Default),
    },
    {
      icon: Checklist,
      title: "Multi Select",
      node: _.cloneDeep(MultiSelect.Default),
    },
    {
      icon: Check,
      title: "Single Select",
      node: _.cloneDeep(SingleSelect.Default),
    },
    {
      icon: AttachFile,
      title: "File Attachment",
      node: _.cloneDeep(FileUpload.Default),
    },
    {
      icon: Approval,
      title: "Attestation",
      node: _.cloneDeep(Attestation.Default),
    },
  ];

  const staticOptions: IOption[] = [
    {
      icon: TbSectionSign,
      title: "Section",
      node: _.cloneDeep(Section.Default),
    },
    {
      icon: ViewHeadline,
      title: "Text Block",
      node: _.cloneDeep(TextBlock.Default),
    },
  ];

  const variableOptions: IOption[] = [
    {
      icon: TbMathFunction,
      title: "Bias Analysis",
      node: _.cloneDeep(BiasAnalysis.Default),
    },
  ];

  const OptionCard = ({ option }: { option: IOption }) => (
    <Flex
      onMouseDown={() => onSelect(option.node)}
      _hover={{ cursor: "pointer", backgroundColor: Colors.gray[50] }}
      style={{
        width: 230,
        flexDirection: "row",
      }}
      align={"center"}
      gap={4}
      py={2}
      px={1}
    >
      <Icon color="gray.500" as={option.icon} boxSize={6} />
      <Text variant="h2">{option.title}</Text>
    </Flex>
  );

  return (
    <Flex contentEditable={false} style={{ userSelect: "none" }} flexDirection={"row"} alignItems={"center"}>
      <Wrap>
        {surveyOptions.map((option, index) => (
          <WrapItem key={index}>
            <div className="panel" key={index}>
              <OptionCard option={option} />
            </div>
          </WrapItem>
        ))}
        <Divider />
        {staticOptions.map((option, index) => (
          <WrapItem key={index}>
            <div className="panel" key={index}>
              <OptionCard option={option} />
            </div>
          </WrapItem>
        ))}
        <Divider />
        {variableOptions.map((option, index) => (
          <WrapItem key={index}>
            <div className="panel" key={index}>
              <OptionCard option={option} />
            </div>
          </WrapItem>
        ))}
      </Wrap>
    </Flex>
  );
}
