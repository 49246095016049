import {
  Button,
  Input,
  Grid,
  GridItem,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { LuminosModalHeader } from "./LuminosModalHeader";
import { User } from "../../types";

export const EmailAddressChangeModal = ({ userInfo }: { userInfo: User }) => {
  const [requestedEmail, setRequestedEmail] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  function requestEmailAddressChange() {}

  return (
    <>
      <Button variant="secondary" w="fit-content" size="md" onClick={onOpen}>
        Request Change
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth="600px">
          <LuminosModalHeader
            title={"Email Address Change Request"}
            closeButton={true}
          />
          <ModalBody bg="white">
            <Text>
              Email Address changes require assistance from Luminos.AI support
              and may cause you to lose access to certain organizations. If
              youEmail Address changes require assistance from Luminos.AI
              support and may cause you to lose access to certain
              organizations.d like to proceed, please enter a new email address
              below.
            </Text>
            <VStack spacing="8px" mt="10px" ml="20px">
              <Text w="full">Request email address change</Text>
              <Grid
                w="70%"
                templateRows="repeat(2, 1fr)"
                templateColumns="repeat(4, 1fr)"
                gap={4}
              >
                <GridItem rowSpan={1} colSpan={1}>
                  <Text textAlign="right">From:</Text>
                </GridItem>
                <GridItem rowSpan={1} colSpan={3}>
                  <Input
                    placeholder="Email Address"
                    size="sm"
                    isDisabled={true}
                    value={userInfo?.email}
                  ></Input>
                </GridItem>
                <GridItem rowSpan={1} colSpan={1}>
                  <Text textAlign="right">To:</Text>
                </GridItem>
                <GridItem rowSpan={1} colSpan={3}>
                  <Input
                    placeholder="Requested Email Address"
                    size="sm"
                    value={requestedEmail}
                    onChange={(e) => setRequestedEmail(e.target.value)}
                  ></Input>
                </GridItem>
              </Grid>
            </VStack>
          </ModalBody>

          <ModalFooter bg="white">
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="secondary"
              isDisabled={requestedEmail.length == 0}
              onClick={requestEmailAddressChange}
            >
              Request Change
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
