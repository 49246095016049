import React, { useState, useEffect, ReactElement, ReactNode } from "react";
import {
  Box,
  Center,
  Checkbox,
  HStack,
  Icon,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Fade,
  Spacer,
  Text,
  Button,
  Tooltip,
  VStack,
  Stack,
  Wrap,
  WrapItem,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import { LuminosModalHeader } from "./LuminosModalHeader";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateField } from "@mui/x-date-pickers/DateField";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { OrganizationMembership, OrganizationPermissions, MembershipDetail } from "../../types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useOrganizationAPI } from "../../endpoints/organization";

const orgRoleTemplateNames = {
  governor: "Governor",
  standardMember: "Standard Member",
  standardGuest: "Standard Guest",
  custom: "Custom",
};

export function OrganizationMembershipModal({
  isOpen,
  onClose,
  setRefreshMemberships,
  organizationName,
  membershipToEdit,
}: {
  isOpen: boolean;
  onClose: () => void;
  setRefreshMemberships: () => void;
  organizationName: string;
  membershipToEdit: MembershipDetail | null;
}) {
  const { isOpen: successModalIsOpen, onOpen: openSuccessModal, onClose: closeSuccessModal } = useDisclosure();

  const { isOpen: errorModalIsOpen, onOpen: openErrorModal, onClose: closeErrorModal } = useDisclosure();

  const { inviteNewUser, updateOrganizationMembership, refreshOrganizationMembers } = useOrganizationAPI();

  function determineOrgRoleTemplate(membership: MembershipDetail) {
    if (
      membership.permissions.manageOrgPermissions === true &&
      membership.permissions.manageOrgSettings === true &&
      membership.permissions.inviteUser === true &&
      membership.permissions.manageOrgTags === true &&
      membership.permissions.manageLegalPrivilege === true &&
      membership.permissions.manageAllGroups === true &&
      membership.permissions.seeAllGroups === true &&
      membership.permissions.createOrCopyForms === true &&
      membership.permissions.createGroups === true &&
      membership.permissions.seeAllMembers === true &&
      membership.permissions.seeAllGuests === true
    ) {
      return orgRoleTemplateNames.governor;
    } else if (
      membership.permissions.manageOrgPermissions === false &&
      membership.permissions.manageOrgSettings === false &&
      membership.permissions.inviteUser === true &&
      membership.permissions.manageOrgTags === false &&
      membership.permissions.manageLegalPrivilege === false &&
      membership.permissions.manageAllGroups === false &&
      membership.permissions.seeAllGroups === false &&
      membership.permissions.createOrCopyForms === false &&
      membership.permissions.createGroups === false &&
      membership.permissions.seeAllMembers === false &&
      membership.permissions.seeAllGuests === false
    ) {
      return orgRoleTemplateNames.standardGuest;
    } else if (
      membership.permissions.manageOrgPermissions === false &&
      membership.permissions.manageOrgSettings === false &&
      membership.permissions.inviteUser === true &&
      membership.permissions.manageOrgTags === false &&
      membership.permissions.manageLegalPrivilege === false &&
      membership.permissions.manageAllGroups === false &&
      membership.permissions.seeAllGroups === true &&
      membership.permissions.createOrCopyForms === false &&
      membership.permissions.createGroups === true &&
      membership.permissions.seeAllMembers === true &&
      membership.permissions.seeAllGuests === false
    ) {
      return orgRoleTemplateNames.standardMember;
    }
    return orgRoleTemplateNames.custom;
  }

  function getDefaultState() {
    if (!membershipToEdit) {
      return {
        email: "",
        firstName: "",
        lastName: "",
        orgMembership: {
          membershipType: "guest",
          permissions: {
            manageOrgPermissions: false,
            manageOrgSettings: false,
            inviteUser: true,
            manageOrgTags: false,
            manageLegalPrivilege: false,
            manageAllGroups: false,
            seeAllGroups: false,
            createOrCopyForms: false,
            createGroups: false,
            seeAllMembers: false,
            seeAllGuests: false,
          } as OrganizationPermissions,
        } as OrganizationMembership,
        orgRoleTemplateName: orgRoleTemplateNames.standardGuest,
        showCustom: false,
        emailValid: false,
      };
    } else {
      return {
        email: membershipToEdit.user.email,
        firstName: membershipToEdit.user.firstName,
        lastName: membershipToEdit.user.lastName,
        orgMembership: {
          _id: membershipToEdit._id,
          userId: membershipToEdit.userId,
          organizationId: membershipToEdit.organizationId,
          membershipType: membershipToEdit.membershipType,
          permissions: {
            ...membershipToEdit.permissions,
          } as unknown as OrganizationPermissions,
        } as OrganizationMembership,
        orgRoleTemplateName: determineOrgRoleTemplate(membershipToEdit),
        showCustom: false,
        emailValid: true,
      };
    }
  }
  const [userInfo, setUserInfo] = useState(getDefaultState());
  const [errorMessage, setErrorMessage] = useState("");
  const [membershipEdited, setMembershipEdited] = useState(false);
  const [expireNowVsScheduled, setExpireNowVsScheduled] = useState("immediately");
  useEffect(() => {
    setUserInfo(getDefaultState());
    setMembershipEdited(false);
    if (membershipToEdit?.membershipExpiration) {
      setExpirationDateTime(moment(membershipToEdit.membershipExpiration));
      setExpirationEnabled(true);
    }
  }, [membershipToEdit]);

  function changeRoleTemplate(templateName: any, userType: string, isMember: boolean) {
    if (templateName == orgRoleTemplateNames.governor) {
      setUserInfo({
        ...userInfo,
        orgMembership: {
          ...userInfo.orgMembership,
          membershipType: userType,
          permissions: {
            manageOrgPermissions: true,
            manageOrgSettings: true,
            inviteUser: true,
            manageOrgTags: true,
            manageLegalPrivilege: true,
            manageAllGroups: true,
            seeAllGroups: true,
            createOrCopyForms: true,
            createGroups: true,
            seeAllMembers: true || isMember,
            seeAllGuests: true,
          } as OrganizationPermissions,
        } as OrganizationMembership,
        orgRoleTemplateName: templateName,
        showCustom: false,
      });
    } else if (templateName == orgRoleTemplateNames.standardMember) {
      setUserInfo({
        ...userInfo,
        orgMembership: {
          ...userInfo.orgMembership,
          membershipType: userType,
          permissions: {
            manageOrgPermissions: false,
            manageOrgSettings: false,
            inviteUser: true,
            manageOrgTags: false,
            manageLegalPrivilege: false,
            manageAllGroups: false,
            seeAllGroups: true,
            createOrCopyForms: false,
            createGroups: true,
            seeAllMembers: true || isMember,
            seeAllGuests: false,
          } as OrganizationPermissions,
        } as OrganizationMembership,
        orgRoleTemplateName: templateName,
        showCustom: false,
      });
    } else if (templateName == orgRoleTemplateNames.standardGuest) {
      setUserInfo({
        ...userInfo,
        orgMembership: {
          ...userInfo.orgMembership,
          membershipType: userType,
          permissions: {
            manageOrgPermissions: false,
            manageOrgSettings: false,
            inviteUser: true,
            manageOrgTags: false,
            manageLegalPrivilege: false,
            manageAllGroups: false,
            seeAllGroups: false,
            createOrCopyForms: false,
            createGroups: false,
            seeAllMembers: false || isMember,
            seeAllGuests: false,
          } as OrganizationPermissions,
        } as OrganizationMembership,
        orgRoleTemplateName: templateName,
        showCustom: false,
      });
    } else {
      setUserInfo({
        ...userInfo,
        orgMembership: {
          ...userInfo.orgMembership,
          membershipType: userType,
          permissions: {
            ...userInfo.orgMembership.permissions,
            seeAllMembers: userInfo.orgMembership.permissions.seeAllMembers || isMember,
          } as OrganizationPermissions,
        } as OrganizationMembership,
        orgRoleTemplateName: templateName,
        showCustom: true,
      });
    }
    setMembershipEdited(true);
  }

  const locales = ["en-us", "en-gb", "zh-cn", "de"];
  type LocaleKey = (typeof locales)[number];
  const [locale, setLocale] = React.useState<LocaleKey>("en-us");

  if (moment.locale() !== locale) {
    moment.locale(locale);
  }

  const mTheme = createTheme({});

  const [exiprationDateTime, setExpirationDateTime] = useState(moment().endOf("day"));

  function changeRoleTemplateWrapper(newRole: string) {
    changeRoleTemplate(newRole, userInfo.orgMembership.membershipType, userInfo.orgMembership.membershipType == "member");
  }

  function wrappedAddUserIcon(): ReactElement {
    return <Icon as={PersonAddAltOutlinedIcon} ml="4px"></Icon>;
  }

  function wrappedEditUserIcon(): ReactElement {
    return <Icon as={EditOutlinedIcon} ml="4px"></Icon>;
  }

  function setEmailAddress(event: any) {
    let emailValid = false;
    const emailParts = (event.target.value as string).split("@");
    if (emailParts.length == 2 && emailParts[0].length > 0 && emailParts[1].length > 0) {
      emailValid = true;
    }
    setUserInfo({
      ...userInfo,
      email: event.target.value,
      emailValid: emailValid,
    });
  }

  function setFirstName(event: any) {
    setUserInfo({
      ...userInfo,
      firstName: event.target.value,
    });
  }

  function setLastName(event: any) {
    setUserInfo({
      ...userInfo,
      lastName: event.target.value,
    });
  }

  async function addUser() {
    if (expirationEnabled) {
      if (expireNowVsScheduled === "immediately") {
        userInfo.orgMembership.membershipExpiration = moment().toString();
      } else {
        userInfo.orgMembership.membershipExpiration = exiprationDateTime.toString();
      }
    }

    if (membershipToEdit !== null) {
      userInfo.orgMembership._id = membershipToEdit._id;
    }

    const res = membershipToEdit !== null ? await updateOrganizationMembership({ userInfo: userInfo }) : await inviteNewUser(userInfo);

    if (res.error) {
      setErrorMessage(res.error);
      openErrorModal();
    } else {
      setUserInfo(getDefaultState);
      setMembershipEdited(false);
      refreshOrganizationMembers();
      openSuccessModal();
    }
  }

  type permissionDetail = {
    variableName: string;
    text: string;
    helpText: ReactNode;
  };

  const checkboxFontSize = "xs";
  const checkboxSize = "sm";
  const checkboxTooltipOpenDelay = 800;
  const checkboxTooltipCloseDelay = 50;
  const checkboxTooltipFontSize = "sm";
  const permissionDetails: permissionDetail[] = [
    {
      variableName: "manageOrgPermissions",
      text: "Manage Permissions for All Organization Users",
      helpText: (
        <VStack align="start" spacing="0px">
          <Text fontSize={checkboxTooltipFontSize}>Essentially an administrator role. A user with this permission can:</Text>
          <Text fontSize={checkboxTooltipFontSize}> - Add users to the organization </Text>
          <Text fontSize={checkboxTooltipFontSize}> - Manage the permissions below for all users</Text>
          <Text fontSize={checkboxTooltipFontSize}> - Manage groups</Text>
          <Text fontSize={checkboxTooltipFontSize}> - Manage organization settings except</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            those related to legal privilege
          </Text>
        </VStack>
      ),
    },
    {
      variableName: "manageOrgSettings",
      text: "Manage All Settings of the Organization",
      helpText: (
        <VStack align="start" spacing="0px">
          <Text fontSize={checkboxTooltipFontSize}>A user with this role can manage all organization settings except those involving legal privilege.</Text>
        </VStack>
      ),
    },
    {
      variableName: "inviteUser",
      text: "Invite Users to the Organization",
      helpText: (
        <VStack align="start" spacing="0px">
          <Text fontSize={checkboxTooltipFontSize}>A user with this permission can invite other users via comments in Forms and Form Submissions.</Text>
          <Text fontSize={checkboxTooltipFontSize}> - Luminos.AI recommends this permission be</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            checked for nearly all users.
          </Text>
          <Text fontSize={checkboxTooltipFontSize}> - Luminos.AI strongly advises against removing</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            this permission for members of
          </Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            your organization.
          </Text>
          <Text fontSize={checkboxTooltipFontSize}> - In some sensitive situations, it may</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            be advisable to prevent external / guest
          </Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            users from sending invitations.
          </Text>
        </VStack>
      ),
    },
    {
      /* TODO - this variable name should change to manageOrganizationTags once that is changed in the backend */
      variableName: "manageOrgTags",
      text: "Manage Organization Tags",
      helpText: <Text fontSize={checkboxTooltipFontSize}>This permission allows a user to manage the organization&apos; organization-wide tags.</Text>,
    },
    {
      variableName: "manageLegalPrivilege",
      text: "Manage Legal Privilege",
      helpText: (
        <VStack align="start" spacing="0px">
          <Text fontSize={checkboxTooltipFontSize}>A user with this permission can</Text>
          <Text fontSize={checkboxTooltipFontSize}> - Mark a Form (and all of its submissions)</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            as subject to legal privilege.
          </Text>
          <Text fontSize={checkboxTooltipFontSize}> - Approve or reject the addition of users</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            and groups to Forms and Form Submissions
          </Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            that are subject to legal privilege.
          </Text>
          <Text fontSize={checkboxTooltipFontSize}> - Modify the organizations legal privilege</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            language.
          </Text>
        </VStack>
      ),
    },
    {
      variableName: "createOrCopyForms",
      text: "Create or Copy Forms",
      helpText: (
        <VStack align="start" spacing="0px">
          <Text fontSize={checkboxTooltipFontSize}>A user with this privilege can create new forms from scratch or by copying existing forms.</Text>
        </VStack>
      ),
    },
    {
      variableName: "manageAllGroups",
      text: "Manage All Groups Within the Organization",
      helpText: (
        <VStack align="start" spacing="0px">
          <Text fontSize={checkboxTooltipFontSize}>This permission allows a user manage all</Text>
          <Text fontSize={checkboxTooltipFontSize}>groups within the organization. This grants</Text>
          <Text fontSize={checkboxTooltipFontSize}>the additional permissions:</Text>
          <Text fontSize={checkboxTooltipFontSize}> - See All Groups Within the Organization</Text>
          <Text fontSize={checkboxTooltipFontSize}> - Create Groups Within the Organization</Text>
          <Text fontSize={checkboxTooltipFontSize}> - See All Organization Members</Text>
          <Text fontSize={checkboxTooltipFontSize}> - See All Organization Guests</Text>
        </VStack>
      ),
    },
    {
      variableName: "seeAllGroups",
      text: "See All Groups Within the Organization",
      helpText: (
        <Text fontSize={checkboxTooltipFontSize}>This permission allows a user to see all groups within an organization, though not who belongs to them.</Text>
      ),
    },
    {
      variableName: "createOrCopyForms",
      text: "Create or Copy Forms",
      helpText: (
        <Text fontSize={checkboxTooltipFontSize}>
          This permission allows a user to create new Forms from scratch or by copying another Form. Regardless of this permission, all users can be granted
          access to own, edit, or comment on Forms by owners or editors of those Forms.
        </Text>
      ),
    },
    {
      variableName: "createGroups",
      text: "Create Groups Within the Organization",
      helpText: <Text fontSize={checkboxTooltipFontSize}>This permission allows a user to create groups within the organization.</Text>,
    },
    {
      variableName: "seeAllMembers",
      text: "See All Organization Members",
      helpText: (
        <VStack align="start" spacing="0px">
          <Text fontSize={checkboxTooltipFontSize}>This permission allows a user to see all</Text>
          <Text fontSize={checkboxTooltipFontSize}>members of the organization even if they</Text>
          <Text fontSize={checkboxTooltipFontSize}>haven&apos;t interacted with them before.</Text>
          <Text fontSize={checkboxTooltipFontSize}> - All organization members will have</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            this permission.
          </Text>
          <Text fontSize={checkboxTooltipFontSize}> - Luminos.AI recommends very limited</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            use of this permission with organization guests.
          </Text>
        </VStack>
      ),
    },
    {
      variableName: "seeAllGuests",
      text: "See All Organization Guests (External Users)",
      helpText: (
        <VStack align="start" spacing="0px">
          <Text fontSize={checkboxTooltipFontSize}>This permission allows a user to see all</Text>
          <Text fontSize={checkboxTooltipFontSize}>guests of the organization even if they</Text>
          <Text fontSize={checkboxTooltipFontSize}>haven&apos;t interacted with them before.</Text>
          <Text fontSize={checkboxTooltipFontSize}> - Luminos.AI recommends caution when</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            granting access to this permission
          </Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            to organization members.
          </Text>
          <Text fontSize={checkboxTooltipFontSize}> - Luminos.AI recommends very limited</Text>
          <Text fontSize={checkboxTooltipFontSize} ml="8px">
            {" "}
            use of this permission with organization guests.
          </Text>
        </VStack>
      ),
    },
  ];

  function populatePermissionDetail() {
    const rows = [];
    let x = "";
    for (let i = 0; i < permissionDetails.length; i++) {
      x = permissionDetails[i].variableName;
      rows.push(
        <Checkbox
          key={i}
          isDisabled={
            !userInfo.showCustom ||
            (permissionDetails[i].variableName === "manageAllGroups" && userInfo.orgMembership.permissions.manageOrgPermissions) ||
            (permissionDetails[i].variableName === "manageOrgSettings" && userInfo.orgMembership.permissions.manageOrgPermissions) ||
            (permissionDetails[i].variableName === "inviteUser" && userInfo.orgMembership.permissions.manageOrgPermissions) ||
            (permissionDetails[i].variableName === "seeAllGroups" && userInfo.orgMembership.permissions.manageAllGroups) ||
            (permissionDetails[i].variableName === "createGroups" && userInfo.orgMembership.permissions.manageAllGroups) ||
            (permissionDetails[i].variableName === "seeAllMembers" &&
              (userInfo.orgMembership.permissions.manageAllGroups || userInfo.orgMembership.membershipType == "member")) ||
            (permissionDetails[i].variableName === "seeAllGuests" && userInfo.orgMembership.permissions.manageAllGroups)
          }
          isChecked={userInfo.orgMembership.permissions[x as keyof OrganizationPermissions] as boolean}
          size={checkboxSize}
          onChange={(event: any) => {
            if (permissionDetails[i].variableName === "manageOrgPermissions" && event.target.checked) {
              setUserInfo({
                ...userInfo,
                orgMembership: {
                  ...userInfo.orgMembership,
                  permissions: {
                    ...userInfo.orgMembership.permissions,
                    manageOrgPermissions: true,
                    manageAllGroups: true,
                    manageOrgSettings: true,
                    inviteUser: true,
                    seeAllGroups: true,
                    createGroups: true,
                  },
                },
              });
            } else if (permissionDetails[i].variableName === "manageAllGroups" && event.target.checked) {
              setUserInfo({
                ...userInfo,
                orgMembership: {
                  ...userInfo.orgMembership,
                  permissions: {
                    ...userInfo.orgMembership.permissions,
                    manageAllGroups: true,
                    seeAllGroups: true,
                    createGroups: true,
                    seeAllMembers: true,
                    seeAllGuests: true,
                  },
                },
              });
            } else {
              setUserInfo({
                ...userInfo,
                orgMembership: {
                  ...userInfo.orgMembership,
                  permissions: {
                    ...userInfo.orgMembership.permissions,
                    [permissionDetails[i].variableName]: event.target.checked,
                  },
                },
              });
            }
            setMembershipEdited(true);
          }}
        >
          <Tooltip
            fontSize={checkboxTooltipFontSize}
            openDelay={checkboxTooltipOpenDelay}
            closeDelay={checkboxTooltipCloseDelay}
            label={
              <Stack mt="4px" mb="8px">
                {permissionDetails[i].helpText}
              </Stack>
            }
          >
            <Text fontSize={checkboxFontSize}>{permissionDetails[i].text}</Text>
          </Tooltip>
        </Checkbox>
      );
    }
    return rows;
  }

  function handleRelationshipChangetoMember() {
    changeRoleTemplate(userInfo.orgRoleTemplateName, "member", true);
  }

  function handleRelationshipChangetoGuest() {
    changeRoleTemplate(userInfo.orgRoleTemplateName, "guest", false);
  }

  function closeAllModals() {
    setUserInfo(getDefaultState);
    setExpirationDateTime(moment().endOf("day"));
    setExpirationEnabled(false);
    closeSuccessModal();
    closeErrorModal();
    onClose();
    setRefreshMemberships();
  }

  function setFormatSensical() {
    setLocale("zh-cn");
  }

  function setFormatUS() {
    setLocale("en-us");
  }

  const [expirationEnabled, setExpirationEnabled] = useState<boolean>(false);

  function updateUserInfoExpiration(expiration: string | null) {
    if (expiration) {
      setUserInfo({
        ...userInfo,
        orgMembership: {
          ...userInfo.orgMembership,
          membershipExpiration: expiration,
          permissions: {
            ...userInfo.orgMembership.permissions,
          } as OrganizationPermissions,
        } as OrganizationMembership,
      });
    } else {
      setUserInfo({
        ...userInfo,
        orgMembership: {
          ...userInfo.orgMembership,
          membershipExpiration: null,
          permissions: {
            ...userInfo.orgMembership.permissions,
          } as OrganizationPermissions,
        } as OrganizationMembership,
      });
    }
  }

  function setExpirationNow() {
    const newExpiration = moment();
    setExpirationDateTime(newExpiration);
    updateUserInfoExpiration(newExpiration.toString());
  }

  function setExpirationEnabledWrapper(event: any) {
    setExpirationEnabled(event.target.checked);
    if (event.target.checked) {
      updateUserInfoExpiration(exiprationDateTime.toString());
    } else {
      updateUserInfoExpiration(null);
    }
    setMembershipEdited(true);
  }

  function handleExpirationDateChange(e: any) {
    const newExpiration = moment();
    newExpiration.set("hour", exiprationDateTime.hour());
    newExpiration.set("minute", exiprationDateTime.minute());
    newExpiration.set("year", e.year());
    newExpiration.set("month", e.month());
    newExpiration.set("day", e.day());
    newExpiration.set("seconds", e.seconds());
    setExpirationDateTime(newExpiration);
    updateUserInfoExpiration(newExpiration.toString());
    setMembershipEdited(true);
  }
  function handleExpirationTimeChange(e: any) {
    const newExpiration = moment();
    newExpiration.set("hour", e.hour());
    newExpiration.set("minute", e.minute());
    newExpiration.set("year", exiprationDateTime.year());
    newExpiration.set("month", exiprationDateTime.month());
    newExpiration.set("day", exiprationDateTime.day());
    newExpiration.set("seconds", e.seconds());
    setExpirationDateTime(newExpiration);
    updateUserInfoExpiration(newExpiration.toString());
    setMembershipEdited(true);
  }

  const modalWidth = "600px";
  const userInfoFontSize = "sm";
  return (
    <React.Fragment>
      <Modal isOpen={successModalIsOpen} onClose={closeSuccessModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent top="12rem" minW="fit-content">
          <LuminosModalHeader title={"Success"}></LuminosModalHeader>
          <ModalBody bg="white">
            <VStack>
              {membershipToEdit !== null ? (
                <Flex>{`The user's membership was successfully updated.`}</Flex>
              ) : (
                <Flex>{`The user was successfully invited.`}</Flex>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter bg="white">
            {membershipToEdit !== null ? (
              <></>
            ) : (
              <Button variant="secondary" onClick={closeSuccessModal}>
                Add Another
              </Button>
            )}
            <Button onClick={closeAllModals}>Done</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={errorModalIsOpen} onClose={closeErrorModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent top="12rem" minW="fit-content">
          <LuminosModalHeader title={"Error Inviting User"}></LuminosModalHeader>
          <ModalBody bg="white">
            <VStack>
              <Text>{errorMessage}</Text>
            </VStack>
          </ModalBody>
          <ModalFooter bg="white">
            {membershipToEdit !== null ? (
              <></>
            ) : (
              <Button variant="secondary" onClick={closeErrorModal}>
                Edit & Retry
              </Button>
            )}
            <Button onClick={closeAllModals}>Abort</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent minW="fit-content">
          {membershipToEdit ? (
            <LuminosModalHeader iconWrapper={wrappedEditUserIcon()} title={`Edit ${membershipToEdit.user.email}'s Membership`} closeButton={false} />
          ) : (
            <LuminosModalHeader iconWrapper={wrappedAddUserIcon()} title={"Invite User to Organization"} closeButton={false} />
          )}
          <ModalBody bg="white">
            <VStack spacing="0px" mt="8px" align="left">
              <Text>Relationship & Role</Text>
              <HStack ml="20px" mt="6px" spacing="0px">
                <Wrap spacing="2px">
                  <WrapItem>
                    {membershipToEdit ? (
                      <Center h="100%" fontSize="sm">
                        {`${userInfo.email} is`}
                      </Center>
                    ) : (
                      <Center h="100%" fontSize="sm">
                        The new user is
                      </Center>
                    )}
                  </WrapItem>
                  <WrapItem>
                    <Menu>
                      <WrapItem></WrapItem>
                      <MenuButton border="1px" borderRadius="3px" pt="2px" pb="2px" pl="4px" pr="4px" borderColor="gray.500" fontSize="sm">
                        {userInfo.orgMembership.membershipType == "guest" ? "external to" : "a member of"}
                      </MenuButton>
                      <MenuList>
                        <MenuItem fontSize="xs" onClick={handleRelationshipChangetoMember}>
                          a member of
                        </MenuItem>
                        <MenuItem fontSize="xs" onClick={handleRelationshipChangetoGuest}>
                          external to
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </WrapItem>
                  <WrapItem>
                    <Center h="100%" fontSize="sm">
                      {organizationName}
                    </Center>
                  </WrapItem>
                  <WrapItem>
                    <Center h="100%" fontSize="sm" ml="1px">
                      and has
                    </Center>
                  </WrapItem>
                  <WrapItem>
                    <Menu>
                      <WrapItem></WrapItem>
                      <MenuButton border="1px" borderRadius="3px" pt="2px" pb="2px" pl="4px" pr="4px" borderColor="gray.500" fontSize="sm">
                        {userInfo.orgRoleTemplateName}
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          fontSize="xs"
                          onClick={() => {
                            changeRoleTemplateWrapper("Standard Guest");
                          }}
                        >
                          Standard Guest
                        </MenuItem>
                        <MenuItem
                          fontSize="xs"
                          onClick={() => {
                            changeRoleTemplateWrapper("Standard Member");
                          }}
                        >
                          Stamdard Member
                        </MenuItem>
                        <MenuItem
                          fontSize="xs"
                          onClick={() => {
                            changeRoleTemplateWrapper("Governor");
                          }}
                        >
                          Governor{" "}
                        </MenuItem>
                        <MenuItem
                          fontSize="xs"
                          onClick={() => {
                            changeRoleTemplateWrapper("Custom");
                          }}
                        >
                          Custom
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </WrapItem>
                  <WrapItem>permissions.</WrapItem>
                </Wrap>
              </HStack>

              <Box h="20px" w="1px" bg="white.100"></Box>
              <Box h="1px" w="auto" bg="gray.500"></Box>
              <Box h="20px" w="1px" bg="white.100"></Box>
              <HStack align="start">
                <VStack align="left">
                  <Text>Basic Information</Text>
                  <HStack spacing="2px">
                    <Tooltip
                      fontSize={checkboxTooltipFontSize}
                      openDelay={checkboxTooltipOpenDelay}
                      closeDelay={checkboxTooltipCloseDelay}
                      label={
                        <Stack mt="4px" mb="8px">
                          <Text>A valid email address is required.</Text>
                        </Stack>
                      }
                    >
                      <Text fontSize={userInfoFontSize}>Email Address:</Text>
                    </Tooltip>
                    <Text fontSize={userInfoFontSize} textColor="red.500">
                      *
                    </Text>
                  </HStack>
                  <Input
                    placeholder="New User Email Address"
                    size="sm"
                    w="280px"
                    isDisabled={membershipToEdit !== null}
                    value={userInfo.email}
                    onChange={setEmailAddress}
                  ></Input>
                  <Tooltip
                    fontSize={checkboxTooltipFontSize}
                    openDelay={checkboxTooltipOpenDelay}
                    closeDelay={checkboxTooltipCloseDelay}
                    label={
                      <Stack mt="4px" mb="8px">
                        <Text>
                          {membershipToEdit
                            ? "Name must be edited by the user."
                            : "If known, please provide the user&apos;s first name. The user can override this setting."}
                        </Text>
                      </Stack>
                    }
                  >
                    <Text fontSize={userInfoFontSize}>First Name:</Text>
                  </Tooltip>
                  <Input
                    placeholder="New User First Name"
                    size="sm"
                    w="280px"
                    isDisabled={membershipToEdit !== null}
                    value={userInfo.firstName}
                    onChange={setFirstName}
                  ></Input>
                  <Tooltip
                    fontSize={checkboxTooltipFontSize}
                    openDelay={checkboxTooltipOpenDelay}
                    closeDelay={checkboxTooltipCloseDelay}
                    label={
                      <Stack mt="4px" mb="8px">
                        <Text>
                          {membershipToEdit
                            ? "Name must be edited by the user."
                            : "If known, please provide the user&apos;s last name. The user can override this setting."}
                        </Text>
                      </Stack>
                    }
                  >
                    <Text fontSize={userInfoFontSize}>Last Name:</Text>
                  </Tooltip>
                  <Input
                    placeholder="New User Last Name"
                    size="sm"
                    w="280px"
                    isDisabled={membershipToEdit !== null}
                    value={userInfo.lastName}
                    onChange={setLastName}
                  ></Input>
                  <Spacer />
                  <Checkbox onChange={setExpirationEnabledWrapper} isChecked={expirationEnabled}>
                    Set Membership Expiration
                  </Checkbox>
                  {membershipToEdit !== null && expirationEnabled ? (
                    <>
                      <Fade in={membershipToEdit !== null}>
                        <HStack>
                          <Box h="1px" w="42px"></Box>
                          <Center h="100%" fontSize="sm" mr="0px">
                            Expire
                          </Center>
                          <Menu>
                            <MenuButton border="1px" borderRadius="3px" pt="2px" pb="2px" pl="4px" pr="4px" borderColor="gray.500" fontSize="sm">
                              {expireNowVsScheduled}
                            </MenuButton>
                            <MenuList zIndex={100}>
                              <MenuItem
                                fontSize="xs"
                                onClick={() => {
                                  setExpireNowVsScheduled("immediately");
                                  setMembershipEdited(true);
                                }}
                              >
                                immediately
                              </MenuItem>
                              <MenuItem
                                fontSize="xs"
                                onClick={() => {
                                  setExpireNowVsScheduled("at a scheduled date and time");
                                  setMembershipEdited(true);
                                }}
                              >
                                at a scheduled date and time
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </HStack>
                      </Fade>
                    </>
                  ) : (
                    <></>
                  )}
                  <Fade
                    in={
                      (expirationEnabled && exiprationDateTime !== null && membershipToEdit === null) ||
                      (expirationEnabled && exiprationDateTime !== null && membershipToEdit !== null && expireNowVsScheduled === "at a scheduled date and time")
                    }
                  >
                    <HStack align="start">
                      <Spacer />
                      <VStack>
                        <ThemeProvider theme={mTheme}>
                          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                            <DateField size="small" label="Date" defaultValue={exiprationDateTime} onChange={handleExpirationDateChange} />
                            <TimeField size="small" label="Time" defaultValue={exiprationDateTime} onChange={handleExpirationTimeChange} />
                          </LocalizationProvider>
                        </ThemeProvider>
                      </VStack>
                      <Menu>
                        <MenuButton>
                          <Icon color="gray.600" as={SettingsOutlinedIcon}></Icon>
                        </MenuButton>
                        <MenuList>
                          <MenuItem fontSize="xs" onClick={setFormatSensical}>
                            YYYY-MM-DD 24hr
                          </MenuItem>
                          <MenuItem fontSize="xs" onClick={setFormatUS}>
                            DD/MM/YYYY AM/PM
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </HStack>
                  </Fade>
                  <Spacer />
                </VStack>
                <Box w="1px" h="300px" bg="gray.500"></Box>
                <Spacer />
                <VStack>
                  <VStack spacing={2} align="left">
                    <Text>Permission Detail</Text>
                    {populatePermissionDetail()}
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter bg="white">
            <Button variant="secondary" onClick={closeAllModals}>
              Cancel
            </Button>
            {membershipToEdit === null ? (
              <>
                {userInfo.emailValid ? (
                  <Button onClick={addUser}>Add User</Button>
                ) : (
                  <Button isDisabled variant="disabled" onClick={addUser}>
                    Add User
                  </Button>
                )}
              </>
            ) : (
              <>
                {" "}
                {membershipEdited ? (
                  <Button onClick={addUser}>Save Edits</Button>
                ) : (
                  <Button isDisabled variant="disabled" onClick={addUser}>
                    Save Edits
                  </Button>
                )}
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
}
