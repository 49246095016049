import { ICommentFunctions } from "@/hooks/useCommentManager";
import {
  Box,
  Center,
  HStack,
  Icon,
  MenuList,
  Menu,
  MenuItem,
  MenuButton,
  VStack,
  Wrap,
  WrapItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { useState, useEffect } from "react";

export const CommentFilter = ({ commentManagement }: { commentManagement: ICommentFunctions }) => {
  const [commentButtonActive, setCommentButtonActive] = useState(false);
  const [openCount, setOpenCount] = useState(0);
  const [resolvedCount, setResolvedCount] = useState(0);
  const [participatingOpenCount, setParticipatingOpenCount] = useState(0);
  const [participatingResolvedCount, setParticipatingResolvedCount] = useState(0);
  const [assignedOpenCount, setAssignedOpenCount] = useState(0);
  const [assignedResolvedCount, setAssignedResolvedCount] = useState(0);
  const [displayCount, setDisplayCount] = useState(getCommentProgress());

  useEffect(() => {
    setOpenCount(commentManagement.openCount());
    setResolvedCount(commentManagement.resolvedCount());
    setParticipatingOpenCount(commentManagement.participatingCount({ resolved: false }));
    setParticipatingResolvedCount(commentManagement.participatingCount({ resolved: true }));
    setAssignedOpenCount(commentManagement.assignedCount({ resolved: false }));
    setAssignedResolvedCount(commentManagement.assignedCount({ resolved: true }));
  }, [commentManagement.filteredThreadsDictionary]);

  function getCommentProgress() {
    let count: number | undefined = 0;
    switch (commentManagement.primaryCommentFilter) {
      case "all conversations":
        count = getOpenConversationsCount();
        break;
      case "conversations assigned to you":
        count = getAssignedConversationsCount();
        break;
      case "your conversations":
        count = getForConversationsCount();
        break;
    }
    if (count && count > 0) {
      if (openCount > 9) {
        return "9+";
      } else {
        return `${openCount}`;
      }
    }
    return "";
  }

  function getAssignedConversationsCount() {
    switch (commentManagement.secondaryCommentFilter) {
      case "open":
        return assignedOpenCount;
      case "resolved":
        return assignedResolvedCount;
      case "open or resolved":
        return assignedOpenCount + assignedResolvedCount;
    }
  }

  function getForConversationsCount() {
    switch (commentManagement.secondaryCommentFilter) {
      case "open":
        return participatingOpenCount;
      case "resolved":
        return participatingResolvedCount;
      case "open or resolved":
        return participatingOpenCount + participatingResolvedCount;
    }
  }

  function getOpenConversationsCount() {
    switch (commentManagement.primaryCommentFilter) {
      case "all conversations":
        return openCount;
      case "conversations assigned to you":
        return assignedOpenCount;
      case "your conversations":
        return participatingOpenCount;
    }
  }

  function getResolvedConversationsCount() {
    switch (commentManagement.primaryCommentFilter) {
      case "all conversations":
        return resolvedCount;
      case "conversations assigned to you":
        return assignedResolvedCount;
      case "your conversations":
        return participatingResolvedCount;
    }
  }

  function getOpenOrResolvedConversationsCount() {
    switch (commentManagement.primaryCommentFilter) {
      case "all conversations":
        return openCount + resolvedCount;
      case "conversations assigned to you":
        return assignedOpenCount + assignedResolvedCount;
      case "your conversations":
        return participatingOpenCount + participatingResolvedCount;
    }
  }

  function getAllConversationsCount() {
    switch (commentManagement.secondaryCommentFilter) {
      case "open":
        return openCount;
      case "resolved":
        return resolvedCount;
      case "open or resolved":
        return openCount + resolvedCount;
    }
  }
  const iconWH = "15px";
  return (
    <Popover onClose={() => setCommentButtonActive(false)} isOpen={commentButtonActive}>
      <PopoverTrigger>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            position: "sticky",
          }}
        >
          <Icon
            color="white"
            as={ChatBubbleOutlineOutlinedIcon}
            onClick={(event) => {
              setCommentButtonActive(true);
            }}
          />

          <div style={{ zIndex: -2, position: "absolute" }}>
            <Box w={iconWH} h={iconWH}>
              <Text fontSize="10px" fontWeight={1000} ml="3px" mt="-3px">
                {getCommentProgress()}
              </Text>
            </Box>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent bg="white">
        <VStack textColor="black" m="24px" align="left">
          <HStack>
            <Wrap>
              <WrapItem>
                <Center h="100%" fontSize="sm">
                  Show
                </Center>
              </WrapItem>
              <WrapItem>
                <Menu>
                  <MenuButton border="1px" borderRadius="3px" p="4px" borderColor="gray.500" fontSize="sm">
                    {commentManagement.primaryCommentFilter}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        commentManagement.setPrimaryCommentFilter("all conversations");
                        setDisplayCount(getCommentProgress());
                      }}
                      fontSize="sm"
                    >
                      all conversations ({getAllConversationsCount()})
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        commentManagement.setPrimaryCommentFilter("conversations assigned to you");
                        setDisplayCount(getCommentProgress());
                      }}
                      fontSize="sm"
                    >
                      conversations assigned to you ({getAssignedConversationsCount()})
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        commentManagement.setPrimaryCommentFilter("your conversations");
                        setDisplayCount(getCommentProgress());
                      }}
                      fontSize="sm"
                    >
                      your conversations ({getForConversationsCount()})
                    </MenuItem>
                  </MenuList>
                </Menu>
              </WrapItem>
              <WrapItem>
                <Center h="100%" fontSize="sm">
                  that are
                </Center>
              </WrapItem>
              <WrapItem>
                <Menu>
                  <MenuButton border="1px" borderRadius="3px" p="4px" borderColor="gray.500" fontSize="sm">
                    {commentManagement.secondaryCommentFilter}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        commentManagement.setSecondaryCommentFilter("open");
                        setDisplayCount(getCommentProgress());
                      }}
                      fontSize="sm"
                    >
                      open ({getOpenConversationsCount()})
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        commentManagement.setSecondaryCommentFilter("resolved");
                        setDisplayCount(getCommentProgress());
                      }}
                      fontSize="sm"
                    >
                      resolved ({getResolvedConversationsCount()})
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        commentManagement.setSecondaryCommentFilter("open or resolved");
                        setDisplayCount(getCommentProgress());
                      }}
                      fontSize="sm"
                    >
                      open or resolved ({getOpenOrResolvedConversationsCount()})
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Center h="100%" fontSize="sm">
                  .
                </Center>
              </WrapItem>
            </Wrap>
          </HStack>
        </VStack>
      </PopoverContent>
    </Popover>
  );
};
