import { useEffect, useState, useCallback } from "react";
import { Center, Heading, Flex, Card, CardBody, Button, Spacer as FlexSpacer, Tabs, TabList, TabPanel, TabPanels, Tab } from "@chakra-ui/react";
import { Spacer, SpacerSizes } from "../components/Spacer";
import { PageInfo } from "../components/TopNavBar/PageInfo";
import { useTopNavContext } from "../context/TopNavContext";
import { Colors } from "../theme/colors";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import { useFormAPI } from "../endpoints/forms";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { FormsSection } from "./FormsPageComponents/FormsSection";
import { EmptyFormsSection } from "./FormsPageComponents/EmptyFormsSection";
import { LoadingForms } from "./FormsPageComponents/LoadingForms";

export default function Forms() {
  const navigate = useNavigate();

  const currentOrgMembership = useSelector((state: RootState) => state.auth.globalUserInfo?.currentOrgMembership);
  const [formsList, setFormsList] = useState<any[]>([]);
  const [archivedFormsList, setArchivedFormsList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { getForms, createNewForm } = useFormAPI();
  const { setPageInfo } = useTopNavContext();

  useEffect(() => {
    setPageInfo(<PageInfo title="Forms" />);

    return () => {
      setPageInfo(<PageInfo />);
    };
  }, [setPageInfo]);

  const refreshAllForms = async () => {
    setLoading(true);
    await refreshForms();
    await refreshArchivedForms();
    setLoading(false);
  };

  const refreshForms = async () => {
    await getForms()
      .then((data) => {
        setFormsList(data as any);
      })
      .catch((err) => console.log(err));
    return;
  };

  const refreshArchivedForms = async () => {
    await getForms("archived=true")
      .then((data) => {
        setArchivedFormsList(data as any);
      })
      .catch((err) => console.log(err));
    return;
  };

  useEffect(() => {
    refreshAllForms();
  }, [currentOrgMembership]);

  async function createFormAndGo() {
    const formContent: any = {
      meta: {
        title: "Untitled Form",
        description: "",
      },
      elements: [
        {
          type: "section",
          content: { title: "Untitled Section" },
          id: nanoid(16),
        },
      ],
      commentThreadsDictionary: {},
    };
    const newForm: any = await createNewForm(formContent);
    navigate(`/form-builder/${newForm.id}`);
  }

  return (
    <div style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "5%" }}>
      <Flex>
        <Center>
          <Heading size="md">Manage Forms</Heading>
        </Center>
        <FlexSpacer />
        <Button onClick={createFormAndGo}>New Form</Button>
      </Flex>
      <Spacer y={SpacerSizes.sm} />
      <Tabs>
        <TabList>
          <Tab>Active</Tab>
          <Tab>Archived</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {loading ? (
              <LoadingForms />
            ) : formsList.length ? (
              <FormsSection forms={formsList} refreshForms={refreshAllForms} archivePanel={false} />
            ) : (
              <EmptyFormsSection />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <LoadingForms />
            ) : archivedFormsList.length ? (
              <FormsSection forms={archivedFormsList} refreshForms={refreshAllForms} archivePanel={true} />
            ) : (
              <Card style={{ cursor: "pointer" }}>
                <CardBody
                  style={{
                    backgroundColor: "white",
                    border: "dashed",
                    borderWidth: "1px",
                    borderColor: Colors.brand[500],
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Heading variant="h2" color="brand.500" mt="6px" mb="6px">
                    You have no archived Forms.
                  </Heading>
                  <Spacer x={SpacerSizes.md} />
                </CardBody>
              </Card>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Spacer y={SpacerSizes.xl} />
    </div>
  );
}
