import { configureStore } from "@reduxjs/toolkit";
import authReducer, { IAuthState } from "./reducers/authReducers";
import organizationReducer, { IOrgState } from "./reducers/organizationReducers";

const store = configureStore({ reducer: { auth: authReducer, org: organizationReducer } });

export default store;

export interface RootState {
  auth: IAuthState;
  org: IOrgState;
}
