import { Text, Switch, HStack } from "@chakra-ui/react";

export const RequiredToggle = ({ onChange, value }: { onChange: (e: boolean) => void; value: boolean }) => {
  return (
    <HStack
      contentEditable={false}
      style={{
        position: "relative",
        left: "80%",
        userSelect: "none",
      }}
    >
      <Switch size="sm" isChecked={value} onChange={() => onChange(!value)} colorScheme={"brightblue"} />
      <Text>Required</Text>
    </HStack>
  );
};
