import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Box,
  Center,
  HStack,
  VStack,
  Circle,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { useAuthContext } from "../../context/AuthContext";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useTopNavContext } from "../../context/TopNavContext";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store";
import { User, UserQuery } from "../../types";
import { setGlobalUserInfo, setOrganizationId } from "../../actions/authActions";
import { useUserAPI } from "../../endpoints/user";

export function TopNav() {
  const { session, logout } = useAuthContext();
  const { pageInfo, pageActions } = useTopNavContext();
  const [accordionIndex, setAccordionIndex] = useState(-1);
  const [initials, setInitials] = useState<string>("");
  const globalUserInfo: UserQuery = useSelector((state: RootState) => state.auth.globalUserInfo);
  const currentOrgMembership = useSelector((state: RootState) => state.auth.globalUserInfo.currentOrgMembership);
  const navigate = useNavigate();
  const { getUser } = useUserAPI();

  const getUserInfo = async (organizationIdOverride?: string) => {
    const qr: any = await getUser(organizationIdOverride);
    store.dispatch(setGlobalUserInfo(qr as UserQuery));
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    const u: User | undefined = globalUserInfo?.user;
    if (u?.initials) {
      setInitials(u?.initials);
    } else if (u?.firstName && u?.lastName) {
      setInitials(u?.firstName[0] + u?.lastName[0]);
    } else if (u?.lastName) {
      setInitials(u?.lastName[0]);
    } else if (u?.firstName) {
      setInitials(u?.firstName[0]);
    } else {
      setInitials((u?.email?.split("@")[0][0] ?? "") + (u?.email.split("@")[1][0] ?? ""));
    }
  }, [globalUserInfo]);

  function toggleAccordionIndex() {
    if (accordionIndex == -1) {
      setAccordionIndex(0);
    } else {
      setAccordionIndex(-1);
    }
  }

  function currentOrgUpdated(update: any) {
    toggleAccordionIndex();
    let found = false;
    let foundIndex = 0;
    for (let i = 0; i < (globalUserInfo?.orgMemberships.length ?? 0); i++) {
      if (update === globalUserInfo?.orgMemberships[i].organization?.name) {
        found = true;
        foundIndex = i;
      }
    }
    if (found) {
      store.dispatch(setOrganizationId(globalUserInfo!.orgMemberships[foundIndex].organization!._id));
      getUserInfo(globalUserInfo!.orgMemberships[foundIndex].organization!._id);
    }
  }

  function renderName() {
    if (globalUserInfo?.user?.firstName || globalUserInfo?.user?.lastName) {
      return (globalUserInfo?.user?.firstName ?? "") + " " + (globalUserInfo?.user?.lastName ?? "");
    }
    return globalUserInfo?.user?.email;
  }

  if (!session) return <></>;

  const userAndOrgMenuMargin = "10px";
  return (
    <React.Fragment>
      <HStack mr="auto" color="white.500" pl="40px">
        {pageInfo}
      </HStack>
      <HStack>
        <HStack m="12px" color="white.500">
          <Center>{pageActions}</Center>
        </HStack>
        <Box w="1px" bg="brand.500" h="24px" />
        <Menu>
          <MenuButton>
            <HStack border="1px" borderColor="brand.500" color="white.500" p="4px">
              <Center>
                <Box w="160px">
                  <Text isTruncated>{currentOrgMembership?.organization?.name}</Text>
                </Box>
              </Center>
              <Center>
                <Icon as={AccountCircleOutlinedIcon} />
              </Center>
            </HStack>
          </MenuButton>
          <MenuList style={{ color: "gray.900", borderRadius: "2px" }}>
            <HStack m="12px">
              <VStack>
                <Circle size="64px" bg="brightblue.200">
                  <Text fontWeight="bold">{initials}</Text>
                </Circle>
              </VStack>
              <VStack>
                <Text w="full" fontWeight="bold">
                  {renderName()}
                </Text>
                <Text w="full" fontSize="sm">
                  {globalUserInfo?.user?.email}
                </Text>
              </VStack>
            </HStack>
            <MenuItem w="100%" icon={<AccountCircleOutlinedIcon />} mt={userAndOrgMenuMargin} onClick={() => navigate("/user-profile")}>
              Profile
            </MenuItem>
            <MenuDivider />
            <VStack w="full">
              {globalUserInfo && globalUserInfo.orgMemberships && globalUserInfo.orgMemberships ? (
                <Accordion allowToggle w="full" p="8px" index={accordionIndex}>
                  <AccordionItem border="none">
                    <AccordionButton onClick={toggleAccordionIndex}>
                      <Box as="span" flex="1" textAlign="left">
                        <Text>{currentOrgMembership?.organization?.name}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                      <RadioGroup onChange={currentOrgUpdated} defaultValue={currentOrgMembership?.name}>
                        <VStack align="left" ml="8px">
                          {globalUserInfo?.orgMemberships.map(function (m, i) {
                            return (
                              <Radio key={m.name} value={m.name}>
                                {m.name}
                              </Radio>
                            );
                          })}
                        </VStack>
                      </RadioGroup>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ) : (
                <></>
              )}
            </VStack>
            {currentOrgMembership?.permissions.manageOrgPermissions ||
            currentOrgMembership?.permissions.manageOrgSettings ||
            currentOrgMembership?.permissions.manageLegalPrivilege ||
            currentOrgMembership?.permissions.manageAllGroups ? (
              <>
                <MenuItem w="100%" icon={<GroupsOutlinedIcon />} mt={userAndOrgMenuMargin} onClick={() => navigate("/organization")}>
                  Organization Settings
                </MenuItem>
              </>
            ) : (
              <></>
            )}
            <MenuDivider />
            <MenuItem w="100%" icon={<LogoutOutlinedIcon />} mt={userAndOrgMenuMargin} onClick={logout}>
              Log Out
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </React.Fragment>
  );
}
